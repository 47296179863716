import {Component, Input} from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {NGXLogger} from 'ngx-logger';

import {Preview} from '../preview';
import {BelegDownloadService} from '../../beleg-api/beleg-download.service';


@Component({
  selector: 'bo-download-preview',
  templateUrl: './download-preview.component.html',
  styleUrls: ['./download-preview.component.scss']
})

export class DownloadPreviewComponent extends Preview {

  constructor(
    logger: NGXLogger,
    snackBar: MatSnackBar,
    belegDownloadService: BelegDownloadService,
  ) {
    super(logger, snackBar, belegDownloadService);
  }
}
