import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';
import {Activity} from '../../interfaces/activity.interface';
import {ActivityIdentifier} from '../../interfaces/activity-identifier.interface';
import {ActivityUpdate} from '../../interfaces/activity-update.interface';
import {ActivityMode} from '../../types/activity-mode.type';


@Injectable({
  providedIn: 'root'
})
export class ActivityDisplayService {

  /*
   * INFO: Die Liste der Aktivitäten, je Inhaber.
   */
  private inhaberActivities$ = new Map<string, BehaviorSubject<Map<string, Activity>>>();

  /*
   * INFO: Steuert die temporäre Darstellung der Aktivitätenanzeige, je Inhaber.
   * Kann durch den Anwender gesteuert werden, sofern die Anzeige grundsätzlich erlaubt ist.
   */
  private showInhaberActivityDisplay$ = new Map<string, BehaviorSubject<boolean>>();

  /*
   * INFO: In manchen Kontexten (bspw. Bearbeiten-Seite),
   * darf die Aktivitätenanzeige niemals / nicht immer angezeigt werden.
   * Hierüber können wir dies steuern. Kann / Darf nicht vom Anwender übersteuert werden.
   */
  private neverShowActivityDisplay$ = new BehaviorSubject<boolean>(false);

  constructor() {
  }

  /**
   * Ermittelt alle Aktivitäten zu der inhaberId.
   *
   * @param inhaberId Id des Inhabers.
   * @private
   */
  private getInhaberActivities(inhaberId: string): BehaviorSubject<Map<string, Activity>> {
    let activities = this.inhaberActivities$.get(inhaberId);

    if (!activities) {
      activities = new BehaviorSubject(new Map<string, Activity>());
      this.inhaberActivities$.set(inhaberId, activities);
    }

    return activities;
  }

  /**
   * Kopiert die Aktivitäten in eine neue Map.
   *
   * @param activities Die Aktivitäten.
   * @private
   */
  private copyActivities(activities: Map<string, Activity>): Map<string, Activity> {
    return new Map<string, Activity>(activities);
  }

  /**
   * Alle Aktivitäten, sortiert absteigend nach dem Erstellt-Datum.
   * Die neuste Aktivität ist oben in der Liste.
   *
   * @param inhaberId Id des Inhabers.
   */
  public getActivities(inhaberId: string): Observable<Activity[]> {
    return this.getInhaberActivities(inhaberId).pipe(
      map(activities => Array.from(activities.values())),
      map(activities => activities.sort((a, b) => {
        return b.created.getTime() - a.created.getTime();
      })),
    );
  }

  public successActivity(
    identifier: ActivityIdentifier,
    description?: string,
  ): void {

    this.endActivity(identifier, true, description);
  }

  public failActivity(
    identifier: ActivityIdentifier,
    description?: string,
  ): void {
    this.endActivity(identifier, false, description);
  }

  /**
   * Beendet eine Aktivität und setzt die entsprechenden Status.
   *
   * @param identifier Zum identifizieren der Aktivität.
   * @param success Je nach dem ob die Aktivität erfolgreich gewesen ist.
   * @param description Eine Beschreibung des Status der Aktivität.
   * @private
   */
  private endActivity(
    identifier: ActivityIdentifier,
    success: boolean,
    description?: string,
  ): void {

    const subject = this.getInhaberActivities(identifier.inhaberId);
    const changedActivities = this.copyActivities(subject.value);
    const activity = changedActivities.get(identifier.id);

    if (activity) {
      const updatedTask = {
        ...activity,
        description,
        updated: new Date(),
        done: true,
        success,
        failed: !success,
      };

      changedActivities.set(identifier.id, updatedTask);
      subject.next(changedActivities);
    }
  }

  /**
   * Aktualisiert die Aktivität mit dem passenden Identifier.
   *
   * @param identifier Zum identifizieren der Aktivität.
   * @param updateData Die neuen Informationen für die Aktivität.
   */
  public updateActivity(
    identifier: ActivityIdentifier,
    updateData: ActivityUpdate,
  ): void {

    const subject = this.getInhaberActivities(identifier.inhaberId);
    const changedTasks = this.copyActivities(subject.value);
    const activity = changedTasks.get(identifier.id);
    if (activity) {
      let description = updateData.description;
      if (!description) {
        description = activity.description;
      }

      let mode = updateData.mode;
      if (!mode) {
        mode = activity.mode;
      }

      let progress = updateData.progress;
      if (!progress) {
        progress = activity.progress;
      }

      const updatedTask = {
        ...activity,
        updated: new Date(),
        description,
        mode,
        progress,
      };

      changedTasks.set(identifier.id, updatedTask);
      subject.next(changedTasks);
    }
  }

  /**
   * Fügt eine Aktivität auf die Liste des passenden Inhabers hinzu.
   *
   * @param inhaberId Id des Inhabers.
   * @param title Titel der Aktivität.
   * @param description Eine Beschreibung der Aktivität.
   * @param mode Den Modus der Aktivität.
   */
  public addTask(
    inhaberId: string,
    title: string,
    description?: string,
    mode: ActivityMode = 'indeterminate',
  ): ActivityIdentifier {

    const activity: Activity = {
      inhaberId,
      id: uuid(),
      created: new Date(),
      updated: new Date(),
      title,
      description,
      mode,
      progress: 0,
      done: false,
      success: false,
      failed: false,
    };

    const subject = this.getInhaberActivities(inhaberId);
    const changedActivities = this.copyActivities(subject.value);
    changedActivities.set(activity.id, activity);

    subject.next(changedActivities);

    // INFO: Sofern erlaubt, die Aktivitätenanzeige bei neuen Aktivitäten einblenden.
    const inhaberActivity = this.showInhaberActivityDisplay$.get(inhaberId);
    if (inhaberActivity && !inhaberActivity.getValue()) {
      inhaberActivity.next(true);
    }

    return {
      inhaberId,
      id: activity.id,
    };
  }

  /**
   * Ermittelt für den entsprechenden Inhaber,
   * ob die Aktivitäten-Anzeige dargestellt oder ausgeblendet werden soll.
   *
   * @param inhaberId Id des Inhabers.
   */
  showActivityDisplayByInhaber(inhaberId: string): Observable<boolean> {
    const inhaberActivity = this.showInhaberActivityDisplay$.get(inhaberId);
    if (inhaberActivity) {
      return inhaberActivity.asObservable();
    } else {
      this.showInhaberActivityDisplay$.set(inhaberId, new BehaviorSubject<boolean>(false));
    }

    return this.showInhaberActivityDisplay$.get(inhaberId)!.asObservable();
  }

  get neverShowActivityDisplay(): Observable<boolean> {
    return this.neverShowActivityDisplay$.asObservable();
  }

  /**
   * Steuert das Ausblenden der Aktivitäten-Anzeige.
   * Leert die Liste der Aktivitäten.
   *
   * @param inhaberId Id des Inhabers.
   */
  activityDisplayShouldHide(inhaberId: string): void {
    this.showInhaberActivityDisplay$.get(inhaberId)?.next(false);
    this.inhaberActivities$.get(inhaberId)?.next(new Map<string, Activity>([]));
  }

  /**
   * Steuert, ob die Aktivitäten-Anzeige grundsätzlich ausgeblendet werden soll.
   *
   * @param neverShow Ob die Aktivitäten-Anzeige ausgeblendet werden soll.
   */
  activityDisplayShouldNeverShow(neverShow: boolean): void {
    this.neverShowActivityDisplay$.next(neverShow);
  }
}
