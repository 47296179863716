import {createAction, props} from '@ngrx/store';



export class DeleteDialogActions {

  public static open = createAction(
    '[Delete-Dialog] Open delete-beleg-dialog.',
    props<{
      belegIds: string[];
    }>(),
  );

  public static close = createAction(
    '[Delete-Dialog] Close delete-beleg-dialog.',
  );

}
