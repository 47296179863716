import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/states/app.state';
import {StipulateDialogActions} from '../../store/actions/stipulate-dialog.actions';
import {BelegEntitiesActions} from '../../store/actions/beleg-entities.actions';
import {Subject, take, takeUntil} from 'rxjs';
import {StipulateDialogSelectors} from '../../store/selectors/stipulate-dialog.selectors';
import {NavigationService} from '@adnova/jf-ng-components';


@Component({
  selector: 'bo-stipulate-dialog',
  templateUrl: './stipulate-dialog.component.html',
  styleUrls: ['./stipulate-dialog.component.scss']
})

export class StipulateDialogComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private inhaberId = '';

  constructor(
    private navigationService: NavigationService,
    private store: Store<AppState>
  ) {
  }

  ngOnInit() {
    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      if (!inhaber) return;

      this.inhaberId = inhaber.id;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public closeDialog(): void {
    this.store.dispatch(StipulateDialogActions.close());
  }

  public doStipulate(): void {
    this.store.select(StipulateDialogSelectors.belegDtos(this.inhaberId)).pipe(
      take(1),
    ).subscribe(belegDtos => {
      for (const belegDto of belegDtos) {
        if (belegDto.offen) {
          this.store.dispatch(BelegEntitiesActions.updateBeleg({
            belegDto,
            stipulateBeleg: true
          }));
        } else {
          this.store.dispatch(BelegEntitiesActions.stipulateBeleg({
            inhaberId: belegDto.inhaberId,
            belegId: belegDto.id,
          }));
        }
      }
    });

    this.closeDialog();
  }
}
