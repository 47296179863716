import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {BelegDTO} from '../../openapi/beleg-openapi';
import {BehaviorSubject} from 'rxjs';
import {AppState} from '../../store/states/app.state';
import {Store} from '@ngrx/store';
import {BelegEntitiesActions} from '../../store/actions/beleg-entities.actions';


@Component({
  selector: 'bo-preview-dialog',
  templateUrl: './preview-dialog.component.html',
  styleUrls: ['./preview-dialog.component.scss']
})
export class PreviewDialogComponent implements OnInit {

  protected beleg$ = new BehaviorSubject<BelegDTO | undefined>(undefined);

  constructor(
    @Inject(MAT_DIALOG_DATA) public beleg: BelegDTO,
    private store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.beleg$.next(this.beleg);
  }

  close(): void {
    this.store.dispatch(BelegEntitiesActions.closeBelegPreviewDialog());
  }

}
