import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {belegAktionenEntitiesAdapter} from '../adapters/beleg-aktionen-entities.adapter';


export class BelegAktionenEntitiesSelectors {

  private static adapterSelectors = belegAktionenEntitiesAdapter.getSelectors();

  private static select = {
    belegAktionenEntities: (state: AppState) => state.belegAktionenEntities,
  };

  public static all = createSelector(
    this.select.belegAktionenEntities,
    this.adapterSelectors.selectAll,
  );

  public static entities = createSelector(
    this.select.belegAktionenEntities,
    this.adapterSelectors.selectEntities,
  );

  public static belegAktionenByBelegId = (belegId: string) => createSelector(
    this.entities,
    belegAktionen => belegAktionen[belegId],
  );
}
