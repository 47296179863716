import {createAction, props} from '@ngrx/store';
import {FilterBelegDTO, PageableBelegDTO} from '../../openapi/beleg-openapi';
import {DubletteSnackbar} from '../../interfaces/dublette-snackbar.interface';


export class DubletteActions {

  public static openHandleDubletteDialog = createAction(
    '[Overview- & Edit-Page] Open handle-dublette-dialog.',
    props<{
      inhaberId: string,
      dublettenIds: string[],
    }>(),
  );

  public static closeHandleDubletteDialog = createAction(
    '[Handle Dublette Dialog] Close handle-dublette-dialog.',
  );

  public static readDubletten = createAction(
    '[Overview- & Edit-Page] Load dubletten data.',
    props<{
      inhaberId: string,
      filterDto: FilterBelegDTO,
      pageableDto: PageableBelegDTO,
    }>(),
  );

  public static openDubletteSnackbar = createAction(
    '[Beleg Effect] Open dublette snackbar.',
    props<DubletteSnackbar>(),
  );

  public static closeDubletteSnackbar = createAction(
    '[Beleg Effect] Close dublette snackbar.',
  );

  public static deleteDublette = createAction(
    '[Handle Dublette Dialog] Delete dublette.',
    props<{
      inhaberId: string,
      belegId: string,
    }>(),
  );

  public static deleteDubletteSuccess = createAction(
    '[Beleg Effect] Delete dublette succeeded.',
    props<{
      inhaberId: string,
      belegId: string,
    }>(),
  );

}
