import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';


export class ReuseBelegDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isReuseBelegDialogOpen: (state: AppState) => state.reuseBelegDialog?.isOpen,
    reuseBelegData: (state: AppState) => state.reuseBelegDialog?.reuseBelegData,
  }

  public static isReuseBelegDialogOpen = createSelector(
    this.select.isReuseBelegDialogOpen,
    identity,
  );

  public static reuseBelegData = createSelector(
    this.select.reuseBelegData,
    identity,
  );
}
