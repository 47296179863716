import {Component, OnDestroy, OnInit} from '@angular/core';
import {Activity} from '../../interfaces/activity.interface';
import {ActivityDisplayService} from './activity-display.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {NavigationService} from '@adnova/jf-ng-components';


@Component({
  selector: 'bo-activity-display',
  templateUrl: './activity-display.component.html',
  styleUrls: ['./activity-display.component.scss']
})
export class ActivityDisplayComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  // INFO: Die Id des ausgewählten Inhabers.
  private currentInhaberId = '';

  // INFO: Die Liste der Aktivitäten
  public activities: Activity[] = [];

  // INFO: Das Mat-expansion-panel soll initial immer geschlossen sein.
  public isExpansionpanelOpen = false;

  // INFO: Anzahl der laufenden Aktivitäten
  public activitiesOngoingCount = 0;

  // INFO: Das Mat-expansion-panel soll initial immer ausgeblendet sein.
  public showActivityDisplay = false;

  // INFO: Die Anzeige des Mat-expansion-panel soll initial immer erlaubt sein.
  public neverShowActivityDisplay = false;

  constructor(
    private navigationService: NavigationService,
    private activityDisplayService: ActivityDisplayService,
  ) {
  }

  ngOnInit() {
    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      if (!inhaber) return;
      const inhaberId = inhaber.id;

      this.currentInhaberId = inhaberId;

      this.activityDisplayService.getActivities(inhaberId).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(activities => {

        if (this.currentInhaberId === inhaberId) {
          // INFO: Zählen der laufenden Aktivitäten
          let activitiesOngoingCount = 0;
          for (const activity of activities) {
            if (!activity.done) {
              activitiesOngoingCount += 1;
            }
          }

          this.activitiesOngoingCount = activitiesOngoingCount;
          // INFO: Aktualisieren der Aktivitäten.
          this.activities = activities;

        }
      });

      // INFO: Ermitteln, ob die Aktivitäten-Anzeige bei dem Inhaber dargestellt werden soll.
      this.activityDisplayService.showActivityDisplayByInhaber(this.currentInhaberId).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(show => {
        this.showActivityDisplay = show;
      });
    });

    // INFO: Ermitteln, ob die Aktivitäten-Anzeige grundsätzlich angezeigt werden darf.
    this.activityDisplayService.neverShowActivityDisplay.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(neverShow => {
      this.neverShowActivityDisplay = neverShow;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Setzen des State des Mat-expansion-panel.
   * @param isOpen Offen oder nicht.
   */
  changeExpansionpanelState(isOpen: boolean): void {
    this.isExpansionpanelOpen = isOpen;
  }

  /**
   * Triggert das Ausblenden der Aktivitäten-Anzeige.
   * @param event Das Event, welches das Mat-expansion-panel wirft.
   */
  hideActivityDisplay(event: MouseEvent): void {
    // INFO: Die Aktivitäten-Anzeige soll nicht ein- oder ausklappen, wenn sie geschlossen wird.
    event.stopPropagation();

    this.activityDisplayService.activityDisplayShouldHide(this.currentInhaberId);
  }
}
