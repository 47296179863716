import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../store/states/app.state';
import {BelegEntitiesSelectors} from '../store/selectors/beleg-entities.selectors';
import {combineLatest, filter, of, switchMap, take} from 'rxjs';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {DeleteDialogSelectors} from '../store/selectors/delete-dialog.selectors';
import {DeleteDialogComponent} from '../modules/delete-dialog/delete-dialog.component';
import {BelegDTO} from '../openapi/beleg-openapi';
import {MoveBelegDialogSelectors} from '../store/selectors/move-beleg-dialog.selectors';
import {MoveBelegDialogComponent} from '../modules/move-beleg-dialog/move-beleg-dialog.component';
import {PreviewDialogComponent} from '../modules/preview-dialog/preview-dialog.component';
import {PreviewDialogSelectors} from '../store/selectors/preview-dialog.selectors';
import {MoveBelegData} from '../modules/move-beleg-dialog/move-beleg-data.interface';
import {DubletteSelectors} from '../store/selectors/dublette.selectors';
import {HandleDubletteDialogComponent} from '../modules/handle-dublette-dialog/handle-dublette-dialog.component';
import {BelegStipulatedDialogComponent} from '../modules/beleg-stipulated-dialog/beleg-stipulated-dialog.component';
import {ReuseBelegData} from '../interfaces/reuse-beleg-data.interface';
import {ReuseBelegDialogComponent} from '../modules/reuse-beleg-dialog/reuse-beleg-dialog.component';
import {BelegStipulatedDialogSelectors} from '../store/selectors/beleg-stipulated-dialog.selectors';
import {ReuseBelegDialogSelectors} from '../store/selectors/reuse-beleg-dialog.selectors';
import {StipulateDialogSelectors} from '../store/selectors/stipulate-dialog.selectors';
import {StipulateDialogComponent} from '../modules/stipulate-dialog/stipulate-dialog.component';
import {NavigationService} from '@adnova/jf-ng-components';


/**
 * @class UiService
 *
 * Der UiService ist zuständig für das Steuern globaler UI-Operationen
 * auf der ganzen App. Dieser Service wird von der AppComponent beim
 * Initialisieren geladen und ermöglicht es bestimmte UI-Aspekte
 * basierend auf dem Zustand des Redux-Stores zu steuern.
 *
 * U.a. steuert dieser Service das Öffnen eines Vorschau-Dialogs,
 * abhängig von dem Wert 'belegId' im Store. Hiermit wird vermieden,
 * dass die gleiche Funktionalität redundant in verschiedenen
 * Komponenten implementiert werden muss. Dies hat nicht nur den
 * Vorteil, den Code sauberer und wartbarer zu machen, sondern kann
 * auch Performanzproblemen vorbeugen.
 *
 * Ein weiterer Vorteil davon, dass diese Operationen durch den
 * UiService und nicht durch die Effekte gesteuert werden, besteht
 * darin, dass der UiService eher der "Ansichtslogik" entspricht,
 * und es somit intuitiver ist, ihn für die Ansichtssteuerung zu
 * verwenden, im Vergleich zur Verwendung der Effekte, die eher
 * für die 'Geschäftslogik' geeignet sind.
 *
 * Problematisch wäre es, wenn diese Logik in jeder einzelnen Komponenten
 * liegen würde, da jede dieser Implementierungen gewartet werden muss.
 * Darüber hinaus könnte sich die Implementierung leicht in kleinen
 * Detailpunkten unterscheiden, was zu Inkonsistenzen in der
 * Benutzererfahrung führen könnte.
 *
 * Auch das Preloading der Module weist den Vorteil auf, dass die
 * Module genau dann geladen werden, wenn sie benötigt werden. Dies
 * kann die Benutzererfahrung erheblich verbessern, insbesondere in
 * Anwendungen, die eine Vielzahl von Modulen verwenden.
 *
 */
@Injectable({
  providedIn: 'root'
})
export class UiService {

  private readonly modules: {
    [key: string]: { modulePath: Promise<any>, moduleName: string, componentName: string }
  } = {
    previewDialog: {
      modulePath: import('../modules/preview-dialog/preview-dialog.module'),
      moduleName: 'PreviewDialogModule',
      componentName: 'previewDialogComponent',
    },
    moveBelegDialog: {
      modulePath: import('../modules/move-beleg-dialog/move-beleg-dialog.module'),
      moduleName: 'MoveBelegDialogModule',
      componentName: 'moveBelegDialogComponent',
    },
    handleDubletteDialog: {
      modulePath: import('../modules/handle-dublette-dialog/handle-dublette-dialog.module'),
      moduleName: 'HandleDubletteDialogModule',
      componentName: 'handleDubletteDialogComponent',
    },
    belegStipulatedDialog: {
      modulePath: import('../modules/beleg-stipulated-dialog/beleg-stipulated-dialog.module'),
      moduleName: 'BelegStipulatedDialogModule',
      componentName: 'BelegStipulatedDialogComponent',
    },
    reuseBelegDialog: {
      modulePath: import('../modules/reuse-beleg-dialog/reuse-beleg-dialog.module'),
      moduleName: 'ReuseBelegDialogModule',
      componentName: 'ReuseBelegDialogComponent',
    },
    deleteDialog: {
      modulePath: import('../modules/delete-dialog/delete-dialog.module'),
      moduleName: 'DeleteDialogModule',
      componentName: 'deleteDialogComponent',
    },
    stipulateDialog: {
      modulePath: import('../modules/stipulate-dialog/stipulate-dialog.module'),
      moduleName: 'StipulateDialogModule',
      componentName: 'StipulateDialogComponent',
    },
  };

  private previewDialog?: MatDialogRef<PreviewDialogComponent, BelegDTO>;

  private moveBelegeDialog?: MatDialogRef<MoveBelegDialogComponent, MoveBelegData>;

  private handleDubletteDialog?: MatDialogRef<HandleDubletteDialogComponent, any>;

  private belegStipulatedDialog?: MatDialogRef<BelegStipulatedDialogComponent, ReuseBelegData>;

  private reuseBelegDialog?: MatDialogRef<ReuseBelegDialogComponent, ReuseBelegData>;

  private deleteDialog?: MatDialogRef<DeleteDialogComponent, BelegDTO[]>;

  private stipulateDialog?: MatDialogRef<StipulateDialogComponent, any>;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private navigationService: NavigationService,
  ) {
    this.preloadModules();
    this.initMoveDialog();
    this.initPreviewDialog();
    this.initDeleteDialog();
    this.initStipulateDialog();
    this.initHandleDublettenDialog();
    this.initBelegStipulatedDialog();
    this.initReuseBelegDialog();
  }

  /**
   * Befindet sich im MoveBelegDialogState eine sourceInhaberId,
   * sowie eine Liste von BelegIds, wird der Dialog zum Verschieben
   * der Belege geöffnet. Dazu wird erst das Modul "lazy" geladen
   * (sollte der Preload-Vorgang noch nicht abgeschlossen sein).
   */
  private initMoveDialog(): void {
    this.store.select(MoveBelegDialogSelectors.moveBelegDialogPayload).subscribe(
      ({sourceInhaberId, belegIds}) => {
        if (sourceInhaberId && belegIds.length) {
          this.store.select(BelegEntitiesSelectors.belegeByIds(sourceInhaberId, belegIds)).pipe(
            // INFO: Inhalte aus dem Store werden nur einmal abgerufen, danach wird die Subscription beendet.
            take(1),

            // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
            switchMap(belege => {
              // INFO: Hier das korrekte Modul auswhählen
              const module = this.modules.moveBelegDialog;
              module.modulePath.then(m => m[module.moduleName].components[module.componentName]);
              return of(belege);
            }),
          ).subscribe(belege => {
            this.moveBelegeDialog = this.dialog.open<MoveBelegDialogComponent, MoveBelegData>(
              MoveBelegDialogComponent,
              {
                disableClose: true,
                minWidth: '236px',
                data: {
                  sourceInhaberId,
                  belegIds,
                }
              }
            );
          });
        } else {
          this.moveBelegeDialog?.close();
        }
      }
    );
  }

  /**
   * Befindet sich im PreviewDialogState eine BelegId,
   * wird der Dialog mit der Vorschau geöffnet. Dazu wird erst
   * das Modul "lazy" geladen (sollte der Preload-Vorgang noch
   * nicht abgeschlossen sein).
   */
  private initPreviewDialog(): void {
    combineLatest([
      this.navigationService.currentInhaber$,
      this.store.select(PreviewDialogSelectors.belegId),
    ]).subscribe(
      ([inhaber, belegId]) => {
        const inhaberId = inhaber?.id;
        if (belegId && inhaberId) {
          this.store.select(BelegEntitiesSelectors.belegById(inhaberId, belegId)).pipe(
            // INFO: Inhalte aus dem Store werden nur einmal abgerufen, danach wird die Subscription beendet.
            take(1),

            // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
            switchMap(beleg => {
              // INFO: Hier das korrekte Modul auswhählen
              const module = this.modules.previewDialog;
              module.modulePath.then(m => m[module.moduleName].components[module.componentName]);
              return of(beleg);
            }),

            // INFO: Sicherstellen, dass "beleg" auch gesetzt ist.
            filter((beleg): beleg is BelegDTO => !!beleg),
          ).subscribe(beleg => {
            this.previewDialog = this.dialog.open<PreviewDialogComponent, BelegDTO>(
              PreviewDialogComponent,
              {
                width: '70%',
                height: '85%',
                disableClose: true,
                data: beleg,
              }
            );
          });
        } else {
          this.previewDialog?.close();
        }
      }
    );
  }

  private initDeleteDialog(): void {
    combineLatest([
      this.navigationService.currentInhaber$,
      this.store.select(DeleteDialogSelectors.belegIds),
    ]).subscribe(
      ([inhaber, belegIds]) => {
        const inhaberId = inhaber?.id;
        if (belegIds.length && inhaberId) {
          this.store.select(BelegEntitiesSelectors.belegeByIds(inhaberId, belegIds)).pipe(
            // INFO: Inhalte aus dem Store werden nur einmal abgerufen, danach wird die Subscription beendet.
            take(1),

            // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
            switchMap(belege => {
              // INFO: Hier das korrekte Modul auswhählen
              const module = this.modules.deleteDialog;
              module.modulePath.then(m => m[module.moduleName].components[module.componentName]);
              return of(belege);
            }),

          ).subscribe(belege => {
            this.deleteDialog = this.dialog.open<DeleteDialogComponent, BelegDTO[]>(
              DeleteDialogComponent,
              {
                disableClose: true,
                data: belege,
              }
            );
          });
        } else {
          this.deleteDialog?.close();
        }
      }
    );
  }

  private initStipulateDialog(): void {
    this.store.select(StipulateDialogSelectors.isOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.stipulateDialog.modulePath
          .then(m => m[this.modules.stipulateDialog.moduleName].components[this.modules.stipulateDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.stipulateDialog = this.dialog.open(StipulateDialogComponent, {
            autoFocus: false,
          });
        } else {
          this.stipulateDialog?.close();
        }
      }
    );
  }

  private initHandleDublettenDialog(): void {
    this.store.select(DubletteSelectors.isHandleDubletteDialogOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        // INFO: Hier das korrekte Modul auswhählen
        const module = this.modules.handleDubletteDialog;
        module.modulePath.then(m => m[module.moduleName].components[module.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.handleDubletteDialog = this.dialog.open(HandleDubletteDialogComponent, {
            width: '700px',
            minWidth: '700px',
            autoFocus: false,
            disableClose: true,
          });
        } else {
          this.handleDubletteDialog?.close();
        }
      }
    );
  }

  private initBelegStipulatedDialog(): void {
    this.store.select(BelegStipulatedDialogSelectors.isBelegStipulatedDialogOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.belegStipulatedDialog.modulePath
          .then(m => m[this.modules.belegStipulatedDialog.moduleName].components[this.modules.belegStipulatedDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.belegStipulatedDialog = this.dialog.open(BelegStipulatedDialogComponent, {
            width: '530px',
            minWidth: '530px',
            autoFocus: false,
          });
        } else {
          this.belegStipulatedDialog?.close();
        }
      }
    );
  }

  private initReuseBelegDialog(): void {
    this.store.select(ReuseBelegDialogSelectors.isReuseBelegDialogOpen).pipe(
      // INFO: Modul für den Inhalt des Dialogs laden, sofern noch nicht über preloadModules initiiert.
      switchMap(isOpen => {
        this.modules.reuseBelegDialog.modulePath
          .then(m => m[this.modules.reuseBelegDialog.moduleName].components[this.modules.reuseBelegDialog.componentName]);
        return of(isOpen);
      }),
    ).subscribe(
      isOpen => {
        if (isOpen) {
          this.reuseBelegDialog = this.dialog.open(ReuseBelegDialogComponent, {
            width: '332px',
            minWidth: '332px',
            autoFocus: false,
          });
        } else {
          this.reuseBelegDialog?.close();
        }
      }
    );
  }

  /**
   * Lädt die hier verwendeten Module vor.
   * Diese Funktion wird beim Initialisieren des Services aufgerufen und
   * lädt die verwendeten Module vor (Preloading), unabhängig von der Routing-Konfiguration.
   *
   * Durch Verwendung von setTimeout mit einer Verzögerung von 0 wird sichergestellt,
   * dass das Vorladen des Moduls nicht den Initialisierungsprozess der Anwendung blockiert.
   * Stattdessen wird es so schnell wie möglich ausgeführt, nachdem der aktuelle Call Stack abgearbeitet ist.
   */
  private preloadModules(): void {
    Object.keys(this.modules).forEach(moduleKey => {
      setTimeout(() => {
        this.modules[moduleKey].modulePath.then(m => m[this.modules[moduleKey].moduleName]);
      }, 0);
    });
  }
}
