import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {debounceTime, filter, Subject, takeUntil} from 'rxjs';
import * as moment from 'moment/moment';
import {DateFilterData} from '../../../../../interfaces/date-filter-data.interface';


@Component({
  selector: 'bo-filter-beleg-date',
  templateUrl: './filter-beleg-date.component.html',
  styleUrls: ['./filter-beleg-date.component.scss'],
})
export class FilterBelegDateComponent implements OnInit, OnDestroy {

  private ignoreValueChanges = false;

  /*
   * INFO: Es existiert ein Bug im MatDatePickerModule:
   * https://github.com/angular/components/issues/20218
   *
   * Dadurch hat die Option "emitEvent: false" keine Auswirkung
   * und es kommt trotz korrekter Flag zu einer Endlosschleife.
   *
   * Um das zu umgehen wird ignoreValueChanges als Flag gesetzt
   * und weiter unten im Code als Filter verwendet, um ein
   * ungewünschtes Emittieren zu verhindern.
   */
  @Input()
  public set value(value: DateFilterData) {
    this.ignoreValueChanges = true;
    this.range.setValue({
      from: value.from || null,
      to: value.to || null,
    }, {
      emitEvent: false,
    });
    this.ignoreValueChanges = false;
  }

  @Output()
  public valueChange = new EventEmitter<DateFilterData>();

  public range = new FormGroup({
    from: new FormControl<string | moment.Moment | undefined>(undefined),
    to: new FormControl<string | moment.Moment | undefined>(undefined),
  });

  private readonly unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.range.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      filter(() => !this.ignoreValueChanges),
      debounceTime(1500),

      // INFO: Prüfen, ob der Input bereits ein gültiges Datum ist - ansonsten Pipe abbrechen
      filter(value => {
        let fromIsValid = true;
        let toIsValid = true;
        const from = value.from;
        const to = value.to;

        if (from && from instanceof moment) {
          const fromMoment = from as moment.Moment;
          const fromCreationData = fromMoment.creationData(); // INFO: Original Input-Wert
          const fromCreationDate = fromCreationData.input;
          const fromCreationFormat = fromCreationData.format;
          fromIsValid = moment(fromCreationDate, fromCreationFormat, true).isValid();
        }

        if (to && to instanceof moment) {
          const toMoment = to as moment.Moment;
          const toCreationData = toMoment.creationData(); // INFO: Original Input-Wert
          const toCreationDate = toCreationData.input;
          const toCreationFormat = toCreationData.format;
          toIsValid = moment(toCreationDate, toCreationFormat, true).isValid();
        }

        return fromIsValid && toIsValid;
      }),
    ).subscribe(value => {
      this.valueChange.emit({
        from: this.formatDate(value.from),
        to: this.formatDate(value.to),
      });
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Formatiert das Datum in YYYY-MM-DD.
   *
   * @param value Der Datumswert
   * @private
   */
  private formatDate(value: any): string | undefined {
    if (value === undefined || value === null) {
      return undefined;
    }
    return moment(value).format('YYYY-MM-DD');
  }

}
