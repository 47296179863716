import {Injectable, NgZone} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {of} from 'rxjs';

import {AdnovaTokenService} from './adnova-token.service';
import {PortalMessageBroker} from './portal-message-broker';


@Injectable({
  providedIn: 'root'
})
export class PortalTokenService {

  /**
   * Kennzeichen ob der PortalTokenService initialisiert ist
   *
   * @private
   */
  private inited = false;

  /**
   * Kennzeichen ob der PortalTokenService im Portal läuft
   *
   * @private
   */
  private runningInPortal = false;

  constructor(
    private logger: NGXLogger,
    private zone: NgZone,
    private portalMessageBroker: PortalMessageBroker,
    private adnovaTokenService: AdnovaTokenService,
  ) {
  }

  public init(
    renewTimeBeforeTokenExpiresInSeconds: number,
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      let result = of('').toPromise();

      if (!this.inited) {
        this.runningInPortal = this.portalMessageBroker.isRunningInPortal();
        if (this.runningInPortal) {
          result = this.adnovaTokenService.startTokenRefresh(5, renewTimeBeforeTokenExpiresInSeconds);
        }

        this.inited = true;
      } else {
        this.logger.warn('PortalMessageBroker.init(): already inited, ignoring... ');
      }
      resolve(result);
    });
  }

  public getAdnovaToken(): string {
    return this.adnovaTokenService.getToken();
  }
}
