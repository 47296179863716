import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/states/app.state';
import {BelegEntitiesActions} from '../../store/actions/beleg-entities.actions';
import {ReuseBelegData} from '../../interfaces/reuse-beleg-data.interface';
import {BelegStipulatedDialogActions} from '../../store/actions/beleg-stipulated-dialog.actions';
import {BelegStipulatedDialogSelectors} from '../../store/selectors/beleg-stipulated-dialog.selectors';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'bo-beleg-stipulated-dialog',
  templateUrl: './beleg-stipulated-dialog.component.html',
  styleUrls: ['./beleg-stipulated-dialog.component.scss']
})
export class BelegStipulatedDialogComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  public reuseBelegData!: ReuseBelegData;
  
  protected linkedPayment: boolean = false;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.store.select(BelegStipulatedDialogSelectors.reuseBelegData).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(data => {
      if (!data) return;

      this.reuseBelegData = data;
    });

    this.store.select(BelegStipulatedDialogSelectors.showLinkedPayment).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.linkedPayment = value;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected doReverseBeleg(createKorrekturBeleg: boolean): void {
    this.store.dispatch(BelegEntitiesActions.reverseBeleg({
      inhaberId: this.reuseBelegData.inhaberId,
      belegId: this.reuseBelegData.belegId,
      createKorrekturBeleg,
      belegAlreadyReversed: this.reuseBelegData.belegAlreadyReversed,
      isDublette: this.reuseBelegData.isDublette,
    }));

    this.closeDialog();
  }

  protected closeDialog(): void {
    this.store.dispatch(BelegStipulatedDialogActions.closeBelegStipulatedDialog());
  }
}
