import {DubletteState} from '../states/dublette.state';
import {createReducer, on} from '@ngrx/store';
import {DubletteActions} from '../actions/dublette.actions';


export const initialDubletteState: DubletteState = {
  handleDubletteDialogData: {
    isOpen: false,
  },
  inhaberId: '',
  dublettenIds: [],
  latestDeletedDubletteId: '',
};

export const dubletteReducer = createReducer(
  initialDubletteState,

  on(
    DubletteActions.openHandleDubletteDialog,
    (state, action) => ({
      ...state,
      handleDubletteDialogData: {
        isOpen: true,
      },
      inhaberId: action.inhaberId,
      dublettenIds: action.dublettenIds,
    })
  ),

  on(
    DubletteActions.closeHandleDubletteDialog,
    () => ({
      ...initialDubletteState
    })
  ),

  on(
    DubletteActions.deleteDubletteSuccess,
    (state, action) => ({
      ...state,
      latestDeletedDubletteId: action.belegId,
    })
  ),

);
