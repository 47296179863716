import {createAction, props} from '@ngrx/store';
import {ZahlungDTO} from '../../openapi/zahlung-openapi';
import {BelegDTO} from '../../openapi/beleg-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class ZahlungEntitiesActions {

  public static getZahlung = createAction(
    '[Intent Action Service] Get single zahlung.',
    props<{
      inhaberId: string,
      zahlungId: string,
    }>(),
  );

  public static getZahlungSuccess = createAction(
    '[Zahlung Effect] Get zahlung succeeded.',
    props<{
      zahlungDto: ZahlungDTO,
    }>(),
  );

  public static readZahlungenByBelege = createAction(
    '[Edit-Page & Beleg Effect] Read zahlung for every belegId.',
    props<{
      belegDtos: BelegDTO[],
    }>(),
  );

  public static readZahlungenSuccess = createAction(
    '[Zahlung Effect] Read zahlung succeeded.',
    props<{
      zahlungDtos: ZahlungDTO[],
    }>(),
  );

  public static readZahlungenFailure = createAction(
    '[Zahlung Effect] Read zahlung failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static deleteZahlungSuccess = createAction(
    '[Beleg Effect] Delete zahlung succeeded.',
    props<{
      zahlungId: string,
    }>(),
  );

}
