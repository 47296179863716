import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {MatomoTracker} from '@ngx-matomo/tracker';


@Component({
  selector: 'bo-searchfield',
  templateUrl: './searchfield.component.html',
  styleUrls: ['./searchfield.component.scss']
})
export class SearchfieldComponent implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  @ViewChild('input')
  public input?: ElementRef;

  @Input()
  public value: string = '';

  @Input()
  public debounceTime = 300;

  @Output()
  public valueChanged = new EventEmitter<string>();

  protected search$ = new Subject<string>();

  constructor(
    private readonly tracker: MatomoTracker,
  ) {
    this.search$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(this.debounceTime),
    ).subscribe(() => {
      this.valueChanged.emit(this.input?.nativeElement.value);
      this.tracker.trackEvent('bo-search', 'value-changed')
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  focus(): void {
    setTimeout(() => this.input?.nativeElement.focus(), 0);
  }
}
