import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {BelegEntitiesSelectors} from './beleg-entities.selectors';


export class DubletteSelectors {

  private static select = {
    isHandleDubletteDialogOpen: (state: AppState) => state.dublette?.handleDubletteDialogData.isOpen,
    dublettenIds: (state: AppState) => state.dublette?.dublettenIds,
    inhaberId: (state: AppState) => state.dublette?.inhaberId,
    latestDeletedDubletteId: (state: AppState) => state.dublette?.latestDeletedDubletteId,
  };

  public static isHandleDubletteDialogOpen = createSelector(
    this.select.isHandleDubletteDialogOpen,
    identity
  );

  public static inhaberId = createSelector(
    this.select.inhaberId,
    identity,
  );

  public static dublettenIds = createSelector(
    this.select.dublettenIds,
    identity,
  );

  public static dublettenDtos = (inhaberId: string) => createSelector(
    this.select.dublettenIds,
    BelegEntitiesSelectors.all,
    (dublettenIds, belege) =>
      BelegEntitiesSelectors.belegeByIds(inhaberId, dublettenIds).projector(belege),
  );

  public static latestDeletedDubletteId = createSelector(
    this.select.latestDeletedDubletteId,
    identity,
  );
}
