import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';

import {Observable, EMPTY, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorpageService} from '../services/errorpage.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class BelegeInterceptor implements HttpInterceptor {

  constructor(
    private errorpageService: ErrorpageService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(error => {
        if (error.status) {
          switch (error.status) {
            case 502:
              this.errorpageService.serviceUnavailable();
              return EMPTY;
              break;
            case 503:
              this.errorpageService.serviceUnavailable();
              return EMPTY;
              break;
          }
        }
        return throwError(error);
      })
    );
  }

}
