import {Component, Inject} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {BelegEntitiesActions} from '../../store/actions/beleg-entities.actions';
import {DeleteDialogActions} from '../../store/actions/delete-dialog.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/states/app.state';
import {BelegDTO} from '../../openapi/beleg-openapi';


@Component({
  selector: 'bo-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  title: string;
  text: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public belege: BelegDTO[],
    private store: Store<AppState>
  ) {
    if (this.belege.length > 1) {
      this.title = 'Belege löschen';
      this.text = 'Sollen die Belege wirklich gelöscht werden?';
    } else {
      this.title = 'Beleg löschen';
      this.text = 'Soll der Beleg wirklich gelöscht werden?';
    }
  }

  deleteBeleg(): void {
    const belege = this.belege;
    if (belege.length > 0) {
      for (const beleg of belege) {
        this.store.dispatch(
          BelegEntitiesActions.deleteBeleg({
            inhaberId: beleg.inhaberId,
            belegId: beleg.id,
          })
        );
      }

      this.belege = [];
    }

    this.closeDialog();
  }

  closeDialog(): void {
    this.store.dispatch(DeleteDialogActions.close());
  }

}

