import {BelegEntitiesState} from '../states/beleg-entities.state';
import {belegEntitiesAdapter} from '../adapters/beleg-entities.adapter';
import {createReducer, on} from '@ngrx/store';
import {BelegEntitiesActions} from '../actions/beleg-entities.actions';


export const initialBelegEntitiesState: BelegEntitiesState = belegEntitiesAdapter.getInitialState({
  isLoading: false,
  isMoving: false,
  countedBelege: 0,
  badgeValues: [],
  reverseBelegData: {
    belegReversing: false,
  },
  vorgangsartDtos: [],
});

export const belegEntitiesReducer = createReducer(
  initialBelegEntitiesState,

  on(
    BelegEntitiesActions.startReadingBelege,
    (state) => ({
      ...state,
      isLoading: true,
    })
  ),

  on(
    BelegEntitiesActions.getBelegSuccess,
    (state, {belegDto}) => {
      const belegDtos = belegEntitiesAdapter.getSelectors().selectAll(state);
      if (belegDtos.find(currentBelegDto => currentBelegDto.id === belegDto.id)) {
        return belegEntitiesAdapter.updateOne(
          {
            id: belegDto.id,
            changes: belegDto
          }, state);
      } else {
        return belegEntitiesAdapter.upsertOne(
          belegDto,
          {
            ...state,
            countedBelege: state.countedBelege + 1,
          }
        );
      }
    }
  ),

  /*
   * INFO: Reducer für readBelegeSuccess-Aktion:
   * `upsertMany` wird anstelle von `setMany` genutzt, um mehrere Belege hinzuzufügen.
   * `upsertMany` erweitert die Liste der bestehenden Belege, anstatt sie zu ersetzen.
   * Da wir die Liste mit Belegen stets erweitern, stellen wir sicher, dass keine Daten verloren gehen.
   * Die tatsächlich darzustellenden Belege werden durch Mapping in der Component geregelt.
   */
  on(
    BelegEntitiesActions.readBelegeSuccess,
    (state, {belegDtos}) => {
      return belegEntitiesAdapter.upsertMany(
        belegDtos,
        {
          ...state,
          isLoading: false,
        },
      );
    }
  ),

  on(
    BelegEntitiesActions.readBelegeFailure,
    (state) => ({
      ...state,
      isLoading: false,
    })
  ),

  on(
    BelegEntitiesActions.countBelegeSuccess,
    (state, {countedBelege}) => ({
      ...state,
      countedBelege,
    })
  ),

  // TODO: Dies muss unbedingt in einen InhaberEntityState, sobald das Laden der Inhaber durch den Store passiert.
  on(
    BelegEntitiesActions.countBadgeValueSuccess,
    (state, {badgeValue}) => {
      let badgeValues = [...state.badgeValues];

      const index = badgeValues.findIndex(value => {
        return value.inhaberId === badgeValue.inhaberId;
      });

      if (index === -1) {
        // INFO: Wenn die Liste komplett leer ist, muss diese initialisiert werden.
        badgeValues = [
          ...badgeValues,
          badgeValue,
        ];
      } else {
        /*
         * INFO: Ist ein BadgeValue vorhanden, muss dieses entfernt
         * und das aktualisierte hinzugefügt werden.
         * Die Reihenfolge ist hierbei egal.
         */
        badgeValues.splice(index, 1);
        badgeValues.push(badgeValue);
      }

      return {
        ...state,
        badgeValues,
      };
    }
  ),

  on(
    BelegEntitiesActions.entheftenSuccess,
    (state, action) => {
      return belegEntitiesAdapter.removeOne(action.belegIdToRemove, {
        ...state,
        countedBelege: state.countedBelege - 1,
      });
    }
  ),

  on(
    BelegEntitiesActions.deleteBeleg,
    (state) => ({
      ...state,
      lastDeletedBelegData: initialBelegEntitiesState.lastDeletedBelegData,
    })
  ),

  on(
    BelegEntitiesActions.deleteBelegSuccess,
    (state, {inhaberId, belegId}) => {
      return belegEntitiesAdapter.removeOne(belegId, {
        ...state,
        lastDeletedBelegData: {
          inhaberId,
          belegId,
        },
        countedBelege: state.countedBelege - 1,
      });
    }
  ),

  on(
    BelegEntitiesActions.move,
    (state, action) => ({
      ...state,
      isMoving: true,
    })
  ),

  on(
    BelegEntitiesActions.moveSuccess,
    (state, action) => {
      return belegEntitiesAdapter.removeOne(action.belegId, {
        ...state,
        countedBelege: state.countedBelege - 1,
        isMoving: false,
      });
    }
  ),

  on(
    BelegEntitiesActions.moveFailure,
    (state, action) => ({
      ...state,
      isMoving: false,
    })
  ),

  /**
   * Sollte bei dem Laden eines neuen Belegs genau eine Dublette zu dem Beleg geben,
   * existiert bereits ein älterer Beleg (das Original).
   * Damit dieser in der Tabelle auch als Dublette gekennzeichnet wird, sofern nicht der Fall,
   * muss der Originale Beleg ebenfalls neu geladen werden.
   */
  on(
    BelegEntitiesActions.markOriginalBelegAsDublette,
    (state, {originalBelegId, dubletteId}) => {

      let belegDto = belegEntitiesAdapter
        .getSelectors()
        .selectAll(state)
        .find(belegDto => {
          return belegDto.id === originalBelegId;
        });

      if (belegDto && !belegDto.dubletten) {

        belegDto = {
          ...belegDto,
          dubletten: [
            dubletteId,
          ],
        };

        return belegEntitiesAdapter.updateOne({
            id: belegDto.id,
            changes: belegDto,
          },
          state,
        );
      }

      return {
        ...state,
      };
    }
  ),

  /**
   * Wird die letzte Dublette gelöscht, so soll der verbleibende Beleg (das Original)
   * nicht mehr als Dublette gekennzeichnet sein.
   */
  on(
    BelegEntitiesActions.unmarkOriginalBelegAsDublette,
    (state, {belegId}) => {

      let belegDto = belegEntitiesAdapter
        .getSelectors()
        .selectAll(state)
        .find(belegDto => {
          return belegDto.id === belegId;
        });

      if (belegDto) {

        belegDto = {
          ...belegDto,
          dubletten: undefined
        };

        return belegEntitiesAdapter.updateOne({
            id: belegDto.id,
            changes: belegDto,
          },
          state,
        );
      }

      return {
        ...state,
      };
    }
  ),

  /**
   * Im Store vermerken, dass das Stornieren eines Belegs im Gange ist.
   */
  on(
    BelegEntitiesActions.reverseBeleg,
    (state) => ({
      ...state,
      reverseBelegData: {
        belegReversing: true,
        korrekturBelegId: initialBelegEntitiesState.reverseBelegData.korrekturBelegId,
        reverseBelegSuccess: initialBelegEntitiesState.reverseBelegData.reverseBelegSuccess,
      },
    })
  ),

  /**
   * Bei erfolgreichen Stornieren den Store, sowie den alten Beleg aktualisieren.
   */
  on(
    BelegEntitiesActions.reverseBelegSuccess,
    (state, action) => {

      // INFO: Den alten Beleg vom Status auf storniert setzen
      let belegDto = belegEntitiesAdapter
        .getSelectors()
        .selectAll(state)
        .find(belegDto => {
          return belegDto.id === action.oldBelegId;
        });

      if (belegDto) {

        belegDto = {
          ...belegDto,
          offen: false,
          bearbeitet: false,
          festgeschrieben: false,
          storniert: true,
        };

        return belegEntitiesAdapter.updateOne({
            id: belegDto.id,
            changes: belegDto,
          },
          {
            ...state,
            reverseBelegData: {
              ...state.reverseBelegData,
              belegReversing: false,
              reverseBelegSuccess: true,
              korrekturBelegId: action.korrekturBelegId,
            },
          },
        );
      }

      return {
        ...state,
      };
    }
  ),

  /**
   * Bei fehlgeschlagenem Stornieren den Store aktualisieren.
   */
  on(
    BelegEntitiesActions.reverseBelegFailure,
    (state) => ({
      ...state,
      reverseBelegData: {
        ...state.reverseBelegData,
        belegReversing: false,
        reverseBelegSuccess: false,
      },
    })
  ),

  /**
   * Bei fehlgeschlagenem Stornieren den Store aktualisieren.
   */
  on(
    BelegEntitiesActions.resetReverseBelegData,
    (state) => ({
      ...state,
      reverseBelegData: initialBelegEntitiesState.reverseBelegData,
    })
  ),

  on(
    BelegEntitiesActions.readVorgangsartenSuccess,
    (state, action) => ({
      ...state,
      vorgangsartDtos: [
        ...state.vorgangsartDtos,
        ...action.vorgangsartDtos,
      ],
    })
  ),

);

