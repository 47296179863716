import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class ErrorpageService {

  constructor(
    private router: Router,
    private logger: NGXLogger,
  ) {
  }

  betriebNotFound(): void {
    this.logger.error('betrieb not found');

    this.router.navigate(['/error/betrieb-not-found/']);
  }

  serviceUnavailable(): void {
    this.logger.error('beleg-service unavailable');

    const errorUrl = '/error/service-unavailable';
    if (this.router.url !== errorUrl) {
      this.router.navigate([errorUrl]);
    }
  }
}
