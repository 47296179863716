import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, ReplaySubject, Subject, takeUntil} from 'rxjs';
import {tap} from 'rxjs/operators';


export interface LoggingConfig {
  level: number,
}

export interface AuthConfig {
  authority: string,
  clientId: string,
  scope: string,
  logLevel: number,
  renewTimeBeforeTokenExpiresInSeconds: number,
}

export interface BelegConfig {
  apiBaseUrl: string,
  naseBaseUrl: string,
}

export interface ZahlungConfig {
  apiBaseUrl: string,
}

export interface PortalConfig {
  targetOrigin: string,
}

export interface SentryConfig {
  enabled: boolean,
  debug: boolean,
  dsn: string,
  sampleRate: number,
}

export interface MatomoConfig {
  enabled: boolean,
  trackerUrl: string,
  siteId: string,
}

export interface IConfig {
  environment: string,
  logging: LoggingConfig,
  auth: AuthConfig,
  beleg: BelegConfig,
  zahlung: ZahlungConfig,
  portal: PortalConfig,
  sentry: SentryConfig,
  matomo: MatomoConfig,
}

export class Config
  implements IConfig {

  constructor(
    public environment: string,
    public logging: LoggingConfig,
    public auth: AuthConfig,
    public beleg: BelegConfig,
    public zahlung: ZahlungConfig,
    public portal: PortalConfig,
    public sentry: SentryConfig,
    public matomo: MatomoConfig,
  ) {
  }
}

@Injectable()
export class ConfigService
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private loaded = new ReplaySubject<Config>();

  private config_?: Config;

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    this.loaded.complete();
  }

  get loaded$() {
    return this.loaded.pipe(
      takeUntil(this.unsubscribe$),
    );
  }

  get config(): Config {
    return this.config_ as Config;
  }

  loadConfig() {
    return forkJoin([
      this.getConfig(),
    ]);
  }

  getConfig(): Observable<Config> {
    const url = '/assets/config/config.json';
    return this.httpClient.get<IConfig>(url)
      .pipe(
        tap(config => {
          this.config_ = new Config(
            config.environment,
            config.logging,
            config.auth,
            config.beleg,
            config.zahlung,
            config.portal,
            config.sentry,
            config.matomo,
          );
          this.loaded.next(this.config_);
        }),
      );
  }
}
