import {Injectable} from '@angular/core';
import {BelegFilter} from '../modules/common/left-panel/left-panel.service';
import {ZahlungDTO} from '../openapi/zahlung-openapi';
import {NGXLogger} from 'ngx-logger';


@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private logger: NGXLogger) {
  }

  /**
   * Setzt einen Wert in die URL. Wird der Parameter nicht gefunden, wird er hinten angehängt.
   * Format: /url/param/value
   * @param url: URL auf die die Manipulation angewendet werden soll
   * @param param: Name des Parameters (key), der geändert werden soll
   * @param value: Wert des Parameters
   */
  setParamToUrl(param: string, value: string, url?: string): string | undefined {
    let newUrl = '';
    let foundParam = false;
    let foundValue = false;

    if (!url) {
      url = location.pathname;
    }
    const parts = url.split('/');
    parts.shift();    /** da am Anfang der URL immer ein "/" ist, kann das erste Element gelöscht werden */

    for (const i in parts) {
      if (parts[+i - 1] === param) {
        /** wenn der Schleifendurchgang beim WERT des Parameters angekommen ist */
        newUrl += `/${value}`;
        foundValue = true;
      } else {
        newUrl += `/${parts[i]}`;
        if (parts[i] === param) {
          /** wenn der Schleifendurchgang beim NAMEN des Parameters angekommen ist */
          foundParam = true;
        }
      }
    }

    if (!foundParam) {
      newUrl += `/inhaber`;
    }
    if (!foundValue) {
      newUrl += `/${value}`;
    }

    return newUrl;
  }

  deepCopyFilter(belegFilter: BelegFilter): BelegFilter {
    return {
      ...belegFilter, // copy all fields
      filter: {
        ...belegFilter.filter, // deep copy filter object
      },
      pageable: {
        ...belegFilter.pageable // deep copy pageable object
      },
    };
  }

  calcPaymentState(zahlung: ZahlungDTO): string {
    if (zahlung.offen) {
      return 'Offen';
    } else if (zahlung.angewiesen) {
      return 'Angewiesen';
    } else {
      this.logger.error('Status der Zahlung unbekannt.');

      return '';
    }
  }
}
