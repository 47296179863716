import {Injectable} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {filter, map} from 'rxjs/operators';
import {NGXLogger} from 'ngx-logger';
import {Config} from '../config/service/config.service';


export enum BelegmailStatus {
  Undefined,
  Absent,
  Inactive,
  Active,
  Unauthenticated
}

export interface EmailAddressStatistics {
  processed14Days: number;
}

export interface EmailAddress {
  emailAddress: string;
  active: boolean;
  requireAuthentication: boolean;
  createdAt: string;
  statistics: EmailAddressStatistics;
  updatedAt: string;
}


@Injectable({
  providedIn: 'root'
})
export class BelegmailService {

  constructor(
    private logger: NGXLogger,
    private config: Config,
    private httpClient: HttpClient,
  ) {
  }

  private naseUrl(): string | undefined {
    const naseBaseUrl = this.config?.beleg?.naseBaseUrl;
    if (naseBaseUrl) {
      const tenant = 'just-farming';
      const context = 'belege';
      return `${naseBaseUrl}/api/v1/tenant/${tenant}/${context}`;
    }
    return undefined;
  }

  createEmailAddress(
    betriebId: string,
  ): Observable<HttpResponse<any>> {
    const naseUrl = this.naseUrl();
    if (naseUrl) {
      const url = `${naseUrl}`;

      const body = {
        betriebId,
        // info: Im Browser folgt ein XHR-Aufruf automatisch einem Redirect.
        // Dies lässt sich leider nicht verhindern.
        // Dadurch kann der Location-Header nicht abgefragt werden, um ein Popup zu öffnen.
        // Um dies umgehen zu können, kann noRedirect=true gesetzt werden.
        // Dann wird HTTP 204 No Content zusammen mit dem Location-Header zurückgegeben
        noRedirect: true,
      };

      return this.httpClient.post(url, body, {
        observe: 'response',
        responseType: 'text',
      });
    }
    return EMPTY;
  }

  loadEmailAddress(
    betriebId: string,
  ): Observable<EmailAddress> {
    if (betriebId === undefined) {
      return EMPTY;
    }
    const naseUrl = this.naseUrl();
    if (!naseUrl) {
      return EMPTY;
    }

    const url = `${naseUrl}/?betriebId=${betriebId}`;
    return this.httpClient.get<EmailAddress>(url, {});
  }

  loadVcardImage(
    betriebId: string,
  ): Observable<Blob> {
    if (betriebId === undefined) {
      return EMPTY;
    }
    const naseUrl = this.naseUrl();
    if (!naseUrl) {
      return EMPTY;
    }

    const url = `${naseUrl}/vcard/image/?betriebId=${betriebId}&resolution=300`;
    return this.httpClient.get(url, {responseType: 'blob'});
  }

  downloadVcard(
    betriebId: string,
  ): void {
    if (betriebId === undefined) {
      return;
    }
    const naseUrl = this.naseUrl();
    if (!naseUrl) {
      return;
    }

    const url = `${naseUrl}/vcard/?betriebId=${betriebId}`;
    this.httpClient.get(url, {observe: 'events', responseType: 'blob'})
      .pipe(
        filter(value => value instanceof HttpResponse),
        map(value => value as HttpResponse<Blob>),
      )
      .subscribe({
        next: event => {
          const objectUrl = window.URL.createObjectURL(event.body as Blob);

          let filename = event.headers.get('content-disposition')?.split(';')[1].split('=')[1].replace(/\"/g, '');
          if (!filename) {
            filename = 'kontakt.vcf';
          }

          const a = document.createElement('a');
          a.href = objectUrl;
          a.download = filename;
          a.click();

          window.URL.revokeObjectURL(objectUrl);
        },
        error: err => {
          this.logger.debug('vCard failed', err);
        }
      });
  }

  updateEmailAddress(
    betriebId: string,
    active: boolean,
  ): Observable<HttpResponse<any>> {
    const naseUrl = this.naseUrl();
    if (naseUrl) {
      const url = `${naseUrl}?betriebId=${betriebId}`;

      const body = {
        active,
      };

      return this.httpClient.put(url, body, {
        observe: 'response',
        responseType: 'text',
      });
    }
    return EMPTY;
  }
}
