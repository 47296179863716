<div class="table-wrapper mat-elevation-z0">

  <div class="filter">

    <div class="upper-filter-row">

      <div>
        <bo-searchfield
          [value]="searchValue"
          (valueChanged)="updateSearchFilter($event)"
        ></bo-searchfield>

        <bo-filter-status
          [value]="statusFilter"
          (valueChanged)="updateStatusFilter($event)"
        ></bo-filter-status>
      </div>

      <button mat-flat-button (click)="toggleExpansionpanel()">
        <bo-icon
          class="filter-icon"
          icon="common:filter"
          primaryStroke="#333333"
        ></bo-icon>
        {{filterVisible  ? 'Filter verbergen' : 'Filter anzeigen'}}
        {{filterCounter ? '(' + filterCounter + ')' : ''}}
      </button>
    </div>

    <div class="lower-filter-row">

      <mat-accordion>
        <mat-expansion-panel hideToggle>
          <div class="sub-filter-row">
            <bo-filter-created-date
              [(value)]="createdDateValue"
              (valueChange)="setCreatedState($event)"
            ></bo-filter-created-date>

            <bo-filter-belegtyp
              [options]="vorgangsartDtos"
              [value]="belegTypValue"
              (valueChange)="setVorgangsartIds($event)"
            ></bo-filter-belegtyp>

            <bo-filter-eingang-ausgang
              [(value)]="eingangAusgangValue"
              (valueChange)="setEingangAusgangState($event)"
            ></bo-filter-eingang-ausgang>

            <bo-filter-dublette
              [(value)]="showDubletteValue"
              (valueChange)="filterDublette($event)"
            ></bo-filter-dublette>
          </div>

          <div class="sub-filter">
            <div class="sub-filter-row">
              <bo-filter-beleg-date
                [(value)]="belegDateValue"
                (valueChange)="setBelegDateState($event)"
              ></bo-filter-beleg-date>

              <bo-filter-betrag-von-bis
                [valueVon]="betragVon"
                [valueBis]="betragBis"
                (valueVonChange)="setBetragVon($event)"
                (valueBisChange)="setBetragBis($event)"
              ></bo-filter-betrag-von-bis>
            </div>
          </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <jf-filter-chip-row
      *ngIf="filterChips.length > 0"
      class="filter-chip-row"
      [items]="filterChips"
      (onResetFilter)="resetFilter($event)"
      (onResetAllFilters)="resetAllFilter()"
    ></jf-filter-chip-row>
  </div>

  <mat-multi-sort-table-settings
    *ngIf="table"
    [tableData]="table"
    sortToolTip="Sortierreihenfolge ändern"
    [closeDialogOnChoice]="false"
  >
    <button
      mat-icon-button
      matTooltip="Spalten bearbeiten"
      matTooltipPosition="left"
      aria-label="Spalten bearbeiten"
    >
      <bo-icon icon="common:gear" primaryFill="white"></bo-icon>
    </button>
  </mat-multi-sort-table-settings>
  <div class="table-container" #tableContainer>

    <table mat-table matMultiSort [dataSource]="belege" (matSortChange)="table?.onSortEvent(); onSortData()">
      <ng-container matColumnDef="{{BelegListColumn.Checkbox}}">
        <th
          *matHeaderCellDef
          mat-header-cell
          class="table-cell table-cell-header table-cell-checkbox"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="masterToggle($event)"
            [checked]="((areAllIdsSelected$ | async) || false)"
            [indeterminate]="((isMasterIndeterminate$ | async) || false)"
          >
          </mat-checkbox>
        </th>
        <td
          *matCellDef="let row"
          mat-cell
          class="no-pointer"
        >
          <mat-checkbox
            *ngIf="{ selected: isSelected(row) | async } as data"
            (change)="checkboxChanged($event, row)"
            [checked]="(!!data.selected)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{BelegListColumn.Status}}">
        <th
          *matHeaderCellDef
          mat-multi-sort-header="status"
          mat-header-cell
          class="table-cell table-cell-header table-cell-status"
        >Status</th>
        <td
          *matCellDef="let element"
          mat-cell
          class="cursor-pointer table-cell-status-td"
          (click)="doOpenEditBeleg([element])"
        >
          <div class="status-wrapper">
            <bo-icon
              class="mat-table-icon"
              icon="common:status"
              primaryStroke="black"
              [primaryFill]="calcBelegStatusIconColor(element)">
            </bo-icon>
            <div class="mat-table-icon-text">
              {{ calcBelegStatus(element) }}
              <span
                class="dublette-status"
                *ngIf="!element.storniert && element.dubletten && element.dubletten.length > 0"
              >
                <br>
                Dublette
              </span>
              <bo-aktionen
                [beleg]="element"
              ></bo-aktionen>
            </div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="erstellt">
        <th
          *matHeaderCellDef
          mat-multi-sort-header="erstellt"
          mat-header-cell
          class="table-cell table-cell-header table-cell-hochgeladen"
        >Hochgeladen am</th>
        <td mat-cell *matCellDef="let element" (click)="doOpenEditBeleg([element])">
          {{element.erstellt | date:'mediumDate'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="vorgangsart.bezeichnung">
        <th
          *matHeaderCellDef
          mat-multi-sort-header="vorgangsart.bezeichnung"
          mat-header-cell
          class="table-cell table-cell-header table-cell-belegtyp"
        >Belegtyp</th>
        <td
          *matCellDef="let element"
          mat-cell
          (click)="doOpenEditBeleg([element])"
        >{{element.vorgangsart.bezeichnung}}</td>
      </ng-container>

      <ng-container matColumnDef="belegbucheinAusgang">
        <th
          *matHeaderCellDef
          mat-multi-sort-header="belegbucheinAusgang"
          mat-header-cell
          class="table-cell table-cell-header table-cell-einausgang"
        >Ein-/Ausgang</th>
        <td
          *matCellDef="let element"
          mat-cell
          (click)="doOpenEditBeleg([element])"
          >{{element.belegbucheinAusgang === 'Belegeingang' ? 'Eingang' : 'Ausgang'}}</td>
      </ng-container>

      <ng-container matColumnDef="kommentar">
        <th
          *matHeaderCellDef
          mat-multi-sort-header="kommentar"
          mat-header-cell
          class="table-cell table-cell-header table-cell-kommentar"
        >Kommentar</th>
        <td
          *matCellDef="let element"
          mat-cell
          (click)="doOpenEditBeleg([element])"
        >
          <div class="table-cell-text-overflow">
            {{element.kommentar}}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="vonBuchstelle">
        <th
          *matHeaderCellDef
          mat-multi-sort-header="vonBuchstelle"
          mat-header-cell
          class="table-cell table-cell-header table-cell-buchstelle"
        >von Buchstelle</th>
        <td
          *matCellDef="let element"
          mat-cell
          class="table-cell-buchstelle-td"
          (click)="doOpenEditBeleg([element])"
        >
          <bo-icon *ngIf="element.uploadBuchstelle" icon="common:buchstelle" primaryFill="black"></bo-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="partner.bezeichnung">
        <th
          *matHeaderCellDef
          mat-multi-sort-header="partner.bezeichnung"
          mat-header-cell
          class="table-cell table-cell-header table-cell-kunde-lieferant"
        >
          Kunde/Lieferant
        </th>
        <td
          *matCellDef="let element"
          mat-cell
          (click)="doOpenEditBeleg([element])"
        >
          <ng-container *ngIf="element.partner; else defaultValue">
            <div class="table-cell-text-overflow">
              {{element.partner.bezeichnung}}
            </div>
          </ng-container>
        </td>
        <ng-template #defaultValue>
        </ng-template>
      </ng-container>

      <ng-container matColumnDef="belegDatum">
        <th
          *matHeaderCellDef
          mat-multi-sort-header="belegDatum"
          mat-header-cell
          class="table-cell table-cell-header table-cell-belegdatum"
        >
          Belegdatum
        </th>
        <td
          *matCellDef="let element"
          mat-cell
          (click)="doOpenEditBeleg([element])"
          >
          {{element.belegDatum | date:'mediumDate'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{BelegListColumn.Betrag}}">
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-multi-sort-header="betrag"
          class="table-cell table-cell-header table-cell-betrag"
        >
          Betrag
        </th>
        <td *matCellDef="let element" (click)="doOpenEditBeleg([element])"
          class="cursor-pointer text-end table-cell-betrag-td"
        mat-cell>
          {{element.betrag | currency:'EUR' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{BelegListColumn.Seitenzahl}}">
        <th mat-header-cell *matHeaderCellDef mat-header
          class="table-cell table-cell-header table-cell-seiten"
        >
          Seitenzahl
        </th>
        <td *matCellDef="let element" (click)="doOpenEditBeleg([element])" class="cursor-pointer text-center" mat-cell>
          {{ element.seitenanzahl > 0 ? element.seitenanzahl : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{BelegListColumn.Aktion}}">
        <th mat-header-cell *matHeaderCellDef
          class="table-cell table-cell-header table-cell-settings"
          #lastTableCol
        >
        </th>
        <td *matCellDef="let element" class="text-end table-cell-actions no-pointer" mat-cell>
          <div class="btn-group" role="group">
            <button
              *ngIf="!element.storniert && element.dubletten && element.dubletten.length > 0"
              class="dublette-btn"
              (click)="doOpenHandleDubletteDialog(element)"
              mat-icon-button
              matTooltipTouchGestures="on"
              matTooltip="Dublette"
              matTooltipPosition="above"
              matomoClickCategory="bo-list"
              matomoClickAction="dublette"
            >
              <bo-icon class="aktion-icon" icon="common:warn" primaryStroke="red"></bo-icon>
            </button>

            <button mat-icon-button
                    class="aktion-button-left"
                    (click)="openPreview(element.inhaberId, element.id)"
                    color="accent"
                    matTooltipTouchGestures="on"
                    matTooltip="Vorschau"
                    matTooltipPosition="above"
                    matomoClickCategory="bo-list"
                    matomoClickAction="vorschau"
            >
              <bo-icon class="aktion-icon" icon="actions:fullscreen" primaryStroke="currentcolor"></bo-icon>
            </button>

            <span
              [matTooltip]="!element.storniert ? (!element.festgeschrieben ? 'Löschen' : 'Stornieren') : ''"
              [matTooltipDisabled]="element.storniert"
              matTooltipPosition="above"
            >
              <button
                mat-icon-button
                class="aktion-button-mid"
                [disabled]="element.storniert"
                (click)="deleteOrRevert([element])"
                color="accent"
                matomoClickCategory="bo-list"
                matomoClickAction="loeschen"
                [matomoClickName]="element.festgeschrieben ? 'stornieren' : 'loeschen'"
              >
                <bo-icon
                  *ngIf="!element.storniert"
                  class="aktion-icon"
                  icon="actions:delete"
                  primaryStroke="currentcolor"
                ></bo-icon>

                <bo-icon
                  *ngIf="element.storniert"
                  class="aktion-icon"
                  icon="actions:delete"
                  primaryStroke="#919191"
                ></bo-icon>
              </button>
            </span>
            <button class="aktion-button-right" color="accent" mat-icon-button [matMenuTriggerFor]="menu">
              <bo-icon class="aktion-icon" icon="actions:more" primaryFill="currentcolor"></bo-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button
                mat-menu-item
                class="mat-menu-item-font"
                (click)="doDownloadBeleg(element)"
                matomoClickCategory="bo-list"
                matomoClickAction="herunterladen"
              >
                <bo-icon icon="actions:download" primaryStroke="#414141" primaryFill="#414141"></bo-icon>
                <span class="mat-menu-item-font">Herunterladen</span>
              </button>

              <button mat-menu-item
                      class="mat-menu-item-font"
                      *ngIf="moveDestinationInhaber.length >= 1"
                      (click)="move(element.inhaberId, [element])"
                      [disabled]="!isOffenBearbeitet(element)"
                      matomoClickCategory="bo-list"
                      matomoClickAction="verschieben"
              >
                <bo-icon *ngIf="isOffenBearbeitet(element)" icon="actions:move" primaryStroke="#414141"
                         primaryFill="#414141"></bo-icon>
                <bo-icon *ngIf="!isOffenBearbeitet(element)" icon="actions:move" primaryStroke="#919191"
                         primaryFill="#919191"></bo-icon>
                <span>Verschieben</span>
              </button>

              <button mat-menu-item
                      class="mat-menu-item-font"
                      (click)="doSlice([element])"
                      [disabled]="!isSlicable(element)"
                      matomoClickCategory="bo-list"
                      matomoClickAction="trennen"
              >
                <bo-icon *ngIf="isSlicable(element)" icon="actions:slice" primaryStroke="#414141"></bo-icon>
                <bo-icon *ngIf="!isSlicable(element)" icon="actions:slice" primaryStroke="#919191"></bo-icon>
                <span>Trennen</span>
              </button>

              <button mat-menu-item
                      class="mat-menu-item-font"
                      [disabled]="!isSplicable(element)"
                      (click)="doSplice([element])"
                      matomoClickCategory="bo-list"
                      matomoClickAction="zusammenfuegen"
              >
                <bo-icon *ngIf="isSplicable(element)" icon="actions:splice" primaryStroke="#414141"></bo-icon>
                <bo-icon *ngIf="!isSplicable(element)" icon="actions:splice" primaryStroke="#919191"></bo-icon>
                <span>Zusammenfügen</span>
              </button>
              <button mat-menu-item
                      class="mat-menu-item-font"
                      (click)="stipulate([element])"
                      [disabled]="!isOffenBearbeitet(element) || hasDubletten(element)"
                      matomoClickCategory="bo-list"
                      matomoClickAction="fertigstellen"
              >
                <bo-icon *ngIf="isOffenBearbeitet(element)" icon="status:stapled" primaryStroke="#414141"
                         primaryFill="#414141"></bo-icon>
                <bo-icon *ngIf="!isOffenBearbeitet(element)" icon="status:stapled" primaryStroke="#919191"
                         primaryFill="#919191"></bo-icon>
                <span>Fertigstellen</span>
              </button>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="table">
        <tr mat-header-row *matHeaderRowDef="table.displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: table.displayedColumns;"></tr>
      </ng-container>
    </table>

    <div *ngIf="belege.length === 0" class="empty-list-icon container">
      <div class="hint">
        <div class="hint-icon">
          <bo-icon icon="dragdrop:dragdrop" primaryFill="#D0D0D0"></bo-icon>
        </div>
        <div class="hint-text">
          <div>
            Ziehe deine Belege hier rein um diese hochzuladen
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="table-actions">
  <div class="paginator-wrapper">
    <mat-paginator
      [length]="countedBelege"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      (page)="doUpdatePageIndex($event.pageIndex)"
      showFirstLastButtons
    ></mat-paginator>
  </div>
  <div class="actions">
    <button
      mat-stroked-button
      color="accent"
      class="action-more"
      [matMenuTriggerFor]="moreActionsMenu"
      [disabled]="!((selectedBelege$ | async)?.length)"
    >
      <bo-icon icon="actions:more" primaryFill="currentcolor"></bo-icon>
    </button>
    <mat-menu class="mat-menu-border more-actions-menu" #moreActionsMenu="matMenu" xPosition="before">

      <!-- Verschieben -->
      <span
        matTooltip="Kann nicht auf Dubletten und Belegen mit Status Fertiggestellt oder Storniert ausgeführt werden"
        [matTooltipDisabled]="!((selectedBelege$ | async)?.length) || (!moveDeleteDisabled && !!((selectedBelege$ | async)?.length))"
        matTooltipPosition="below"
      >
        <button
          *ngIf="moveDestinationInhaber.length > 0"
          mat-menu-item
          color="accent"
          class="action mat-menu-item-font"
          [disabled]="moveDeleteDisabled"
          (click)="move((this.inhaberId || ''), selectedBelege$.getValue())"
          matomoClickCategory="bo-overview"
          matomoClickAction="verschieben"
        >Verschieben
        </button>
      </span>

      <!-- Trennen -->
      <span
        matTooltip="Kann nicht auf Belegen mit Status Fertiggestellt oder Storniert ausgeführt werden"
        [matTooltipDisabled]="!((selectedBelege$ | async)?.length) || (!moveDeleteDisabled && !!((selectedBelege$ | async)?.length))"
        matTooltipPosition="below"
      >
        <button
          mat-menu-item
          color="accent"
          class="action mat-menu-item-font"
          (click)="doSlice(selectedBelege$.getValue())"
          [disabled]="sliceDisabled"
          matomoClickCategory="bo-overview"
          matomoClickAction="trennen"
        >Trennen
        </button>
      </span>

      <!-- Zusammenfügen -->
      <span
        [matTooltip]="((selectedBelege$ | async)?.length || 0) < 2
        ? 'Kann nur auf zwei oder mehr Belegen ausgeführt werden'
        : 'Kann nicht auf Belegen mit Status Fertiggestellt oder Storniert ausgeführt werden'"
        [matTooltipDisabled]="!spliceDisabled"
        matTooltipPosition="below"
      >
        <button
          mat-menu-item
          color="accent"
          class="action mat-menu-item-font"
          (click)="doSplice(selectedBelege$.getValue())"
          [disabled]="spliceDisabled"
          matomoClickCategory="bo-overview"
          matomoClickAction="zusammenfuegen"
        >Zusammenfügen
        </button>
      </span>

      <!-- Löschen -->
      <span
        matTooltip="Kann nicht auf Belegen mit Status Fertiggestellt oder Storniert ausgeführt werden"
        [matTooltipDisabled]="!((selectedBelege$ | async)?.length) || (!moveDeleteDisabled && !!((selectedBelege$ | async)?.length))"
        matTooltipPosition="below"
      >
        <button
          mat-menu-item
          color="accent"
          class="action mat-menu-item-font"
          [disabled]="moveDeleteDisabled"
          (click)="deleteOrRevert(selectedBelege$.getValue())"
          matomoClickCategory="bo-overview"
          matomoClickAction="loeschen"
        >Löschen
        </button>
      </span>

    </mat-menu>
    <span>
      <button
        mat-raised-button
        color="accent"
        class="action"
        (click)="doOpenEditBeleg(selectedBelege$.getValue())"
        [disabled]="!((selectedBelege$ | async)?.length)"
        matomoClickCategory="bo-overview"
        matomoClickAction="bearbeiten"
      >Bearbeiten</button>
    </span>
    <span
      matTooltip="Kann nicht auf Dubletten und Belegen mit Status Fertiggestellt oder Storniert ausgeführt werden"
      [matTooltipDisabled]="!((selectedBelege$ | async)?.length) || (!(moveDeleteDisabled || stipulateDisabled) && !!((selectedBelege$ | async)?.length))"
      matTooltipPosition="below"
    >
    <button
      mat-raised-button
      color="accent"
      class="action"
      [disabled]="moveDeleteDisabled || stipulateDisabled"
      (click)="stipulate(selectedBelege$.getValue())"
      matomoClickCategory="bo-overview"
      matomoClickAction="fertigstellen"
    >Fertigstellen</button>
  </span>
  </div>
</div>
