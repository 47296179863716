import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';


export class DeleteDialogSelectors {

  private static select = {
    belegIds: (state: AppState) => state.deleteDialog.belegIds,
  };

  public static belegIds = createSelector(
    this.select.belegIds,
    identity,
  );

}
