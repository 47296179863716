import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


export type IconNamespace =
  'common'
  | 'card'
  | 'pdf-viewer'
  | 'status'
  | 'actions'
  | 'leftpanel'
  | 'widgets'
  | 'dragdrop';

export interface IconSpec {
  namespace: IconNamespace;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class IconService {

  iconList: IconSpec[] = [
    {
      namespace: 'status',
      name: 'stapled',
    },
    {
      namespace: 'common',
      name: 'status',
    },
    {
      namespace: 'common',
      name: 'status_success',
    },
    {
      namespace: 'common',
      name: 'status_edit',
    },
    {
      namespace: 'common',
      name: 'status_canceled',
    },
    {
      namespace: 'common',
      name: 'status_open',
    },
    {
      namespace: 'actions',
      name: 'fullscreen',
    },
    {
      namespace: 'actions',
      name: 'more',
    },
    {
      namespace: 'actions',
      name: 'delete',
    },
    {
      namespace: 'actions',
      name: 'download',
    },
    {
      namespace: 'actions',
      name: 'move',
    },
    {
      namespace: 'actions',
      name: 'print',
    },
    {
      namespace: 'actions',
      name: 'close',
    },
    {
      namespace: 'actions',
      name: 'arrow_up',
    },
    {
      namespace: 'actions',
      name: 'arrow_down',
    },
    {
      namespace: 'leftpanel',
      name: 'folder',
    },
    {
      namespace: 'actions',
      name: 'prev',
    },
    {
      namespace: 'actions',
      name: 'next',
    },
    {
      namespace: 'actions',
      name: 'first',
    },
    {
      namespace: 'actions',
      name: 'last',
    },
    {
      namespace: 'actions',
      name: 'slice',
    },
    {
      namespace: 'actions',
      name: 'splice',
    },
    {
      namespace: 'common',
      name: 'search',
    },
    {
      namespace: 'common',
      name: 'gear',
    },
    {
      namespace: 'dragdrop',
      name: 'dragdrop',
    },
    {
      namespace: 'common',
      name: 'file',
    },
    {
      namespace: 'common',
      name: 'pen',
    },
    {
      namespace: 'widgets',
      name: 'pin',
    },
    {
      namespace: 'widgets',
      name: 'next',
    },
    {
      namespace: 'widgets',
      name: 'info',
    },
    {
      namespace: 'common',
      name: 'close',
    },
    {
      namespace: 'common',
      name: 'menu',
    },
    {
      namespace: 'common',
      name: 'error_circle',
    },
    {
      namespace: 'common',
      name: 'info_circle',
    },
    {
      namespace: 'common',
      name: 'success',
    },
    {
      namespace: 'common',
      name: 'buchstelle',
    },
    {
      namespace: 'common',
      name: 'expand',
    },
    {
      namespace: 'common',
      name: 'beleg',
    },
    {
      namespace: 'common',
      name: 'dot',
    },
    {
      namespace: 'common',
      name: 'utility-widget-empty-state',
    },
    {
      namespace: 'common',
      name: 'circumflex_left',
    },
    {
      namespace: 'common',
      name: 'circumflex_right',
    },
    {
      namespace: 'common',
      name: 'circumflex_top',
    },
    {
      namespace: 'common',
      name: 'circumflex_bottom',
    },
    {
      namespace: 'common',
      name: 'rotate_right',
    },
    {
      namespace: 'common',
      name: 'print',
    },
    {
      namespace: 'common',
      name: 'download_pdf_viewer',
    },
    {
      namespace: 'common',
      name: 'warn',
    },
    {
      namespace: 'common',
      name: 'dublette',
    },
    {
      namespace: 'common',
      name: 'arrow_left',
    },
    {
      namespace: 'common',
      name: 'access_error',
    },
    {
      namespace: 'common',
      name: 'arrow_up',
    },
    {
      namespace: 'common',
      name: 'tick',
    },
    {
      namespace: 'common',
      name: 'filter',
    },
    {
      namespace: 'common',
      name: 'flag',
    },
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.iconList.forEach(icon => {
      iconRegistry.addSvgIconInNamespace(
        icon.namespace,
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.namespace}/${icon.name}.svg`));
    });
  }
}
