import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {MoveBelegData} from './move-beleg-data.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../../store/states/app.state';
import {BehaviorSubject, combineLatest, filter, map, Subject, switchMap, take, takeUntil} from 'rxjs';
import {BelegEntitiesActions} from '../../store/actions/beleg-entities.actions';
import {InhaberDTO} from '../../openapi/beleg-openapi';
import {BelegEntitiesSelectors} from '../../store/selectors/beleg-entities.selectors';
import {MoveBelegDialogActions} from '../../store/actions/move-beleg-dialog.actions';
import {NavigationService} from '@adnova/jf-ng-components';


@Component({
  selector: 'bo-move-beleg-dialog',
  templateUrl: './move-beleg-dialog.component.html',
  styleUrls: ['./move-beleg-dialog.component.scss']
})
export class MoveBelegDialogComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  protected sourceInhaberId$ = new BehaviorSubject<string | undefined>(undefined);

  protected belegIds$ = new BehaviorSubject<string[]>([]);

  protected moveDestinationInhabers$ = new BehaviorSubject<InhaberDTO[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA) public moveBelegData: MoveBelegData,
    private store: Store<AppState>,
    private navigationService: NavigationService,
  ) {
  }

  ngOnInit(): void {
    this.sourceInhaberId$.next(this.moveBelegData.sourceInhaberId);
    this.belegIds$.next(this.moveBelegData.belegIds);

    this.navigationService.inhaberList$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhabers => {
      const moveDestinationInhabers = inhabers.filter(inhaber => inhaber.id !== this.moveBelegData.sourceInhaberId);
      this.moveDestinationInhabers$.next(moveDestinationInhabers);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected move(destinationInhaberId: string): void {
    combineLatest([
      this.sourceInhaberId$,
      this.belegIds$,
    ]).pipe(

      // INFO: Es werden nur einmal die Daten ausgelesen, danach wird die Subscription direkt wieder beendet.
      take(1),

      // INFO: Wenn die Daten unvollständig sind, soll nichts weiter passieren.
      filter(([sourceInhaberId, belegIds]) => !!sourceInhaberId && !!belegIds.length),
      map(([sourceInhaberId, belegIds]) => ({
        sourceInhaberId: sourceInhaberId!,
        belegIds: belegIds!
      })),

      // INFO: Belege aus den BelegIds laden
      switchMap(({sourceInhaberId, belegIds}) => {
        return this.store.select(BelegEntitiesSelectors.belegeByIds(sourceInhaberId, belegIds)).pipe(
          take(1),
          map(belege => ({
            sourceInhaberId,
            belege,
          })),
        );
      }),

    ).subscribe(({sourceInhaberId, belege}) => {
      this.store.dispatch(BelegEntitiesActions.move({sourceInhaberId, belege, destinationInhaberId}));
    });
  }

  protected closeDialog(): void {
    this.store.dispatch(MoveBelegDialogActions.close());
  }

}
