import {BelegStipulatedDialogState} from '../states/beleg-stipulated-dialog.state';
import {createReducer, on} from '@ngrx/store';
import {BelegStipulatedDialogActions} from '../actions/beleg-stipulated-dialog.actions';


export const initialBelegStipulatedDialogState: BelegStipulatedDialogState = {
  isOpen: false,
};

export const belegStipulatedDialogReducer = createReducer(
  initialBelegStipulatedDialogState,

  on(
    BelegStipulatedDialogActions.openBelegStipulatedDialog,
    (state, action) => ({
      ...state,
      isOpen: true,
      reuseBelegData: action.data,
    })
  ),

  on(
    BelegStipulatedDialogActions.closeBelegStipulatedDialog,
    () => ({
      ...initialBelegStipulatedDialogState,
    })
  ),
);
