import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {BelegEntitiesSelectors} from './beleg-entities.selectors';


export class BelegStipulatedDialogSelectors {

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    isBelegStipulatedDialogOpen: (state: AppState) => state.belegStipulatedDialog?.isOpen,
    reuseBelegData: (state: AppState) => state.belegStipulatedDialog?.reuseBelegData,
  };

  public static isBelegStipulatedDialogOpen = createSelector(
    this.select.isBelegStipulatedDialogOpen,
    identity,
  );

  public static reuseBelegData = createSelector(
    this.select.reuseBelegData,
    identity,
  );

  public static showLinkedPayment = createSelector(
    BelegEntitiesSelectors.all,
    this.select.reuseBelegData,
    (all, reuseBelegData) => {
      if (reuseBelegData) {
        //INFO: Selektieren des Belegs aus den belegEntities anhand der reuseBelegData Ids
        const belegByIdSelector = BelegEntitiesSelectors.belegById(reuseBelegData.inhaberId, reuseBelegData.belegId);
        //INFO: Selektieren der haskontoumsatzIds aus dem Beleg anhand der reuseBelegData Ids
        const haskontoumsatzIdsSelector = BelegEntitiesSelectors.haskontoumsatzIds(reuseBelegData.inhaberId, reuseBelegData.belegId);
        //INFO: Anwenden der Projektorfunktionen der Selektoren auf die Werte aus `all` (Output von `BelegEntitiesSelectors.all`)
        return haskontoumsatzIdsSelector.projector(belegByIdSelector.projector(all));

      }
      return false;
    }
  );
}
