<div class="{{isFullscreenDialog ? 'fullscreen' : ''}}">
  <div
    *ngIf="isFullscreenDialog"
    class="preview-header d-flex flex-direction-row align-items-center justify-content-between"
  >
    <div>PDF Vorschau</div>
    <div class="d-flex flex-direction-row align-items-center close" (click)="doClosePreview()">
      <div>Vorschau schließen</div>
      <bo-icon icon="actions:close" primaryStroke="#414141"></bo-icon>
    </div>
  </div>
  <div class="preview-component">
    <ng-container *ngIf="beleg$ | async as beleg">
      <ng-container *ngIf="beleg">
        <ng-container *ngIf="isPdfOrXrechnung(beleg); else downloadPreview">
          <bo-preview-pdf [beleg$]="beleg$" (closePreview)="doClosePreview()"></bo-preview-pdf>
        </ng-container>
        <ng-template #downloadPreview>
          <bo-download-preview [beleg$]="beleg$" (closePreview)="doClosePreview()"></bo-download-preview>
        </ng-template>
      </ng-container>
    </ng-container>
  </div>
</div>
