import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {MatLegacySelect as MatSelect} from '@angular/material/legacy-select';
import {VorgangsartDTO} from '../../../../../openapi/beleg-openapi';
import {FormControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';


interface SelectOption {
  vorgangsartId: string;
  display: string;
};

@Component({
  selector: 'bo-filter-belegtyp',
  templateUrl: './filter-belegtyp.component.html',
  styleUrls: ['./filter-belegtyp.component.scss']
})
export class FilterBelegtypComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatSelect) protected select?: MatSelect;

  @Input() set value(vorgangsartIds: string[]) {
    this.control.setValue(
      vorgangsartIds,
      {emitEvent: false}
    );
  }

  @Input() set options(vorgangsartDtos: VorgangsartDTO[]) {
    this.selectOptions = vorgangsartDtos.map(vorgangsartDto => ({
      vorgangsartId: vorgangsartDto.id,
      display: vorgangsartDto.bezeichnung,
    }));
  }

  @Output() protected valueChange = new EventEmitter<string[]>();

  protected selectOptions: SelectOption[] = [];

  protected control = new FormControl();

  private readonly unsubscribe$ = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.control.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((values: string[]) => {
      this.valueChange.emit(values);
    });
  }

  ngAfterViewInit() {

    // INFO: Handling für das automatische Schließen des Mat-Selects bei Verlassen mit der Maus
    if (this.select) {
      this.select.openedChange.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(opened => {
        if (opened) {
          let mouseInsideSelect = false;
          let mouseLeaveTimer: NodeJS.Timeout;

          // INFO: Funktion zum Schließen des Dropdown-Menüs bei Verlassen mit der Maus
          const closeSelectOnMouseLeave = () => {
            mouseLeaveTimer = setTimeout(() => {
              if (!mouseInsideSelect) {
                this.select!.close();
              }
            }, 500); // INFO: Delay von 500 Millisekunden
          };

          /*
           * INFO:
           * Kehrt die Maus innerhalb des Delays in das Mat-Select zurück,
           * nachdem sie dieses vorerst verlassen hatte, soll das Mat-select geöffnet bleiben.
           */
          const element = this.select!.panel.nativeElement;

          element.addEventListener('mouseenter', () => {
            mouseInsideSelect = true;
            clearTimeout(mouseLeaveTimer);
          });

          element.addEventListener('mouseleave', () => {
            mouseInsideSelect = false;
            closeSelectOnMouseLeave();
          });
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getValueChange() {
    return this.valueChange;
  }

  getControl() {
    return this.control;
  }

  getSelect() {
    return this.select;
  }

}
