<mat-form-field [appearance]="'outline'">
  <mat-label>Belegdatum von - bis</mat-label>
  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input
      matStartDate
      formControlName="from"
      placeholder="Von"
      min="1900-01-01"
      max="2099-12-31"
    >
    <input
      matEndDate
      formControlName="to"
      placeholder="Bis"
      min="1900-01-01"
      max="2099-12-31"
    >
  </mat-date-range-input>
  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>
