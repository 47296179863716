import {Injectable} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {from} from 'rxjs';
import {PortalMessageBroker} from '../portal/portal-message-broker';
import {OverviewService} from './overview.service';
import {BelegIdentity} from '../interfaces/beleg-identity.interface';
import {EditPageData} from '../interfaces/edit-page.data.interface';
import {Store} from '@ngrx/store';
import {UtilityWidgetService} from '../modules/widget/utility-widget/utility-widget-service';
import {AppState} from '../store/states/app.state';
import {ZahlungEntitiesActions} from '../store/actions/zahlung-entities.actions';


export interface EditOpenDataV1 {
  readonly inhaberId: string;
  readonly belegId: string;
}

export interface EditZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungId: string;
  readonly belegId?: string;
}

export interface DeleteZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungId: string;
}

export interface NewZahlungDataV1 {
  readonly inhaberId: string;
  readonly belegId: string;
}

export interface OverviewOpenDataV1 {
  readonly inhaberId: string;
  readonly filter?: OverviewOpenFilterDataV1;
}

export interface OverviewOpenFilterDataV1 {
  readonly vonBuchstelle?: boolean;
  readonly offen?: boolean;
  readonly bearbeitet?: boolean;
  readonly festgeschrieben?: boolean;
  readonly storniert?: boolean;
  readonly searchText?: string;
}

export interface UpdatedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface CreatedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface DeletedZahlungDataV1 {
  readonly inhaberId: string;
  readonly zahlungenIds: string[];
}

export interface CreatedBelegDataV1 {
  readonly inhaberId: string;
}

export interface UpdatedBelegDataV1 {
  readonly inhaberId: string;
}

export interface DeletedBelegDataV1 {
  readonly inhaberId: string;
}

@Injectable({
  providedIn: 'root'
})
export class IntentActionService {

  /**
   * Intent-Id für den Aufruf der Editor-Ansicht für einen Beleg
   *
   * JSON Schema: /assets/intents/de.just-farming.belege-online_edit_open_v1.schema.json
   *
   * @private
   */
  private readonly editOpenIdV1 = 'de.just-farming:belege-online:edit.open';

  /**
   * Intent-Id für den Aufruf der Editor-Ansicht für eine Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_popup_edit_v1.schema.json
   *
   * @private
   */
  private readonly editZahlungIdV1 = 'de.just-farming:zahlungen:popup.edit';

  /**
   * Intent-Id für den Aufruf der Editor-Ansicht für eine Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_edit_open_v1.schema.json
   *
   * @private
   */
  private readonly deleteZahlungIdV1 = 'de.just-farming:zahlungen:popup.delete';

  /**
   * Intent-Id für den Aufruf der Editor-Ansicht zum Erstellen einer neuen Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_new_v1.schema.json
   *
   * @private
   */
  private readonly newZahlungIdV1 = 'de.just-farming:zahlungen:new';

  /**
   * Intent-Id für den Aufruf der Overview-Ansicht
   *
   * JSON Schema: /assets/intents/de.justfarming.belege-online_overview_open_v1.schema.json
   *
   * @private
   */
  private readonly overviewOpenIdV1 = 'de.just-farming:belege-online:overview.open';

  /**
   * Intent-Id für den Broadcast zur Information über eine aktualisierte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.updated_v1.schema.json
   *
   * @private
   */
  private readonly updatedZahlungIdV1 = 'de.just-farming:zahlungen:payment.updated';

  /**
   * Intent-Id für den Broadcast zur Information über eine erstellte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.created_v1.schema.json
   *
   * @private
   */
  private readonly createdZahlungIdV1 = 'de.just-farming:zahlungen:payment.created';

  /**
   * Intent-Id für den Broadcast zur Information über eine gelöschte Zahlung
   *
   * JSON Schema: /assets/intents/de.just-farming.zahlungen_payment.deleted_v1.schema.json
   *
   * @private
   */
  private readonly deletedZahlungIdV1 = 'de.just-farming:zahlungen:payment.deleted';

  /**
   * Intent-Id für den Broadcast zur Information über einen erstellten Beleg
   *
   * JSON Schema: src/assets/intents/de.just-farming.belege-online_beleg.created_v1.schema.json
   *
   * @private
   */
  private readonly belegCreatedIdV1 = 'de.just-farming:belege-online:beleg.created';

  /**
   * Intent-Id für den Broadcast zur Information über einen aktualisierten Beleg
   *
   * JSON Schema: src/assets/intents/de.just-farming.belege-online_beleg.updated_v1.schema.json
   *
   * @private
   */
  private readonly belegUpdatedIdV1 = 'de.just-farming:belege-online:beleg.updated';

  /**
   * Intent-Id für den Broadcast zur Information über einen gelöschten Beleg
   *
   * JSON Schema: src/assets/intents/de.just-farming.belege-online_beleg.deleted_v1.schema.json
   *
   * @private
   */
  private readonly belegDeletedIdV1 = 'de.just-farming:belege-online:beleg.deleted';

  constructor(
    private logger: NGXLogger,
    private overviewService: OverviewService,
    private utilityWidgetService: UtilityWidgetService,
    private portalMessageBroker: PortalMessageBroker,
    private store: Store<AppState>,
  ) {
    if (this.isRunningInPortal()) {
      // created beleg
      this.portalMessageBroker.registerIntentCallback(this.belegCreatedIdV1, '1', (data => {
        this.handleBelegCreatedV1(data);
      }));

      // updated beleg
      this.portalMessageBroker.registerIntentCallback(this.belegUpdatedIdV1, '1', (data => {
        this.handleBelegUpdatedV1(data);
      }));

      // deleted beleg
      this.portalMessageBroker.registerIntentCallback(this.belegDeletedIdV1, '1', (data => {
        this.handleBelegDeletedV1(data);
      }));

      // open edit
      this.portalMessageBroker.registerIntentCallback(this.editOpenIdV1, '1', (data => {
        this.handleEditOpenV1(data);
      }));

      // open overview
      this.portalMessageBroker.registerIntentCallback(this.overviewOpenIdV1, '1', (data => {
        this.handleOverviewOpenV1(data);
      }));

      // updated payment
      this.portalMessageBroker.registerIntentCallback(this.updatedZahlungIdV1, '1', (data => {
        this.handlePaymentUpdatedV1(data);
      }));

      // created payment
      this.portalMessageBroker.registerIntentCallback(this.createdZahlungIdV1, '1', (data => {
        this.handlePaymentCreatedV1(data);
      }));

      // deleted payment
      this.portalMessageBroker.registerIntentCallback(this.deletedZahlungIdV1, '1', (data => {
        this.handlePaymentDeletedV1(data);
      }));

      this.portalMessageBroker.allIntentCallbackRegistered();
    }
  }

  /**
   * Prüft ob die App im Portal läuft
   */
  public isRunningInPortal(): boolean {
    return this.portalMessageBroker.isRunningInPortal();
  }

  /**
   * Öffnet über einen Intent die Editor-Ansicht zu einem Beleg
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doEditOpenV1(
    data: EditOpenDataV1,
  ): void {
    this.doEmit(this.editOpenIdV1, '1', data);
  }

  handleEditOpenV1(
    data: EditOpenDataV1
  ): void {
    const belege: BelegIdentity[] = [{
      inhaberId: data.inhaberId,
      id: data.belegId,
    }];

    const editPageData: EditPageData = {
      inhaberId: data.inhaberId,
      index: 0,
      belege,
    };

    this.overviewService.openEditBeleg(editPageData);
  }

  /**
   * Öffnet über einen Intent die Editor-Ansicht zu einer Zahlung
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doEditZahlungV1(
    data: EditZahlungDataV1,
  ): void {
    this.doEmit(this.editZahlungIdV1, '1', data);
  }


  /**
   * Öffnet über einen Intent das Delete-Dialog zu einer Zahlung
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doDeleteZahlungV1(
    data: DeleteZahlungDataV1,
  ): void {
    this.doEmit(this.deleteZahlungIdV1, '1', data);
  }


  /**
   * Öffnet über einen Intent die Seite zum Erstellen einer neuen Zahlung
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doNewZahlungV1(
    data: NewZahlungDataV1,
  ): void {
    this.doEmit(this.newZahlungIdV1, '1', data);
  }


  /**
   * Öffnet über einen Intent die Overview-Ansicht
   *
   * Befindet sich die Anwendung nicht im Portal, wird ein Fehler geworfen.
   * Es sollte vorher geprüft werden, ob die Anwendung im Portal ausgeführt wird {@link isRunningInPortal}.
   *
   * @param data Benötigte Daten für den Aufruf
   */
  public doOverviewOpenV1(
    data: OverviewOpenDataV1,
  ): void {
    this.doEmit(this.overviewOpenIdV1, '1', data);
  }

  public doCreatedBelegV1(
    data: CreatedBelegDataV1
  ): void {
    this.doEmit(this.belegCreatedIdV1, '1', data);
  }

  public doUpdatedBelegV1(
    data: UpdatedBelegDataV1
  ): void {
    this.doEmit(this.belegUpdatedIdV1, '1', data);
  }

  public doDeletedBelegV1(
    data: DeletedBelegDataV1
  ): void {
    this.doEmit(this.belegDeletedIdV1, '1', data);
  }

  handleBelegCreatedV1(
    data: CreatedBelegDataV1
  ): void {
    this.utilityWidgetService.readUtilityWidgetData(data.inhaberId);
  }

  handleBelegUpdatedV1(
    data: UpdatedBelegDataV1
  ): void {
    this.utilityWidgetService.readUtilityWidgetData(data.inhaberId);
  }

  handleBelegDeletedV1(
    data: DeletedBelegDataV1
  ): void {
    this.utilityWidgetService.readUtilityWidgetData(data.inhaberId);
  }

  handleOverviewOpenV1(
    data: OverviewOpenDataV1
  ): void {

    this.overviewService.openOverview(
      data.inhaberId,
      data.filter,
      data.filter?.searchText,
    );
  }

  handlePaymentCreatedV1(
    data: CreatedZahlungDataV1
  ): void {
    this.logger.info('belege online received zahlung created intent', data);

    for (const zahlungId of data.zahlungenIds) {
      this.store.dispatch(ZahlungEntitiesActions.getZahlung({
        inhaberId: data.inhaberId,
        zahlungId,
      }));
    }
  }

  handlePaymentUpdatedV1(
    data: UpdatedZahlungDataV1
  ): void {
    this.logger.info('belege online received zahlung updated intent', data);

    for (const zahlungId of data.zahlungenIds) {
      this.store.dispatch(ZahlungEntitiesActions.getZahlung({
        inhaberId: data.inhaberId,
        zahlungId,
      }));
    }
  }

  handlePaymentDeletedV1(
    data: DeletedZahlungDataV1
  ): void {
    this.logger.info('belege online received zahlung deleted intent', data);

    for (const zahlungId of data.zahlungenIds) {
      this.store.dispatch(ZahlungEntitiesActions.deleteZahlungSuccess({
        zahlungId: zahlungId,
      }));
    }
  }

  private doEmit(
    intentId: string,
    intentVersion: string,
    data: any,
  ): void {
    if (!this.isRunningInPortal()) {
      throw new Error('app is not running in portal');
    }

    const promise = this.portalMessageBroker.emitIntent(intentId, intentVersion, data);
    from(promise)
      .subscribe(
        value => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' handles successful', value);
        },
        error => {
          this.logger.debug('IntentActionService.doEmit(): id=' + intentId + ' version=' + intentVersion + ' dispatch failed', error);
        },
      );
  }
}
