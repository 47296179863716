import {Injectable} from '@angular/core';
import {Actions} from '@ngrx/effects';


@Injectable()
export class EditEffects {

  constructor(
    private actions$: Actions,
  ) {
  }

}
