import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Store} from '@ngrx/store';
import {AppState} from '../store/states/app.state';
import {NGXLogger} from 'ngx-logger';
import {BelegEntitiesSelectors} from '../store/selectors/beleg-entities.selectors';
import {BelegEntitiesActions} from '../store/actions/beleg-entities.actions';
import {combineLatest} from 'rxjs';
import {NavigationService} from '@adnova/jf-ng-components';


@Injectable()
export class VorgangsartResolver  {

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
    private navigationService: NavigationService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return combineLatest([
      this.navigationService.inhaberList$,
      this.store.select(BelegEntitiesSelectors.allVorgangsartDtos),
    ]).subscribe(([inhaberDtos, vorgangsartDtos]) => {
      if (!inhaberDtos.length) return;

      if (vorgangsartDtos?.length === 0) {

        this.logger.debug('trigger readVorgangsarten from vorgangsart-resolver');

        for (const inhaberDto of inhaberDtos) {
          this.store.dispatch(BelegEntitiesActions.readVorgangsarten({
            inhaberId: inhaberDto.id,
          }));
        }
      }
    });
  }
}
