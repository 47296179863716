import {createReducer, on} from '@ngrx/store';
import {MoveBelegDialogState} from '../states/move-beleg-dialog.state';
import {MoveBelegDialogActions} from '../actions/move-beleg-dialog.actions';


export const initialMoveBelegeDialogState: MoveBelegDialogState = {
};

export const moveBelegDialogReducer = createReducer(
  initialMoveBelegeDialogState,

  on(
    MoveBelegDialogActions.open,
    (state, {sourceInhaberId, belegIds}) => ({
      ...state,
      sourceInhaberId,
      belegIds,
    })
  ),

  on(
    MoveBelegDialogActions.close,
    (state) => ({
      ...state,
      sourceInhaberId: undefined,
      belegIds: undefined,
    })
  ),

);
