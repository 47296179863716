import {Injectable, NgZone, OnDestroy} from '@angular/core';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {NGXLogger} from 'ngx-logger';
import {EMPTY, Observable, of, Subscription} from 'rxjs';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {PortalMessageBroker} from './portal-message-broker';


@Injectable({
  providedIn: 'root'
})
export class AdnovaTokenService implements OnDestroy {

  private token?: string;

  private tokenUpdate?: Subscription;

  constructor(
    private logger: NGXLogger,
    private zone: NgZone,
    private portalMessageBroker: PortalMessageBroker,
  ) {
  }

  private startPeriodicObservale(
    repeatAfterSeconds: number,
  ): Observable<any> {

    const millisecondsDelayBetweenTokenCheck = repeatAfterSeconds * 1000;
    return new Observable((subscriber) => {
      let intervalId: any;
      this.zone.runOutsideAngular(() => {
        intervalId = setInterval(() => this.zone.run(() => subscriber.next()), millisecondsDelayBetweenTokenCheck);
      });
      return () => {
        clearInterval(intervalId);
      };
    });
  }

  private hasAdnovaJwtExpired(
    offsetSeconds?: number,
  ): boolean {
    if (!this.token || this.token.length < 1) {
      return true;
    }

    const decodedToken = jwtDecode<JwtPayload>(this.token);
    return !this.validateTokenNotExpired(decodedToken, offsetSeconds);
  }

  private validateTokenNotExpired(
    decodedToken: JwtPayload,
    offsetSeconds?: number,
  ): boolean {
    offsetSeconds = offsetSeconds || 0;

    // INFO: see TokenHelperService
    // https://github.com/damienbod/angular-auth-oidc-client/blob/version-13/projects/angular-auth-oidc-client/src/lib/utils/tokenHelper/token-helper.service.ts
    const exp = decodedToken.exp;
    if (!exp) {
      return false;
    }
    const tokenExpirationDate = new Date(0); // The 0 here is the key, which sets the date to the epoch
    tokenExpirationDate.setUTCSeconds(exp);

    const tokenExpirationValue = tokenExpirationDate.valueOf();
    const nowWithOffset = new Date(new Date().toUTCString()).valueOf() + offsetSeconds * 1000;
    const tokenNotExpired = tokenExpirationValue > nowWithOffset;

    this.logger.trace(`AdnovaTokenService.validateTokenNotExpired(): has adnova token expired: ${!tokenNotExpired}, ${tokenExpirationValue} > ${nowWithOffset}`);
    return tokenNotExpired;
  }

  private updateTokenInternal(
    token: string,
  ): void {
    this.token = token;
    this.logger.debug('AdnovaTokenService.updateTokenInternal(): adnova token updated', this.token);
  }

  public startTokenRefresh(
    repeatAfterSeconds: number,
    renewTimeBeforeTokenExpiresInSeconds: number,
  ): Promise<any> {
    if (!renewTimeBeforeTokenExpiresInSeconds) {
      renewTimeBeforeTokenExpiresInSeconds = 30;
    }
    if (!this.tokenUpdate) {
      this.tokenUpdate = this.startPeriodicObservale(repeatAfterSeconds)
        .pipe(
          switchMap(() => {
            if (this.hasAdnovaJwtExpired(renewTimeBeforeTokenExpiresInSeconds)) {
              return this.portalMessageBroker.requestAdnovaToken()
                .pipe(
                  catchError(err => {
                    this.logger.error('AdnovaTokenService.startTokenRefresh(): adnova token request failed: ', err);
                    return EMPTY;
                  }),
                );
            }
            return of('');
          }),
          filter(value => {
            if (value && value.length > 0) {
              return true;
            }
            return false;
          }),
          catchError(err => {
            this.logger.error('AdnovaTokenService.startTokenRefresh(): periodical adnova token refresh failed: ', err);
            return EMPTY;
          })
        )
        .subscribe(
          value => this.updateTokenInternal(value),
          error => this.logger.error('AdnovaTokenService.startTokenRefresh(): could not refresh adnova token', error)
        );

      this.logger.debug('AdnovaTokenService.startTokenRefresh(): adnova token refresh started');
      return this.portalMessageBroker.requestAdnovaToken()
        .pipe(
          tap(token => {
            this.updateTokenInternal(token);
            this.logger.debug('AdnovaTokenService.startTokenRefresh(): initial adnova token arrived');
          })
        ).toPromise();
    }
    this.logger.debug('AdnovaTokenService.startTokenRefresh(): adnova token refresh already started, ignoring');
    return of(this.getToken())
      .toPromise();
  }

  public stopTokenRefresh(): void {
    if (this.tokenUpdate) {
      this.tokenUpdate.unsubscribe();
      this.tokenUpdate = undefined;
    }
    this.token = undefined;
  }

  public getToken(): string {
    if (!this.tokenUpdate) {
      throw new Error('token refresh is not running');
    }
    if (!this.token) {
      throw new Error('token not present');
    }
    return this.token;
  }

  ngOnDestroy(): void {
    this.stopTokenRefresh();
  }
}
