import {zahlungEntitiesAdapter} from '../adapters/zahlung-entities.adapter';
import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {ZahlungDTO} from '../../openapi/zahlung-openapi';


export class ZahlungEntitiesSelectors {

  private static adapterSelectors = zahlungEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    zahlungEntities: (state: AppState) => state.zahlungEntities,
  };

  public static all = createSelector(
    this.select.zahlungEntities,
    this.adapterSelectors.selectAll,
  );

  /**
   * Ermittelt die jüngste Zahlung zu jedem Beleg auf der Liste.
   *
   * @param dublettenIds Belege, zu welchen Zahlungen ermittelt werden müssen.
   */
  public static zahlungDublettenData = (dublettenIds: string[]) => createSelector(
    this.all,
    zahlungDtos => {
      const selectedZahlungDtos: ZahlungDTO[] = [];

      for (const id of dublettenIds) {
        const filteredZahlungDto: ZahlungDTO | undefined = zahlungDtos.filter(zahlungDto => {
          return zahlungDto.belegId === id;
        }).sort((a, b) => {
          return new Date(b.erstellt).getTime() - new Date(a.erstellt).getTime();
        })[0];

        if (filteredZahlungDto) {
          selectedZahlungDtos.push(filteredZahlungDto);
        }
      }

      return selectedZahlungDtos;
    },
  );

  public static zahlungenByBelegId = (belegId: string) => createSelector(
    this.all,
    zahlungDtos => zahlungDtos.filter(zahlungDto => {
      return zahlungDto.belegId === belegId;
    })
  );

}
