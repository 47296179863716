import {Component, EventEmitter, Input, Output} from '@angular/core';


@Component({
  selector: 'bo-dublette-item',
  templateUrl: './dublette-item.component.html',
  styleUrls: ['./dublette-item.component.scss']
})
export class DubletteItemComponent {

  @Input()
  id: string | undefined;

  @Input()
  icon: string | undefined;

  @Input()
  empfaenger: string | undefined;

  @Input()
  belegStatus: string | undefined;

  @Input()
  isFestgeschrieben: boolean | undefined;

  @Input()
  erstellt: string | undefined;

  @Input()
  zahlungStatus: string | undefined;

  @Input()
  isOriginal = false;

  @Output()
  openPreview = new EventEmitter<string>();

  @Output()
  deleteOrReverse = new EventEmitter<string>();

  constructor() {
  }
}

