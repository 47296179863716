import {Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {MatLegacySelect as MatSelect, MatLegacySelectChange as MatSelectChange} from '@angular/material/legacy-select';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {StatusData} from '../../../../../interfaces/status-data.interface';
import {StatusFilter} from '../../../../../interfaces/status-filter.interface';


@Component({
  selector: 'bo-filter-status',
  templateUrl: './filter-status.component.html',
  styleUrls: ['./filter-status.component.scss']
})
export class FilterStatusComponent implements OnInit, OnChanges {

  statusList: StatusData[] = [
    {
      text: 'Offen',
      icon: 'common:status_open',
      filter: {
        offen: true,
        bearbeitet: false,
        festgeschrieben: false,
        storniert: false,
      },
    },
    {
      text: 'Bearbeitet',
      icon: 'common:status_edit',
      filter: {
        offen: false,
        bearbeitet: true,
        festgeschrieben: false,
        storniert: false,
      },
    },
    {
      text: 'Fertiggestellt',
      icon: 'common:status_success',
      filter: {
        offen: false,
        bearbeitet: false,
        festgeschrieben: true,
        storniert: false,
      },
    },
    {
      text: 'Storniert',
      icon: 'common:status_canceled',
      filter: {
        offen: false,
        bearbeitet: false,
        festgeschrieben: false,
        storniert: true,
      },
    },
  ];

  @ViewChild(MatSelect)
  public select?: MatSelect;

  @Input()
  public value: StatusFilter = {
    offen: false,
    bearbeitet: false,
    festgeschrieben: false,
    storniert: false,
  };

  @Output()
  public valueChanged = new EventEmitter<StatusFilter>();

  protected statusFilter = new Subject<MatSelectChange>();

  protected closeOptionsTimeout!: any;

  constructor() {
  }

  ngOnInit() {
    this.statusFilter.pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ).subscribe(() => {

      if (!this.select) return;

      const belegFilter: StatusFilter = {
        offen: false,
        bearbeitet: false,
        storniert: false,
        festgeschrieben: false,
      };

      for (const status of this.select.value) {
        const filter = status.filter;
        if (filter.offen) {
          belegFilter.offen = true;
        }
        if (filter.bearbeitet) {
          belegFilter.bearbeitet = true;
        }
        if (filter.festgeschrieben) {
          belegFilter.festgeschrieben = true;
        }
        if (filter.storniert) {
          belegFilter.storniert = true;
        }
      }
      this.valueChanged.emit(belegFilter);
    });
  }

  ngOnChanges(): void {
    if (this.select) {
      const value = this.statusList.filter(val => {
        if (this.value.offen && val.filter.offen) {
          return true;
        }
        if (this.value.bearbeitet && val.filter.bearbeitet) {
          return true;
        }
        if (this.value.festgeschrieben && val.filter.festgeschrieben) {
          return true;
        }
        if (this.value.storniert && val.filter.storniert) {
          return true;
        }
        return false;
      });

      this.select.value = value;
    }
  }

  closeOptions(): void {
    this.closeOptionsTimeout = setTimeout(() => {
      this.select?.close();
    }, 500);
  }

  cancelCloseOptions(): void {
    clearTimeout(this.closeOptionsTimeout);
  }
}
