import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {BelegbucheinAusgangDTO} from '../../../../../openapi/beleg-openapi';
import {FormControl} from '@angular/forms';
import {debounceTime, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatLegacySelect as MatSelect} from '@angular/material/legacy-select';


interface SelectOption {
  value: BelegbucheinAusgangDTO;
  display: string;
};

@Component({
  selector: 'bo-filter-eingang-ausgang',
  templateUrl: './filter-eingang-ausgang.component.html',
  styleUrls: ['./filter-eingang-ausgang.component.scss']
})
export class FilterEingangAusgangComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  @ViewChild(MatSelect)
  public select?: MatSelect;

  @Input()
  set value(eingangAusgang: BelegbucheinAusgangDTO[]) {
    /*
     * INFO: Die Option `emitEvent: false` verhindert, dass beim programmatischen Setzen des Wertes einer FormControl
     * Ereignisse ausgelöst werden, um Endlosschleifen im Kontext von Two-Way-Binding zu vermeiden.
     * Sie ermöglicht es, Werte ohne Seiteneffekte zu aktualisieren und stellt sicher, dass Änderungen von außen nicht das
     * `valueChanges`-Event der Form Control auslösen.
     */
    this.control.setValue(
      eingangAusgang,
      {emitEvent: false}
    );
  }

  @Output()
  valueChange = new EventEmitter<BelegbucheinAusgangDTO[]>();

  protected options: SelectOption[] = [
    {
      value: BelegbucheinAusgangDTO.Belegeingang,
      display: 'Eingang',
    },
    {
      value: BelegbucheinAusgangDTO.Belegausgang,
      display: 'Ausgang',
    },
  ];

  protected control = new FormControl();

  protected closeOptionsTimeout: any;

  ngOnInit() {
    this.control.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(250),
    ).subscribe(values => {
      this.valueChange.emit(values);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  closeOptions(): void {
    this.closeOptionsTimeout = setTimeout(() => {
      this.select?.close();
    }, 500);
  }

  cancelCloseOptions(): void {
    clearTimeout(this.closeOptionsTimeout);
  }
}
