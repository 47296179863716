import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription, timer} from 'rxjs';
import {BelegAktionDTO, BelegDTO} from '../../../openapi/beleg-openapi';
import {BelegAktionenEntitiesActions} from '../../../store/actions/beleg-aktionen-entities.actions';
import {Store} from '@ngrx/store';
import {AppState} from '../../../store/states/app.state';
import {BelegAktionenEntitiesSelectors} from '../../../store/selectors/beleg-aktionen-entities.selectors';
import {takeUntil} from 'rxjs/operators';


@Component({
  selector: 'bo-aktionen',
  templateUrl: './aktionen.component.html',
  styleUrls: ['./aktionen.component.scss'],
})
export class AktionenComponent implements OnInit, OnDestroy {

  @Input({required: true})
  beleg!: BelegDTO;

  aktionenOpen$: Subject<boolean> = new Subject<boolean>();

  aktionen: BelegAktionDTO[] = [];

  aktionenFinished: boolean = false;

  // info: Dieser Status ließe sich auch in den Store verlagern, allerdings könnte es hier noch Änderungen geben.
  loadingAktionen: boolean = false;

  private overlaySubscription: Subscription | null = null;

  private unsubscribe$ = new Subject<void>();

  constructor(
    public store: Store<AppState>,
  ) {
  }

  ngOnInit(): void {
    this.updateAktionenFinished();

    this.store.select(BelegAktionenEntitiesSelectors.belegAktionenByBelegId(this.beleg.id))
      .pipe(
        takeUntil(this.unsubscribe$),
      )
      .subscribe(belegAktionen => {
        this.aktionen = belegAktionen?.belegAktionDtos ?? [];
        this.loadingAktionen = false;
        this.updateAktionenFinished();
        this.aktionenOpen$.next(true);
      });
  }

  onAktionenOver(): void {
    this.unsubscribe();
    this.loadingAktionen = true;
    this.overlaySubscription = timer(400)
      .subscribe(() => {
        this.store.dispatch(BelegAktionenEntitiesActions.readBelegAktionen({
          inhaberId: this.beleg.inhaberId,
          belegId: this.beleg.id,
        }))
      });
  }

  onAktionenOut(): void {
    this.unsubscribe();
    this.loadingAktionen = false;
    this.aktionenOpen$.next(false);
    this.aktionen = [];
  }

  createAktionInfo(aktion: BelegAktionDTO): string {
    const info = aktion.statusMessage ?? '';

    // TODO: remove obsolete datevincometax check after AD+ 5.30.0 release
    if (aktion.type === 'datev-incometax' || aktion.type === 'datevincometax') {
      return `DATEV Meine Steuern: ${info}`;
    }

    return info;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateAktionenFinished(): void {
    if (this.aktionen.length > 0) {
      // most up-to-date information
      this.aktionenFinished = this.aktionen.every(aktion => aktion.finished);
    } else {
      // state since last belege-list data refresh
      this.aktionenFinished = !this.beleg.aktionen?.existsUnfinished;
    }
  }

  private unsubscribe(): void {
    if (this.overlaySubscription) {
      this.overlaySubscription.unsubscribe();
      this.overlaySubscription = null;
    }
  }
}
