import {createAction, props} from '@ngrx/store';
import {ReuseBelegData} from '../../interfaces/reuse-beleg-data.interface';


export class ReuseBelegDialogActions {

  public static openReuseBelegDialog = createAction(
    '[Overview- & Edit-Page] Open reuse-beleg-dialog.',
    props<{
      data: ReuseBelegData,
    }>(),
  );

  public static closeReuseBelegDialog = createAction(
    '[Reuse-Beleg-Dialog] Close reuse-beleg-dialog.',
  );

}
