<div class="dublette-wrapper {{isOriginal ? 'is-original' : 'is-dublette'}}">
  <div class="icon-wrapper">
    <bo-icon icon="{{icon}}" primaryStroke="black"></bo-icon>
  </div>
  <div class="content-wrapper">
    <div class="dublette-title">{{empfaenger}}</div>
    <div class="dublette-data">Status: {{belegStatus}} &bull; Hochgeladen am {{erstellt | date}}
      <span *ngIf="zahlungStatus">&bull; Zahlung {{zahlungStatus}}</span>
    </div>
  </div>
  <div class="btn-wrapper">
    <button
      mat-flat-button
      [matTooltip]="'Vorschau'"
      matTooltipTouchGestures="on"
      matTooltipPosition="above"
      (click)="openPreview.emit(id)"
    >
      <bo-icon icon="actions:fullscreen" primaryStroke="black"></bo-icon>
    </button>
    <button
      mat-flat-button
      [matTooltip]="!isFestgeschrieben ? 'Löschen' : 'Stornieren'"
      matTooltipTouchGestures="on"
      matTooltipPosition="above"
      (click)="deleteOrReverse.emit(id)"
    >
      <bo-icon icon="actions:delete" primaryStroke="black"></bo-icon>
    </button>
  </div>
</div>
