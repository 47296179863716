import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgxCurrencyInputMode} from 'ngx-currency';
import {FormControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {debounceTime, Subject} from 'rxjs';


@Component({
  selector: 'bo-filter-betrag-von-bis',
  templateUrl: './filter-betrag-von-bis.component.html',
  styleUrls: ['./filter-betrag-von-bis.component.scss']
})
export class FilterBetragVonBisComponent implements OnInit {
  @Input()
  public set valueVon(von: number | undefined) {
    this.controlVon.setValue(
      von,
      {emitEvent: false}
    );
  }

  @Input()
  public set valueBis(von: number | undefined) {
    this.controlBis.setValue(
      von,
      {emitEvent: false}
    );
  }

  @Output()
  public valueVonChange = new EventEmitter<number>();

  @Output()
  public valueBisChange = new EventEmitter<number>();

  public controlVon = new FormControl();

  public controlBis = new FormControl();

  public inputOptions = {
    prefix: '',
    suffix: ' €',
    thousands: '.',
    decimal: ',',
    align: 'left',
    allowNegative: false,
    nullable: true,
    inputMode: NgxCurrencyInputMode.Natural,
    min: 0,
    max: 9999999.99
  };

  private readonly unsubscribe$ = new Subject<void>();

  ngOnInit(): void {
    this.controlVon.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(250),
    ).subscribe(value => {
      this.valueVonChange.emit(value);
    });

    this.controlBis.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(250),
    ).subscribe(value => {
      this.valueBisChange.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
