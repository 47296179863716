<mat-toolbar *ngIf="(runningInPortal$ | async) === false">
  <span class="standalone-toolbar-spacer"></span>
  <button mat-icon-button tabindex="-1" (click)="doLogout()">
    <mat-icon>logout</mat-icon>
  </button>
</mat-toolbar>

<bo-activity-display></bo-activity-display>

<div class="content-outlet" [ngClass]="{'runningInPortal': runningInPortal$ | async}">
  <router-outlet></router-outlet>
</div>
