import {ReuseBelegDialogState} from '../states/reuse-beleg-dialog.state';
import {createReducer, on} from '@ngrx/store';
import {ReuseBelegDialogActions} from '../actions/reuse-beleg-dialog.actions';


export const initialReuseBelegDialogState: ReuseBelegDialogState = {
  isOpen: false,
};

export const reuseBelegDialogReducer = createReducer(
  initialReuseBelegDialogState,

  on(
    ReuseBelegDialogActions.openReuseBelegDialog,
    (state, action) => ({
      ...state,
      isOpen: true,
      reuseBelegData: action.data,
    })
  ),

  on(
    ReuseBelegDialogActions.closeReuseBelegDialog,
    () => ({
      ...initialReuseBelegDialogState,
    })
  ),
);
