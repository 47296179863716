import {createAction, props} from '@ngrx/store';
import {
  BelegbucheinAusgangDTO,
  BelegDTO,
  FilterBelegDTO,
  PageableBelegDTO,
  PageableBelegDTOSortingEnum,
  VorgangsartDTO,
} from '../../openapi/beleg-openapi';
import {TableSettings} from '../../interfaces/table-settings.interface';
import {DateFilterData} from '../../interfaces/date-filter-data.interface';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class OverviewTableActions {

  public static readBelege = createAction(
    '[Overview Table] Read belege.',
    props<{
      inhaberId: string,
      filterDto: FilterBelegDTO,
      pageableDto: PageableBelegDTO,
    }>(),
  );

  public static setDisplayedIds = createAction(
    '[Overview Table] Set beleg ids that should be displayed in table.',
    props<{
      belegDtos: BelegDTO[],
    }>(),
  );

  public static addDisplayedId = createAction(
    '[Overview Table] Added a belegId that should be displayed in table.',
    props<{
      belegDto: BelegDTO,
    }>(),
  );

  public static clearDisplayedBelege = createAction(
    '[Overview Table] Clear beleg ids that should be displayed in table.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static showFilter = createAction(
    '[Overview Table] Show filter panel.'
  );

  public static hideFilter = createAction(
    '[Overview Table] Hide filter panel.'
  );

  public static updateSearchFilter = createAction(
    '[Overview Table] Updates the search value.',
    props<{
      searchValue: string,
    }>(),
  );

  public static updateStatusFilter = createAction(
    '[Overview Table] Updates the Statusfilter value.',
    props<{
      offen: boolean,
      bearbeitet: boolean,
      festgeschrieben: boolean,
      storniert: boolean,
    }>(),
  );

  public static updateEinAusgangFilter = createAction(
    '[Overview Table] Updates the EinAusgang value.',
    props<{
      einAusgang: BelegbucheinAusgangDTO[],
    }>(),
  );

  public static updateCreatedDateFilter = createAction(
    '[Overview Table] Updates the CreatedDate value.',
    props<{
      dateFilter: DateFilterData,
    }>(),
  );

  public static updateBelegDateFilter = createAction(
    '[Overview Table] Updates the BelegDate value.',
    props<{
      dateFilter: DateFilterData,
    }>(),
  );

  public static updateShowDublettenFilter = createAction(
    '[Overview Table] Updates the ShowDubletten value.',
    props<{
      showDubletten: boolean | undefined,
    }>(),
  );

  public static toggleBelege = createAction(
    '[Overview Table] Select single beleg.',
    props<{
      belegIds: string[],
    }>(),
  );

  public static toggleAll = createAction(
    '[Overview Table] Select all belege of current view.',
    props<{
      belegIds: string[],
    }>(),
  );

  public static updatePageIndex = createAction(
    '[Overview Table] Updates the pageIndex.',
    props<{
      pageIndex: number,
    }>(),
  );

  public static resetSingleFilter = createAction(
    '[Overview Table] Resets a single filter by id.',
    props<{
      id: string,
    }>(),
  );

  public static resetAllFilter = createAction(
    '[Betriebsauswahl] Resets all filter.',
  );

  public static updateSortingEnum = createAction(
    '[Overview Table] Updates the sorting enum.',
    props<{
      sorting: PageableBelegDTOSortingEnum[],
    }>(),
  );

  public static changeTableSettings = createAction(
    '[Overview Table] Changed table settings.',
    props<{ tableSettings: TableSettings }>(),
  );

  public static updateBeleg = createAction(
    '[Beleg Entities] Update single beleg.',
    props<{
      inhaberId: string,
      belegId: string,
    }>(),
  );

  public static updateBelegSuccess = createAction(
    '[Beleg Entities] Successfully updated single beleg.',
    props<{
      belegDto: BelegDTO,
    }>(),
  );

  public static setBetragvonFilter = createAction(
    '[Overview Table] Set betragvon filter.',
    props<{
      betragVon: number,
    }>(),
  );

  public static setBetragbisFilter = createAction(
    '[Overview Table] Set betragbis filter.',
    props<{
      betragBis: number,
    }>(),
  );

  public static setBelegtypFilter = createAction(
    '[Overview Table] Set belegtyp filter.',
    props<{
      selectedVorgangsartIds: string[],
      allVorgangsartDtos: VorgangsartDTO[],
    }>(),
  );

}
