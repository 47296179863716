import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ZahlungEntitiesSelectors} from '../../store/selectors/zahlung-entities.selectors';
import {HandleDubletteData} from '../../interfaces/handle-dublette-data.interface';
import {BelegDTO} from '../../openapi/beleg-openapi';
import {NGXLogger} from 'ngx-logger';
import {ZahlungDTO} from '../../openapi/zahlung-openapi';
import {DubletteActions} from '../../store/actions/dublette.actions';
import {DubletteSelectors} from '../../store/selectors/dublette.selectors';
import {AppState} from '../../store/states/app.state';
import {BelegEntitiesActions} from '../../store/actions/beleg-entities.actions';
import {ReuseBelegData} from '../../interfaces/reuse-beleg-data.interface';
import {BelegStipulatedDialogActions} from '../../store/actions/beleg-stipulated-dialog.actions';


@Component({
  selector: 'bo-handle-dublette-dialog',
  templateUrl: './handle-dublette-dialog.component.html',
  styleUrls: ['./handle-dublette-dialog.component.scss']
})
export class HandleDubletteDialogComponent implements OnInit, OnDestroy {

  public dublettenDatas: HandleDubletteData[] = [];

  public originalBelegData: HandleDubletteData | undefined;

  public deleteAllDisabled = false;

  private allDublettenDtos: BelegDTO[] = [];

  private inhaberId = '';

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
  ) {
  }

  ngOnInit() {
    combineLatest([
      this.store.select(DubletteSelectors.inhaberId),
      this.store.select(DubletteSelectors.dublettenIds),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([inhaberId, dublettenIds]) => {
      if (!inhaberId || dublettenIds.length === 0) return;

      this.inhaberId = inhaberId;

      this.store.dispatch(DubletteActions.readDubletten({
        inhaberId,
        filterDto: {
          onlyBelege: dublettenIds,
          offen: true,
          bearbeitet: true,
          festgeschrieben: true,
          storniert: false,
        },
        pageableDto: {},
      }));
    });

    this.store.select(DubletteSelectors.dublettenDtos(this.inhaberId)).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(dublettenDtos => {
      if (!dublettenDtos || dublettenDtos.length === 0) return;

      // INFO: Sofern nur noch ein Beleg vorhanden ist, den Dialog schließen.
      if (dublettenDtos.length === 1) {
        this.doCloseDialog();

        // INFO: Die Dublette-Kennzeichnung des letzten verbleibenden Belegs entfernen.
        this.store.dispatch(BelegEntitiesActions.unmarkOriginalBelegAsDublette({
          belegId: dublettenDtos[0].id,
        }));
      }

      // INFO: Liste aller Dubletten speichern
      this.allDublettenDtos = dublettenDtos;

      // INFO: Nachladen der Zahlungsdaten zu den Dubletten, aus dem Store.
      const dublettenIds = dublettenDtos.map(dubletteDto => dubletteDto.id);
      this.store.select(ZahlungEntitiesSelectors.zahlungDublettenData(dublettenIds)).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(zahlungDtos => {

        // INFO: Die Dubletten-Informationen verarbeiten.
        let handleDubletteDatas: HandleDubletteData[] = this.createHandleDubletteDatas(dublettenDtos, zahlungDtos);
        handleDubletteDatas = this.sortHandleDubletteDatas(handleDubletteDatas);
        this.calcOriginalBelegData(handleDubletteDatas);
        this.calcDublettenData(handleDubletteDatas);
        this.checkDeleteAllDisabled();
      });
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Erstellt eine Liste mit den Dubletten-Informationen.
   *
   * @param dublettenDtos Die Dubletten
   * @param zahlungDtos Die dazugehörigen Zahlungen
   * @private
   */
  private createHandleDubletteDatas(dublettenDtos: BelegDTO[], zahlungDtos: ZahlungDTO[]): HandleDubletteData[] {
    const handleDubletteDatas: HandleDubletteData[] = [];

    for (const dublette of dublettenDtos) {
      // INFO: Für jeden Beleg darf maximal eine Zahlung vorhanden sein.
      const selectedZahlung = zahlungDtos.find(zahlung => {
        return zahlung && zahlung.belegId === dublette.id;
      });

      const handleDubletteData = this.createHandleDubletteData(dublette, selectedZahlung);
      handleDubletteDatas.push(handleDubletteData);
    }

    return handleDubletteDatas;
  }

  /**
   * Erstellt eine einzelne Dubletten-Information.
   *
   * @param dublette Die Dublette
   * @param selectedZahlung Die dazugehörige Zahlung
   * @private
   */
  private createHandleDubletteData(dublette: BelegDTO, selectedZahlung: ZahlungDTO | undefined): HandleDubletteData {
    return {
      id: dublette.id,
      empfaenger: dublette.partner ? dublette.partner?.bezeichnung : 'Keine Angaben vorhanden',
      belegStatus: this.calcDubletteStatus(dublette),
      isFestgeschrieben: dublette.festgeschrieben || false,
      erstellt: dublette.erstellt,
      zahlungStatus: selectedZahlung ? this.calcZahlungStatus(selectedZahlung) : undefined,
    };
  }

  /**
   * Berechnet den Status des Belegs / der Dublette.
   *
   * @param dublette
   * @private
   */
  private calcDubletteStatus(dublette: BelegDTO): string {
    if (dublette.offen) {
      return 'offen';
    } else if (dublette.bearbeitet) {
      return 'Bearbeitet';
    } else if (dublette.festgeschrieben) {
      return 'Fertiggestellt';
    }

    this.logger.warn(
      'Dubletten dürfen nicht für stornierte Belege ermittelt werden. ' +
      'Ist dies nicht der Fall, muss möglicherweise ein neuer Beleg-Status hinzugefügt werden.'
    );

    return '';
  }

  /**
   * Berechnet den Status der Zahlung.
   *
   * @param zahlung Die Zahlung zu dem Beleg.
   * @private
   */
  private calcZahlungStatus(zahlung: ZahlungDTO): string {
    if (zahlung.offen) {
      return 'offen';
    } else if (zahlung.angewiesen) {
      return 'angewiesen';
    }

    this.logger.warn('Möglicherweise muss ein neuer Zahlung-Status hinzugefügt werden.');

    return '';
  }

  /**
   * Sortiert die Dubletten-Informationen nach der jüngsten.
   *
   * @param handleDubletteData
   * @private
   */
  private sortHandleDubletteDatas(handleDubletteData: HandleDubletteData[]): HandleDubletteData[] {
    return handleDubletteData.sort((a, b) => {
      return new Date(b.erstellt).getTime() - new Date(a.erstellt).getTime();
    });
  }

  /**
   * Ermittelt den ältesten und somit originalen Beleg.
   * Da die Liste zu diesem Zeitpunkt bereits sortiert ist, ist der älteste Beleg, der letzte in der Liste.
   *
   * @param handleDubletteDatas
   * @private
   */
  private calcOriginalBelegData(handleDubletteDatas: HandleDubletteData[]): void {
    this.originalBelegData = handleDubletteDatas[handleDubletteDatas.length - 1];
  }

  /**
   * Sortiert den originalen Beleg aus der Liste heraus. So verbleiben ausschließlich die Dubletten.
   *
   * @param handleDubletteDatas
   * @private
   */
  private calcDublettenData(handleDubletteDatas: HandleDubletteData[]): void {
    this.dublettenDatas = handleDubletteDatas.filter(dubletteData => {
      return dubletteData.id !== this.originalBelegData!.id;
    });
  }

  /**
   * Die Funktion "Dubletten löschen" nur erlauben, sofern keine Dublette mit Status Festgeschrieben existiert.
   * Hierbei muss beachtet werden, dass diese Prüfung nicht auf den als "Original" ausgewiesenen Beleg, geschehen darf.
   *
   * @private
   */
  private checkDeleteAllDisabled(): void {
    const hasStatusFestgeschrieben = this.allDublettenDtos.filter(dublettenDto => {
      return dublettenDto.id !== this.originalBelegData!.id;
    }).filter(dublettenDto => {
      return dublettenDto.festgeschrieben;
    });

    this.deleteAllDisabled = hasStatusFestgeschrieben.length > 0;
  }

  /**
   * Öffnet die Beleg-Vorschau.
   *
   * @param id Id der im PDF-Viewer anzuzeigenden Dublette.
   */
  doOpenPreview(dublettenId: string): void {
    this.store.dispatch(BelegEntitiesActions.openBelegPreviewDialog({
      inhaberId: this.inhaberId,
      belegId: dublettenId,
    }));
  }

  /**
   * Triggered das Löschen oder das Stornieren eines Belegs.
   *
   * @param id Die Id des zu löschenden Belegs.
   */
  doDeleteOrReverseDublette(id: string) {
    const dublettenDto = this.getDublettenDto(id);

    if (!dublettenDto.festgeschrieben) {
      this.store.dispatch(DubletteActions.deleteDublette({
        inhaberId: dublettenDto.inhaberId,
        belegId: id,
      }));
    } else if (dublettenDto.festgeschrieben) {
      const data: ReuseBelegData = {
        inhaberId: dublettenDto.inhaberId,
        belegId: id,
        belegAlreadyReversed: false,
        showReuseOption: false,
        isDublette: true,
      };

      this.store.dispatch(BelegStipulatedDialogActions.openBelegStipulatedDialog({data}));
    }
  }

  /**
   * Ermittelt das BelegDto zur Id.
   *
   * @param id Id der Dublette
   * @private
   */
  private getDublettenDto(id: string): BelegDTO {
    return this.allDublettenDtos.find(dublettenDto => dublettenDto.id === id)!;
  }

  /**
   * Triggered das Schließen des handle-dublette-dialog.
   */
  doCloseDialog(): void {
    this.store.dispatch(DubletteActions.closeHandleDubletteDialog());
  }

  /**
   * Triggered das löschen aller Dubletten.
   */
  deleteAll() {
    for (const dublettenData of this.dublettenDatas) {
      const dublettenDto = this.getDublettenDto(dublettenData.id);
      if (!dublettenDto.festgeschrieben) {
        this.store.dispatch(DubletteActions.deleteDublette({
          inhaberId: dublettenDto.inhaberId,
          belegId: dublettenDto.id,
        }));
      } else {
        this.logger.warn(
          'deletion cannot be performed on duplicates with the status stipulated. dubletteId:',
          dublettenDto.id,
        );
      }
    }
  }
}
