<h2 mat-dialog-title>Beleg wiederverwenden</h2>
<mat-dialog-content>
  Du kannst diesen Beleg<br>
  wiederverwenden, um bspw. deine<br>
  Angaben zum Beleg zu korrigieren.
  <br><br>
  Der stornierte Beleg bleibt in deiner<br>
  Buchführung sichtbar.
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    (click)="doReuseBeleg()"
  >Beleg wiederverwenden
  </button>
  <button mat-flat-button class="fw-bold" (click)="closeDialog()">Abbrechen</button>
</mat-dialog-actions>
