<mat-form-field appearance="outline">
  <mat-label>Status</mat-label>
  <mat-select (selectionChange)="this.statusFilter.next($event)" multiple>
    <div
      (mouseenter)="cancelCloseOptions()"
      (mouseleave)="closeOptions()"
    >
      <mat-option
        *ngFor="let status of statusList"
        [value]="status"
      >
        <bo-icon class="status-icon" [icon]="status.icon" primaryStroke="black" primaryFill="black"></bo-icon>
        {{status.text}}
      </mat-option>
    </div>
  </mat-select>
</mat-form-field>
