import {createReducer, on} from '@ngrx/store';
import {belegAktionenEntitiesAdapter} from '../adapters/beleg-aktionen-entities.adapter';
import {BelegAktionenEntitiesState} from '../states/beleg-aktionen-entities.state';
import {BelegAktionenEntitiesActions} from '../actions/beleg-aktionen-entities.actions';


export const initialBelegAktionenEntitiesState: BelegAktionenEntitiesState = belegAktionenEntitiesAdapter.getInitialState();

export const belegAktionenEntitiesReducer = createReducer(
  initialBelegAktionenEntitiesState,

  on(
    BelegAktionenEntitiesActions.readBelegAktionenSuccess,
    (state, {belegAktionen}) => {
      return belegAktionenEntitiesAdapter.upsertOne(belegAktionen, state);
    },
  ),
);
