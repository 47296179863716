<div
  *ngIf="value"
  class="activity-wrapper"
>
  <div class="icon-wrapper">
    <bo-icon icon="common:beleg" primaryStroke="black"></bo-icon>
  </div>

  <div class="activity-data">
    <div class="title">
      {{value.title}}
    </div>
    <div class="description">
      {{value.description ? value.description : ''}}
    </div>
  </div>

  <div class="progress-indicator">
    <mat-progress-spinner
      [hidden]="value.done"
      [diameter]="20"
      [mode]="value.mode"
      [value]="value.progress"
      [color]="'accent'"
    ></mat-progress-spinner>

    <bo-icon
      [hidden]="!value.success"
      icon="common:tick"
      primaryStroke="black"></bo-icon>

    <bo-icon
      [hidden]="!value.failed"
      icon="common:warn"
      primaryStroke="#FF4747"></bo-icon>
  </div>
</div>
