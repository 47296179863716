<div class="activity-display"
     [hidden]="neverShowActivityDisplay || (!showActivityDisplay && !neverShowActivityDisplay)"
>
  <mat-accordion>
    <mat-expansion-panel
      hideToggle
      (opened)="changeExpansionpanelState(true)"
      (closed)="changeExpansionpanelState(false)"
    >
      <!--   INFO: Expansion-Panel Hader   -->
      <mat-expansion-panel-header
        class="{{isExpansionpanelOpen ? 'is-open' : 'is-closed'}}"
      >
        <!--    INFO: Je nach dem ob es laufende Aktivitäten gibt, entscheiden welcher Titel anzuzeigen ist    -->
        <div
          *ngIf="activitiesOngoingCount === 0"
          [ngPlural]="activities.length"
        >
          <ng-template ngPluralCase="one">1 abgeschlossene Aktivität</ng-template>
          <ng-template ngPluralCase="other">{{activities.length}} abgeschlossene Aktivitäten</ng-template>
        </div>

        <div
          *ngIf="activitiesOngoingCount > 0"
          [ngPlural]="activitiesOngoingCount"
        >
          <ng-template ngPluralCase="one">1 laufende Aktivität</ng-template>
          <ng-template ngPluralCase="other">{{activitiesOngoingCount}} laufende Aktivitäten</ng-template>
        </div>

        <!--    INFO: Aktionen des Aktivitäten-Panels    -->
        <div class="header-actions">
          <button
            mat-icon-button
            class="expand-btn"
          >
            <bo-icon
              *ngIf="activities.length === 0"
              icon="common:arrow_up"
              [primaryStroke]="'#595959'"
            ></bo-icon>

            <bo-icon
              *ngIf="activities.length > 0"
              icon="common:arrow_up"
              [primaryStroke]="'#CCCCCC'"
              class="expand-icon {{isExpansionpanelOpen ? 'is-open' : 'is-closed'}}"
            ></bo-icon>
          </button>

          <button
            mat-icon-button
            [disabled]="activitiesOngoingCount > 0"
            (click)="hideActivityDisplay($event)"
          >
            <bo-icon
              *ngIf="activitiesOngoingCount > 0"
              icon="actions:close"
              [primaryStroke]="'#595959'"
            ></bo-icon>
            <bo-icon
              *ngIf="activitiesOngoingCount === 0"
              icon="actions:close"
              [primaryStroke]="'#CCCCCC'"
            ></bo-icon>
          </button>
        </div>
      </mat-expansion-panel-header>

      <!--   INFO: Expansion-Panel Body / Aktivitäten-Liste   -->
      <div class="activities">
        <bo-activity
          *ngFor="let activity of activities"
          [value]="activity"
        ></bo-activity>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
