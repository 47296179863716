import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './auth/auth.guard';
import {VorgangsartResolver} from './resolver/vorgangsart.resolver';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/overview',
    pathMatch: 'full'
  },
  {
    path: 'edit',
    loadChildren: () => import('./modules/edit/edit.module').then(m => m.EditModule),
    canActivate: [AuthGuard],
    resolve: [
      VorgangsartResolver,
    ],
  },
  {
    path: 'splice',
    loadChildren: () => import('./modules/splice/splice.module').then(m => m.SpliceModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'overview',
    loadChildren: () => import('./modules/overview/overview.module').then(m => m.OverviewModule),
    canActivate: [AuthGuard],
    resolve: [
      VorgangsartResolver,
    ],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'legal',
    loadChildren: () => import('./modules/legal/legal.module').then(m => m.LegalModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'widget/to-be-edited',
    loadChildren: () => import('./modules/widget/to-be-edited/to-be-edited.module').then(m => m.ToBeEditedModule),
    canActivate: [AuthGuard],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'widget/to-be-edited-list',
    loadChildren: () => import('./modules/widget/to-be-edited-list/to-be-edited-list.module').then(m => m.ToBeEditedListModule),
    canActivate: [AuthGuard],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'widget/utility-widget',
    loadChildren: () => import('./modules/widget/utility-widget/utility-widget.module').then(m => m.UtilityWidgetModule),
    canActivate: [AuthGuard],
    data: {
      redirectAllowed: true,
      useInhaberService: true,
    },
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: '/overview'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
      scrollPositionRestoration: 'top',
      preloadingStrategy: PreloadAllModules
    }
  )],
  providers: [
    VorgangsartResolver,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
