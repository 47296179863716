import {createAction, props} from '@ngrx/store';
import {BelegDTO, FilterBelegDTO, HeftenResponseDTO, PageableBelegDTO} from '../../openapi/beleg-openapi';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class SpliceActions {

  public static readBelege = createAction(
    '[Splice] Read belege.',
    props<{
      inhaberId: string,
      filterDto: FilterBelegDTO,
      pageableDto: PageableBelegDTO,
    }>(),
  );

  public static setDisplayedIds = createAction(
    '[Splice] Set beleg ids that should be displayed in table.',
    props<{
      belegDtos: BelegDTO[],
    }>(),
  );

  public static setSpliceList = createAction(
    '[Splice] Set list of belege that should be spliced.',
    props<{
      belegIdsToSplice: string[],
    }>(),
  );

  public static addToSpliceList = createAction(
    '[Splice] Add to list of belege that should be spliced.',
    props<{
      belegIdsToSplice: string[],
    }>(),
  );

  public static removeFromSpliceList = createAction(
    '[Splice] Remove from list of belege that should be spliced.',
    props<{
      belegIdsToRemove: string[],
    }>(),
  );

  public static setReadBelegePayload = createAction(
    '[Splice] Set filter and pagination payload for read belege request.',
    props<{
      pageableDto: PageableBelegDTO,
      filterDto: FilterBelegDTO,
    }>(),
  );

  public static setReadBelegePaginationOffset = createAction(
    '[Splice] Set pagination offset for read belege request.',
    props<{
      offset: number,
    }>(),
  );

  public static setPdfPreviewId = createAction(
    '[Splice] Set id of beleg that should be displayed in pdf preview.',
    props<{
      belegId?: string,
    }>(),
  );

  public static setSelectionFocus = createAction(
    '[Splice] Set the focus of the selection that defines the beleg for the preview.',
    props<{
      inToSpliceTable: boolean,
    }>(),
  );

  public static reset = createAction(
    '[Splice] Reset splice state.',
  );

  public static splice = createAction(
    '[Splice] Start splicing progress.',
    props<{
      inhaberId: string,
      belege: BelegDTO[],
    }>(),
  );

  public static spliceSuccess = createAction(
    '[Splice] Splicing progress succeeded.',
    props<{
      heftenResponseDTO: HeftenResponseDTO,
      oldIds: string[],
    }>(),
  );

  public static spliceFailure = createAction(
    '[Splice] Splicing progress failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static setSearchText = createAction(
    '[Splice] Set search text.',
    props<{
      searchText: string,
    }>(),
  );

  public static moveUp = createAction(
    '[Splice] Move beleg up in list.',
    props<{
      belegId: string,
    }>(),
  );

  public static moveDown = createAction(
    '[Splice] Move beleg down in list.',
    props<{
      belegId: string,
    }>(),
  );

  public static remove = createAction(
    '[Splice] Remove beleg from list.',
    props<{
      belegId: string,
    }>(),
  );

}
