import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {BelegEntitiesSelectors} from './beleg-entities.selectors';
import {ChipInput} from '@adnova/jf-ng-components';


export class OverviewTableSelectors {

  private static select = {
    filterDto: (state: AppState) => state.overviewTable.filterDto,
    filterVisible: (state: AppState) => state.overviewTable.filterVisible,
    pageableDto: (state: AppState) => state.overviewTable.pageableDto,
    selectedIds: (state: AppState) => state.overviewTable.selectedIds,
    displayedIds: (state: AppState) => state.overviewTable.displayedIds,
    tableSettings: (state: AppState) => state.overviewTable.tableSettings,
    chipInputIdentifiers: (state: AppState) => state.overviewTable.chipInputIdentifiers,
    vorgangsartDtos: (state: AppState) => state.belegEntities.vorgangsartDtos,
  };

  public static filterDto = createSelector(
    this.select.filterDto,
    identity,
  );

  static filterChips = createSelector(
    this.select.chipInputIdentifiers,
    chipInputIdentifiers => {

      let filterChips: ChipInput[] = [];

      if (!!chipInputIdentifiers) {

        for (let chipInputIdentifier of chipInputIdentifiers) {
          let filterChip: ChipInput = {
            id: chipInputIdentifier.filterId,
            label: chipInputIdentifier.filterLabel,
          };

          filterChips.push(filterChip);
        }
      }

      return filterChips;
    },
  );

  public static filterVisible = createSelector(
    this.select.filterVisible,
    identity,
  );

  public static filterCounter = createSelector(
    this.select.filterDto,
    filterDto => {
      let counter = 0;

      // INFO: Filter Suche
      if (filterDto.textfilter && filterDto.textfilter.length > 0) {
        counter++;
      }

      // INFO: Filter Status
      if (filterDto.offen || filterDto.bearbeitet || filterDto.festgeschrieben || filterDto.storniert) {
        counter++;
      }

      // INFO: Filter Hochladedatum
      if (filterDto.erstelltVon || filterDto.erstelltBis) {
        counter++;
      }

      // INFO: Filter Belegtyp
      if (filterDto.vorgangsartIds?.length) {
        counter++;
      }

      // INFO: Filter Ein-Ausgang
      if (filterDto.eingangsbeleg || filterDto.ausgangsbeleg) {
        counter++;
      }

      // INFO: Filter Dubletten
      if (filterDto.dublettenOnly) {
        counter++;
      }

      // INFO: Filter Belegdatum
      if (filterDto.datumVon || filterDto.datumBis) {
        counter++;
      }

      // INFO: Filter Betrag von
      if (filterDto.betragVon !== undefined && filterDto.betragVon !== null) {
        counter++;
      }

      // INFO: Filter Betrag bis
      if (filterDto.betragBis !== undefined && filterDto.betragBis !== null) {
        counter++;
      }

      return counter;
    },
  );

  public static pageableDto = createSelector(
    this.select.pageableDto,
    identity,
  );

  public static selectedIds = createSelector(
    this.select.selectedIds,
    identity,
  );

  public static tableSettings = createSelector(
    this.select.tableSettings,
    identity,
  );

  public static selectedBelege = (inhaberId: string) => createSelector(
    this.select.selectedIds,
    BelegEntitiesSelectors.all,
    (selectedIds, belege) =>
      BelegEntitiesSelectors.belegeByIds(inhaberId, selectedIds).projector(belege)
  );

  public static areAllIdsSelected = createSelector(
    this.select.displayedIds,
    this.select.selectedIds,
    (allIds, selectedIds) =>
      allIds.every(id => selectedIds.includes(id)) && !!allIds.length,
  );

  public static displayedBelege = (inhaberId: string) => createSelector(
    this.select.displayedIds,
    BelegEntitiesSelectors.all,
    (selectedIds, belege) =>
      BelegEntitiesSelectors.belegeByIds(inhaberId, selectedIds).projector(belege)
  );
}
