import {AppState} from '../states/app.state';
import {belegEntitiesAdapter} from '../adapters/beleg-entities.adapter';
import {createSelector, MemoizedSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {BelegDTO} from '../../openapi/beleg-openapi';


export class BelegEntitiesSelectors {

  private static adapterSelectors = belegEntitiesAdapter.getSelectors();

  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    belegEntities: (state: AppState) => state.belegEntities,
    isLoading: (state: AppState) => state.belegEntities.isLoading,
    isMoving: (state: AppState) => state.belegEntities.isMoving,
    countedBelege: (state: AppState) => state.belegEntities.countedBelege,
    badgeValues: (state: AppState) => state.belegEntities.badgeValues,
    reverseBelegData: (state: AppState) => state.belegEntities?.reverseBelegData,
    lastDeletedBelegData: (state: AppState) => state.belegEntities?.lastDeletedBelegData,
    vorgangsartDtos: (state: AppState) => state.belegEntities?.vorgangsartDtos,
  };

  public static all = createSelector(
    this.select.belegEntities,
    this.adapterSelectors.selectAll,
  );

  public static belegById = (inhaberId: string, belegId: string) => createSelector(
    this.all,
    belege => belege.find(beleg => {
      return beleg.inhaberId === inhaberId && beleg.id === belegId;
    }),
  );

  public static isMoving = createSelector(
    this.select.isMoving,
    identity,
  );

  public static countedBelege = createSelector(
    this.select.countedBelege,
    identity,
  );

  public static badgeValueByInhaber = (inhaberId: string) => createSelector(
    this.select.badgeValues,
    badgeValues => badgeValues.find(badgeValue => badgeValue.inhaberId === inhaberId),
  );

  public static badgeValues = createSelector(
    this.select.badgeValues,
    identity
  )

  /**
   * Selektor, der Belege anhand einer Liste von IDs zurückgibt und dabei deren Reihenfolge beibehält.
   *
   * @param {string} inhaberId - Die ID des Inhabers der Belege.
   * @param {string[]} belegIds - Ein Array von BelegIDs.
   * Die Reihenfolge dieses Arrays bestimmt die Reihenfolge der zurückgegebenen Belege.
   *
   * @returns {MemoizedSelector<AppState, BelegDTO[]>} Ein Selektor, der ein Array von Belegen zurückgibt, die den
   * angegebenen IDs entsprechen, in der in `belegIds` angegebenen Reihenfolge für den ausgewählten Inhaber.
   * Gibt ein leeres Array zurück, wenn keine übereinstimmenden Belege gefunden wurden.
   */
  public static belegeByIds = (inhaberId: string, belegIds: string[]): MemoizedSelector<AppState, BelegDTO[]> => createSelector(
    this.all,
    belegDtos => {
      const belegMap = new Map(belegDtos.map(belegDto => [belegDto.id, belegDto]));
      return belegIds
        .map(id => belegMap.get(id))
        .filter((belegDto): belegDto is BelegDTO => !!belegDto)
        .filter(belegDto => belegDto.inhaberId === inhaberId);
    }
  );

  public static reverseBelegData = createSelector(
    this.select.reverseBelegData,
    data => {
      return {
        success: data?.reverseBelegSuccess,
        korrekturBelegId: data?.korrekturBelegId,
      };
    },
  );

  public static lastDeletedBelegData = createSelector(
    this.select.lastDeletedBelegData,
    identity,
  );

  public static allVorgangsartDtos = createSelector(
    this.select.vorgangsartDtos,
    identity,
  );

  public static vorgangsartDtosByInhaberId = (inhaberId: string) => createSelector(
    this.select.vorgangsartDtos,
    vorgangsartDtos => {
      return vorgangsartDtos?.filter(vorgangsartDto => vorgangsartDto.inhaberId === inhaberId);
    },
  );

  public static kontoumsatzIds = (inhaberId: string, belegId: string) => createSelector(
    this.belegById(inhaberId, belegId),
    beleg => beleg?.bankkontoumsatzIds,
  );

  public static haskontoumsatzIds = (inhaberId: string, belegId: string) => createSelector(
    this.belegById(inhaberId, belegId),
    beleg => !!beleg?.bankkontoumsatzIds?.length,
  );
}
