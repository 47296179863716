import {createAction, props} from '@ngrx/store';
import {BelegAktionen} from '../states/beleg-aktionen-entities.state';


export class BelegAktionenEntitiesActions {

  public static readBelegAktionen = createAction(
    '[Beleg Aktionen Entities] Start reading beleg aktionen.',
    props<{
      inhaberId: string,
      belegId: string,
    }>(),
  );

  public static readBelegAktionenSuccess = createAction(
    '[Beleg Aktionen Entities] Read beleg aktionen successfully.',
    props<{
      belegAktionen: BelegAktionen,
    }>(),
  );

}
