import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Config, ConfigService} from './service/config.service';
import {ServiceModule} from './service/service.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ServiceModule,
  ],
  providers: [
    {
      provide: Config,
      useFactory: (configService: ConfigService) => configService.config,
      deps: [ConfigService],
    },
  ],
})
export class ConfigModule {
}
