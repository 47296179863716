import {createAction, props} from '@ngrx/store';
import {
  BelegDTO,
  EntheftenResponseDTO,
  FilterBelegDTO,
  VorgangsartDTO,
} from '../../openapi/beleg-openapi';
import {ActivityIdentifier} from '../../interfaces/activity-identifier.interface';
import {BadgeValue} from '../../interfaces/badge-value.interface';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class BelegEntitiesActions {

  public static startReadingBelege = createAction(
    '[Beleg Entities] Start reading list of belege.',
  );

  public static readBelegeSuccess = createAction(
    '[Beleg Entities] Read list of belege successfully.',
    props<{
      belegDtos: BelegDTO[],
    }>(),
  );

  public static readBelegeFailure = createAction(
    '[Beleg Entities] Read list of belege failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static getBeleg = createAction(
    '[Beleg Entities] Get single beleg.',
    props<{
      inhaberId: string,
      belegId: string,
    }>(),
  );

  public static getBelegSuccess = createAction(
    '[Beleg Entities] Get single beleg successfully.',
    props<{
      belegDto: BelegDTO,
    }>(),
  );

  public static createBeleg = createAction(
    '[Overview Table] Create beleg.',
    props<{
      inhaberId: string,
      objectUrl: string,
      size: number,
      name: string,
    }>(),
  );

  public static createBelegFailure = createAction(
    '[Beleg Effect] Create beleg failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static getImportStatus = createAction(
    '[Beleg Effect] Created beleg successfully, start getting import-state.',
    props<{
      inhaberId: string,
      belegId: string,
      belegname: string,
    }>(),
  );

  public static getImportStatusFailure = createAction(
    '[Beleg Effect] Getting import-state failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static openBelegPreviewDialog = createAction(
    '[Preview Dialog] Open preview dialog.',
    props<{
      inhaberId: string,
      belegId: string,
    }>(),
  );

  public static closeBelegPreviewDialog = createAction(
    '[Preview Dialog] Close preview dialog.',
  );

  public static deleteBeleg = createAction(
    '[Overview Table] Delete beleg.',
    props<{
      inhaberId: string,
      belegId: string,
    }>(),
  );

  public static deleteBelegSuccess = createAction(
    '[Beleg Effect] Delete beleg succeeded.',
    props<{
      inhaberId: string,
      belegId: string,
      identifier: ActivityIdentifier,
    }>(),
  );

  public static deleteBelegFailure = createAction(
    '[Beleg Effect] Delete belege failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static countBelege = createAction(
    '[Overview Table] Count belege.',
    props<{
      inhaberId: string,
      filterDto: FilterBelegDTO,
    }>(),
  );

  public static countBelegeSuccess = createAction(
    '[Beleg Effect] Count belege successfully.',
    props<{
      countedBelege: number,
    }>(),
  );

  public static countBadgeValue = createAction(
    '[Left Panel] Count the badge value.',
    props<{
      inhaberId: string,
    }>(),
  );

  public static countBadgeValueSuccess = createAction(
    '[Beleg Effect] Counted the badge value successfully.',
    props<{
      badgeValue: BadgeValue,
    }>(),
  );

  public static move = createAction(
    '[Belege] Start move belege action.',
    props<{
      sourceInhaberId: string,
      belege: BelegDTO[],
      destinationInhaberId: string,
    }>()
  );

  public static moveSuccess = createAction(
    '[Belege] Moving beleg succeeded.',
    props<{
      belegId: string,
      identifier?: ActivityIdentifier,
      sourceInhaberId: string,
      destinationInhaberId: string,
    }>(),
  );

  public static moveFailure = createAction(
    '[Belege] Moving beleg failed.',
    props<{
      error: MappedHttpErrorResponse,
      identifier?: ActivityIdentifier,
    }>(),
  );

  public static downloadBeleg = createAction(
    '[Overview Table || Edit Page] Download beleg.',
    props<{
      inhaberId: string,
      belegId: string,
      name: string,
    }>(),
  );

  public static downloadBelegSuccess = createAction(
    '[Beleg Effect] Download beleg succeeded.',
    props<{
      objectUrl: string,
      name: string,
    }>(),
  );

  public static downloadBelegFailure = createAction(
    '[Beleg Effect] Download belege failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static entheften = createAction(
    '[Overview Table || Edit Page] Slice beleg.',
    props<{
      belegDtos: BelegDTO[],
    }>(),
  );

  public static entheftenSuccess = createAction(
    '[Beleg Effect] Slice beleg succeeded.',
    props<{
      inhaberId: string,
      responseDto: EntheftenResponseDTO,
      belegIdToRemove: string,
    }>(),
  );

  public static entfheftenFailure = createAction(
    '[Beleg Effect] Slice belege failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static markOriginalBelegAsDublette = createAction(
    '[Beleg Effect] Marks the original beleg as dublette.',
    props<{
      originalBelegId: string,
      dubletteId: string,
    }>(),
  );

  public static unmarkOriginalBelegAsDublette = createAction(
    '[Beleg Effect] Unmarks the last dublette.',
    props<{
      belegId: string,
    }>(),
  );

  public static reverseBeleg = createAction(
    '[Beleg-stipulated-info-dialog] Reverse beleg.',
    props<{
      belegId: string,
      inhaberId: string,
      createKorrekturBeleg: boolean,
      belegAlreadyReversed: boolean,
      isDublette: boolean,
    }>(),
  );

  public static reverseBelegSuccess = createAction(
    '[Beleg Effect] Reverse beleg succeeded.',
    props<{
      inhaberId: string,
      oldBelegId: string,
      korrekturBelegId: string,
      belegAlreadyReversed: boolean,
    }>(),
  );

  public static reverseBelegFailure = createAction(
    '[Beleg Effect] Reverse beleg failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static resetReverseBelegData = createAction(
    '[Overview- & Edit-Page] Resets the reverse-beleg-data.',
  );

  public static updateBeleg = createAction(
    '[Beleg] Update beleg.',
    props<{
      belegDto: BelegDTO,
      stipulateBeleg: boolean,
    }>(),
  );

  public static updateBelegFailure = createAction(
    '[Beleg] Update belege failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static stipulateBeleg = createAction(
    '[Beleg] Stipulate beleg.',
    props<{
      inhaberId: string,
      belegId: string,
    }>(),
  );

  public static stipulateBelegSuccess = createAction(
    '[Beleg] Stipulate beleg succeeded.',
  );

  public static stipulateBelegFailure = createAction(
    '[Beleg] Stipulate belege failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

  public static readVorgangsarten = createAction(
    '[Beleg] Read vorgangsarten.',
    props<{
      inhaberId: string,
    }>(),
  );

  public static readVorgangsartenSuccess = createAction(
    '[Beleg] Read vorgangsarten succeeded.',
    props<{
      vorgangsartDtos: VorgangsartDTO[],
    }>(),
  );

  public static readVorgangsartenFailure = createAction(
    '[Beleg] Read vorgangsarten failed.',
    props<{
      error: MappedHttpErrorResponse,
    }>(),
  );

}
