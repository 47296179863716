import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {tap} from 'rxjs';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import * as Sentry from '@sentry/browser';
import {SentryActions} from '../actions/sentry.actions';
import {PortalTokenService} from '../../portal/portal-token.service';


@Injectable()
export class SentryEffects {

  constructor(
    private actions$: Actions,
    private portalTokenService: PortalTokenService,
  ) {
  }

  readonly captureException$ = createEffect(
    () => this.actions$.pipe(
      ofType(SentryActions.captureException),
      tap(({error, extras}) => {
        const decoded = jwtDecode<JwtPayload>(this.portalTokenService.getAdnovaToken());
        const token = decoded as any;

        const email = token.email;
        const username = token.preferred_username;
        const name = token.name;

        Sentry.withScope(scope => {
          let user = scope.getUser();
          if (!user) {
            user = {};
          }
          user.id = decoded.sub;
          user.email = email;
          user.username = username;
          user['name'] = name;

          scope.setUser(user);
          scope.setExtras(extras);

          Sentry.captureException(error);
        });

      }),
    ), {dispatch: false}
  );

}
