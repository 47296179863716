import {createAction, props} from '@ngrx/store';
import {ReuseBelegData} from '../../interfaces/reuse-beleg-data.interface';


export class BelegStipulatedDialogActions {

  public static openBelegStipulatedDialog = createAction(
    '[Overview- & Edit-Page] Open beleg-stipulated-dialog.',
    props<{
      data: ReuseBelegData,
    }>(),
  );

  public static closeBelegStipulatedDialog = createAction(
    '[Beleg-Stipulated-Dialog] Close beleg-stipulated-dialog.',
  );

}
