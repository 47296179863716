import {createSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {identity} from 'rxjs';
import {MoveBelegData} from '../../modules/move-beleg-dialog/move-beleg-data.interface';


export class MoveBelegDialogSelectors {

  private static select = {
    sourceInhaberId: (state: AppState) => state.moveBelegDialog?.sourceInhaberId,
    belegIds: (state: AppState) => state.moveBelegDialog?.belegIds,
  };

  public static sourceInhaberId = createSelector(
    this.select.sourceInhaberId,
    identity
  );

  public static belegIds = createSelector(
    this.select.belegIds,
    identity,
  );

  public static moveBelegDialogPayload = createSelector(
    this.sourceInhaberId,
    this.belegIds,
    (sourceInhaberId, belegIds) => {
      const moveBelegData: MoveBelegData = {
        sourceInhaberId: sourceInhaberId || '',
        belegIds: belegIds || [],
      };
      return moveBelegData;
    });

}
