import {OverviewTableState} from '../states/overview-table.state';
import {createReducer, on} from '@ngrx/store';
import {OverviewTableActions} from '../actions/overview-table.actions';
import {
  BelegbucheinAusgangDTO,
  FilterBelegDTO,
  PageableBelegDTOSortingEnum,
} from '../../openapi/beleg-openapi';
import {BelegListColumn} from 'src/app/modules/overview/belege-list/belege-list.component';
import {BelegEntitiesActions} from '../actions/beleg-entities.actions';
import {FilterChipInputIdentifier} from '../../interfaces/filter-chip-input-identifier.interface';
import * as moment from 'moment/moment';
import {v4 as uuid} from 'uuid';


export const initialOverviewTableState: OverviewTableState = {
  filterDto: {},
  filterVisible: false,
  pageableDto: {
    limit: 50,
    offset: 0,
    sorting: [PageableBelegDTOSortingEnum.Erstelltdesc],
  },
  selectedIds: [],
  displayedIds: [],
  chipInputIdentifiers: [],
  tableSettings: {
    _key: 'overview-table-settings',
    _columns: [
      {id: BelegListColumn.Checkbox, name: '', isActive: true},
      {id: BelegListColumn.Status, name: 'Status', isActive: true},
      {id: BelegListColumn.Erstellt, name: 'Hochgeladen am', isActive: true},
      {id: BelegListColumn.Belegtyp, name: 'Belegtyp', isActive: true},
      {id: BelegListColumn.BelegbucheinAusgang, name: 'Ein-/Ausgang', isActive: false},
      {id: BelegListColumn.Kommentar, name: 'Kommentar', isActive: false},
      {id: BelegListColumn.Buchstelle, name: 'von Buchstelle', isActive: false},
      {id: BelegListColumn.Partner, name: 'Kunde/Lieferant', isActive: true},
      {id: BelegListColumn.Datum, name: 'Belegdatum', isActive: true},
      {id: BelegListColumn.Betrag, name: 'Betrag', isActive: true},
      {id: BelegListColumn.Seitenzahl, name: 'Seitenzahl', isActive: true},
      {id: BelegListColumn.Aktion, name: '', isActive: true},
    ],
    _sortDirs: ['desc'],
    _sortParams: [BelegListColumn.Erstellt],
  },
};

export const overviewTableReducer = createReducer(
  initialOverviewTableState,

  on(
    OverviewTableActions.setDisplayedIds,
    (state, {belegDtos}) => ({
      ...state,
      displayedIds: belegDtos.map(beleg => beleg.id),
    }),
  ),

  /*
   * Reducer für das Hinzufügen einer ID zur Liste der angezeigten IDs.
   * Dieser Reducer aktualisiert den State, indem er eine neue ID zur Liste hinzufügt.
   * Es wird sichergestellt, dass die ID eindeutig ist und nicht dupliziert wird.
   * Wenn die ID bereits in der Liste vorhanden ist, bleibt die Liste unverändert.
   */
  on(
    OverviewTableActions.addDisplayedId,
    (state, {belegDto}) => ({
      ...state,
      displayedIds: state.displayedIds.includes(belegDto.id)
        ? state.displayedIds
        : [belegDto.id, ...state.displayedIds],
    }),
  ),

  on(
    OverviewTableActions.clearDisplayedBelege,
    (state) => ({
      ...state,
      displayedIds: [],
    }),
  ),

  on(
    OverviewTableActions.showFilter,
    (state) => ({
      ...state,
      filterVisible: true,
    }),
  ),

  on(
    OverviewTableActions.hideFilter,
    (state) => ({
      ...state,
      filterVisible: false,
    }),
  ),

  on(
    OverviewTableActions.updateSearchFilter,
    (state, {searchValue}) => {

      let updatedTextFilter: string[] | undefined;

      let updatedFilterChipInputIdentifiers: FilterChipInputIdentifier[] = [...state.chipInputIdentifiers];

      if (searchValue.length === 0) {
        updatedTextFilter = undefined;

        // INFO: Wenn der Textfilter leer ist, dann wird der Filter aus dem ChipInputIdentifier entfernt
        updatedFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          state.chipInputIdentifiers,
          'textfilter',
        );

      } else {
        updatedTextFilter = searchValue.split(' ');

        // INFO: Wenn der Textfilter nicht leer ist, dann wird der Filter in den ChipInputIdentifier hinzugefügt
        const identifier = createFilterChipInputIdentifier(
          searchValue,
          'textfilter',
          searchValue,
        );

        updatedFilterChipInputIdentifiers = updateFilterChipInputIdentifier(
          updatedFilterChipInputIdentifiers,
          [identifier],
        );
      }

      return {
        ...state,
        filterDto: {
          ...state.filterDto,
          textfilter: updatedTextFilter,
        },
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    }
  ),

  on(
    OverviewTableActions.updateStatusFilter,
    (state, {
      offen,
      bearbeitet,
      festgeschrieben,
      storniert,
    }) => {

      /*
      * INFO:
      * Da die Status als einzelne Filter behandelt werden,
      * im selben Zuge gelöscht als auch hinzugefügt werden können und dies ebenfalls im selben Reducer passiert,
      * wird die aktuelle Liste der FilterChipInputIdentifier in eine neue Liste kopiert
      * und der Store dann mit der neuen Liste aktualisiert.
      */
      let currentFilterChipInputIdentifiers: FilterChipInputIdentifier[] = [...state.chipInputIdentifiers];

      // INFO: FilterChipInputIdentifier für neue Status Filter erstellen
      let newFilterChipInputIdentifiers: FilterChipInputIdentifier[] = [];

      // INFO: FilterChipInputIdentifier für die Status hinzufügen oder entfernen
      if (offen) {
        newFilterChipInputIdentifiers.push(createFilterChipInputIdentifier(
          'Offene Belege',
          'offen',
          offen,
        ));
      } else {
        currentFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          currentFilterChipInputIdentifiers,
          'offen',
        );
      }

      if (bearbeitet) {
        newFilterChipInputIdentifiers.push(createFilterChipInputIdentifier(
          'Bearbeitete Belege',
          'bearbeitet',
          bearbeitet,
        ));
      } else {
        currentFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          currentFilterChipInputIdentifiers,
          'bearbeitet',
        );
      }

      if (festgeschrieben) {
        newFilterChipInputIdentifiers.push(createFilterChipInputIdentifier(
          'Fertiggestellte Belege',
          'festgeschrieben',
          festgeschrieben,
        ));
      } else {
        currentFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          currentFilterChipInputIdentifiers,
          'festgeschrieben',
        );
      }

      if (storniert) {
        newFilterChipInputIdentifiers.push(createFilterChipInputIdentifier(
          'Stornierte Belege',
          'storniert',
          storniert,
        ));
      } else {
        currentFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          currentFilterChipInputIdentifiers,
          'storniert',
        );
      }

      const updatedFilterChipInputIdentifiers = updateFilterChipInputIdentifier(
        currentFilterChipInputIdentifiers,
        newFilterChipInputIdentifiers,
      );

      return {
        ...state,
        filterDto: {
          ...state.filterDto,
          offen,
          bearbeitet,
          festgeschrieben,
          storniert,
        },
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    }
  ),

  on(
    OverviewTableActions.updateEinAusgangFilter,
    (state, {einAusgang}) => {

      /*
       * INFO:
       * Da eingangsbeleg und ausgangsbeleg als einzelne Filter behandelt werden,
       * im selben Zuge gelöscht als auch hinzugefügt werden können und dies ebenfalls im selben Reducer passiert,
       * wird die aktuelle Liste der FilterChipInputIdentifier in eine neue Liste kopiert
       * und der Store dann mit der neuen Liste aktualisiert.
       */
      let currentFilterChipInputIdentifiers: FilterChipInputIdentifier[] = [...state.chipInputIdentifiers];

      // INFO: FilterChipInputIdentifier für neue eingangsbeleg und oder ausgangsbeleg Filter erstellen
      let newFilterChipInputIdentifiers: FilterChipInputIdentifier[] = [];

      const isEingangsbeleg = einAusgang.includes(BelegbucheinAusgangDTO.Belegeingang) || false;
      if (isEingangsbeleg) {

        // INFO: FilterChipInputIdentifier für eingangsbeleg erstellen
        newFilterChipInputIdentifiers.push(createFilterChipInputIdentifier(
          'Eingang',
          'eingangsbeleg',
          isEingangsbeleg,
        ));
      } else {

        // INFO: FilterChipInputIdentifier für eingangsbeleg entfernen
        currentFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          currentFilterChipInputIdentifiers,
          'eingangsbeleg',
        );
      }

      const isAusgangsbeleg = einAusgang.includes(BelegbucheinAusgangDTO.Belegausgang) || false;
      if (isAusgangsbeleg) {

        // INFO: FilterChipInputIdentifier für ausgangsbeleg erstellen
        newFilterChipInputIdentifiers.push(createFilterChipInputIdentifier(
          'Ausgang',
          'ausgangsbeleg',
          isAusgangsbeleg,
        ));
      } else {

        // INFO: FilterChipInputIdentifier für ausgangsbeleg entfernen
        currentFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          currentFilterChipInputIdentifiers,
          'ausgangsbeleg',
        );
      }

      const updatedFilterChipInputIdentifiers = updateFilterChipInputIdentifier(
        currentFilterChipInputIdentifiers,
        newFilterChipInputIdentifiers,
      );

      return {
        ...state,
        filterDto: {
          ...state.filterDto,
          eingangsbeleg: isEingangsbeleg,
          ausgangsbeleg: isAusgangsbeleg,
        },
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    }
  ),

  on(
    OverviewTableActions.updateShowDublettenFilter,
    (state, {showDubletten}) => {

      // INFO: FilterChipInputIdentifier für showDubletten aktualisieren
      let updatedFilterChipInputIdentifiers: FilterChipInputIdentifier[] = [...state.chipInputIdentifiers];

      if (showDubletten) {
        // INFO: FilterChipInputIdentifier für showDubletten erstellen
        const showDublettenIdentifier = createFilterChipInputIdentifier(
          'Nur Dubletten',
          'dublettenOnly',
          showDubletten,
        );

        updatedFilterChipInputIdentifiers = updateFilterChipInputIdentifier(
          updatedFilterChipInputIdentifiers,
          [showDublettenIdentifier],
        );
      } else {
        // INFO: FilterChipInputIdentifier für showDubletten entfernen
        updatedFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          updatedFilterChipInputIdentifiers,
          'dublettenOnly',
        );
      }

      return {
        ...state,
        filterDto: {
          ...state.filterDto,
          dublettenOnly: showDubletten,
        },
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    }
  ),

  on(
    OverviewTableActions.updateCreatedDateFilter,
    (state, {dateFilter}) => {

      // INFO: FilterChipInputIdentifier für HochgeladenVon und ggf. HochgeladenBis erstellen
      let newFilterChipInputIdentifier: FilterChipInputIdentifier[] = [];

      const hochgeladenVonIdentifier = createFilterChipInputIdentifier(
        'Hochgeladen von ' + moment(dateFilter.from, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        'erstelltVon',
        dateFilter.from,
      );
      newFilterChipInputIdentifier.push(hochgeladenVonIdentifier);

      if (dateFilter.to) {
        const hochgeladenbisIdentifier = createFilterChipInputIdentifier(
          'Hochgeladen bis ' + moment(dateFilter.to, 'YYYY-MM-DD').format('DD.MM.YYYY'),
          'erstelltBis',
          dateFilter.to,
        );

        newFilterChipInputIdentifier.push(hochgeladenbisIdentifier);
      }

      const updatedFilterChipInputIdentifiers = updateFilterChipInputIdentifier(
        [...state.chipInputIdentifiers],
        newFilterChipInputIdentifier,
      );

      return {
        ...state,
        filterDto: {
          ...state.filterDto,
          erstelltVon: dateFilter.from || undefined,
          erstelltBis: dateFilter.to || undefined,
        },
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    }
  ),

  on(
    OverviewTableActions.updateBelegDateFilter,
    (state, {dateFilter}) => {

      // INFO: FilterChipInputIdentifier für HochgeladenVon und ggf. HochgeladenBis erstellen
      let newFilterChipInputIdentifier: FilterChipInputIdentifier[] = [];

      const hochgeladenVonIdentifier = createFilterChipInputIdentifier(
        'Belegdatum von ' + moment(dateFilter.from, 'YYYY-MM-DD').format('DD.MM.YYYY'),
        'datumVon',
        dateFilter.from,
      );
      newFilterChipInputIdentifier.push(hochgeladenVonIdentifier);

      if (dateFilter.to) {
        const hochgeladenbisIdentifier = createFilterChipInputIdentifier(
          'Belegdatum bis ' + moment(dateFilter.to, 'YYYY-MM-DD').format('DD.MM.YYYY'),
          'datumBis',
          dateFilter.to,
        );

        newFilterChipInputIdentifier.push(hochgeladenbisIdentifier);
      }

      const updatedFilterChipInputIdentifiers = updateFilterChipInputIdentifier(
        [...state.chipInputIdentifiers],
        newFilterChipInputIdentifier,
      );

      return {
        ...state,
        filterDto: {
          ...state.filterDto,
          datumVon: dateFilter.from || undefined,
          datumBis: dateFilter.to || undefined,
        },
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    }
  ),

  on(
    OverviewTableActions.resetSingleFilter,
    (state, {id}) => {

      // INFO: FilterBelegDTO aktualisieren
      const identifier = state.chipInputIdentifiers.find(chip => {
        return chip.filterId === id;
      })!;

      let newFilterDto: FilterBelegDTO = {
        ...state.filterDto,
      };

      /*
       * INFO:
       * Wenn der zu entfernende Filter eine einzelne Propertie im FilterBelegDTO ist,
       * dann wird diese auf undefined gesetzt.
       *
       * Vorgangsart bildet hierbei einen Sonderfall, da es sich um ein Array handelt.
       * In diesem Fall wird der zu entfernende Wert anhand der Id aus dem Array gefiltert
       * und das neue Array in das DTO übernommen.
       */
      if (identifier.filterName === 'vorgangsart') {
        let newVorgangsartIds = state.filterDto.vorgangsartIds!.filter(id => {
          return id !== identifier.filterValue;
        });

        newFilterDto.vorgangsartIds = newVorgangsartIds;
      } else {
        newFilterDto = {
          ...state.filterDto,
          [identifier.filterName]: undefined
        };
      }

      // INFO: chipInputIdentifier aktualisieren
      const updatedFilterChipInputIdentifiers = state.chipInputIdentifiers.filter(chip => {
        return chip.filterId !== id;
      });

      return {
        ...state,
        filterDto: newFilterDto,
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    }
  ),

  on(
    OverviewTableActions.resetAllFilter,
    (state) => ({
      ...state,
      filterDto: initialOverviewTableState.filterDto,
      chipInputIdentifiers: initialOverviewTableState.chipInputIdentifiers,
    })
  ),

  on(
    OverviewTableActions.updatePageIndex,
    (state, {pageIndex}) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        offset: pageIndex * state.pageableDto.limit!,
      }
    })
  ),

  on(
    BelegEntitiesActions.startReadingBelege,
    (state) => ({
      ...state,
      selectedIds: [],
    })
  ),

  on(
    OverviewTableActions.toggleBelege,
    (state, {belegIds}) => {
      let selectedIds: string[] = [...state.selectedIds];

      belegIds.forEach(belegId => {
        const checked = state.selectedIds.includes(belegId);
        if (checked) {
          selectedIds = selectedIds.filter(id => id !== belegId);
        } else {
          selectedIds.push(belegId);
        }
      });

      return {
        ...state,
        selectedIds,
      };
    },
  ),

  on(
    OverviewTableActions.toggleAll,
    (state, {belegIds}) => {

      let selectedIds: string[] = [...state.selectedIds];

      // INFO: Wenn bereits entweder alle oder einige Elemente ausgewählt sind, dann alle deselektieren
      if (selectedIds.length === belegIds.length || selectedIds.length > 0) {
        selectedIds = [];
      }

      // INFO: Wenn noch keine Elemente ausgewählt sind, dann alle selektieren
      else {
        selectedIds = belegIds;
      }

      return {
        ...state,
        selectedIds,
      };
    },
  ),

  on(
    OverviewTableActions.updateSortingEnum,
    (state, {sorting}) => ({
      ...state,
      pageableDto: {
        ...state.pageableDto,
        sorting,
      },
    })
  ),

  on(
    OverviewTableActions.changeTableSettings,
    (state, action) => ({
      ...state,
      tableSettings: action.tableSettings,
    })
  ),

  on(
    OverviewTableActions.updateBelegSuccess,
    (state) => ({
      ...state,
      selectedIds: [],
    }),
  ),

  on(
    BelegEntitiesActions.stipulateBelegSuccess,
    (state) => ({
      ...state,
      selectedIds: [],
    }),
  ),

  on(
    BelegEntitiesActions.moveSuccess,
    (state, {belegId}) => ({
      ...state,
      selectedIds: state.selectedIds.filter(id => id !== belegId),
      displayedIds: state.displayedIds.filter(id => id !== belegId),
    }),
  ),

  on(
    BelegEntitiesActions.deleteBelegSuccess,
    (state, {belegId}) => ({
      ...state,
      selectedIds: state.selectedIds.filter(id => id !== belegId),
      displayedIds: state.displayedIds.filter(id => id !== belegId),
    }),
  ),

  on(
    OverviewTableActions.setBetragvonFilter,
    (state, {betragVon}) => {

      let updatedFilterChipInputIdentifiers: FilterChipInputIdentifier[] = [];
      if (betragVon !== null) {
        const identifier = createFilterChipInputIdentifier(
          'Betrag von ' + betragVon + ' €',
          'betragVon',
          betragVon,
        );

        updatedFilterChipInputIdentifiers = updateFilterChipInputIdentifier(
          [...state.chipInputIdentifiers],
          [identifier],
        );
      } else {

        // INFO: Wenn der BetragVon-Filter null ist, dann wird der Filter aus den ChipInputIdentifiers entfernt
        updatedFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          state.chipInputIdentifiers,
          'betragVon',
        );
      }

      return {
        ...state,
        filterDto: {
          ...state.filterDto,
          betragVon,
        },
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    },
  ),

  on(
    OverviewTableActions.setBetragbisFilter,
    (state, {betragBis}) => {

      let updatedFilterChipInputIdentifiers: FilterChipInputIdentifier[] = [];

      if (betragBis !== null) {
        const identifier = createFilterChipInputIdentifier(
          'Betrag bis ' + betragBis + ' €',
          'betragBis',
          betragBis,
        );

        updatedFilterChipInputIdentifiers = updateFilterChipInputIdentifier(
          [...state.chipInputIdentifiers],
          [identifier],
        );
      } else {

        // INFO: Wenn der BetragBis-Filter null ist, dann wird der Filter aus den ChipInputIdentifiers entfernt
        updatedFilterChipInputIdentifiers = deleteFilterChipInputIdentifierByFilterName(
          state.chipInputIdentifiers,
          'betragBis',
        );
      }

      return {
        ...state,
        filterDto: {
          ...state.filterDto,
          betragBis,
        },
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    },
  ),

  on(
    OverviewTableActions.setBelegtypFilter,
    (state, {selectedVorgangsartIds, allVorgangsartDtos}) => {

      // INFO: FilterChipInputIdentifiers aktualisieren
      let updatedFilterChipInputIdentifiers: FilterChipInputIdentifier[] = [
        ...state.chipInputIdentifiers,
      ];

      /*
       * INFO:
       * Vorgangsarten, die der Anwender abgewählt hat, müssen aus den ChipInputIdentifiers entfernt werden.
       * Hierbei können diese über den ‘filterName‘ (vorgangsart) und den 'filterValue' identifiziert werden,
       * da der 'filterValue' einer Vorgangsart immer eine eindeutige UUID ist, welche serverseitig generiert wird.
       */
      const currentVorgangsartValues = updatedFilterChipInputIdentifiers
        .filter(chip => {
          return chip.filterName === 'vorgangsart';
        })
        .map(chip => {
          return chip.filterValue;
        });

      for (let currentVorgangsartId of currentVorgangsartValues) {

        // INFO: In diesem Fall ist die currentVorgangsartId immer ein String und darf / muss daher gecastet werden
        if (!selectedVorgangsartIds.includes(currentVorgangsartId as string)) {
          const indexToRemove = updatedFilterChipInputIdentifiers.findIndex(chip => {
            return chip.filterName == 'vorgangsart' && chip.filterValue === currentVorgangsartId;
          });

          updatedFilterChipInputIdentifiers.splice(indexToRemove, 1);
        }
      }

      /*
       * INFO: Vorgangsarten, die der Anwender neu ausgewählt hat,
       * müssen zu den ChipInputIdentifiers hinzugefügt werden.
       */
      for (let vorgangsartId of selectedVorgangsartIds) {

        if (!currentVorgangsartValues.includes(vorgangsartId)) {
          const vorgangsartDto = allVorgangsartDtos!.find(vorgangsartDto => {
            return vorgangsartId === vorgangsartDto.id;
          });

          if (!!vorgangsartDto) {
            updatedFilterChipInputIdentifiers.push(createFilterChipInputIdentifier(
              'Belegtyp ' + vorgangsartDto.bezeichnung,
              'vorgangsart',
              vorgangsartId,
            ));
          }
        }
      }

      return {
        ...state,
        filterDto: {
          ...state.filterDto,
          vorgangsartIds: selectedVorgangsartIds,
        },
        chipInputIdentifiers: updatedFilterChipInputIdentifiers,
      };
    }
  ),

  on(
    OverviewTableActions.toggleBelege,
    (state) => {

      return {
        ...state,
      };
    },
  ),
);

/**
 * Erstellt einen FilterChipInputIdentifier.
 *
 * @param filterLabel Label, dass der Anwender sehen soll
 * @param filterName Name des Filters im FilterBelegDTO
 * @param filterValue Der Wert des Filters
 */
function createFilterChipInputIdentifier(
  filterLabel: string,
  filterName: string,
  filterValue: any,
): FilterChipInputIdentifier {
  return {
    filterId: uuid(),
    filterLabel,
    filterName,
    filterValue,
  };
}

/**
 * Aktualisierte die Liste der FilterChipInputIdentifier und gibt diese zurück.
 *
 * @param currentFilterChipInputIdentifiers Die aktuelle Liste der FilterChipInputIdentifier
 * @param filterChipInputIdentifiersToAdd Die FilterChipInputIdentifier, die hinzugefügt werden sollen
 */
function updateFilterChipInputIdentifier(
  currentFilterChipInputIdentifiers: FilterChipInputIdentifier[],
  filterChipInputIdentifiersToAdd: FilterChipInputIdentifier[],
): FilterChipInputIdentifier[] {

  for (let identifierToAdd of filterChipInputIdentifiersToAdd) {
    const indexToRemove = currentFilterChipInputIdentifiers.findIndex(chip => {
      return chip.filterName === identifierToAdd.filterName;
    });

    // INFO: Wenn der identifier bereits in der Liste vorhanden ist, dann wird dieser auf derselben Position ersetzt
    if (indexToRemove !== -1) {
      currentFilterChipInputIdentifiers.splice(indexToRemove, 1, identifierToAdd);
    } else {
      currentFilterChipInputIdentifiers = [
        ...currentFilterChipInputIdentifiers,
        identifierToAdd,
      ];
    }
  }

  return currentFilterChipInputIdentifiers;
}

/**
 * Löscht einen FilterChipInputIdentifier anhand des Filternamens.
 * Gibt anschließend die aktualisierte Liste der FilterChipInputIdentifier zurück.
 *
 * @param chipInputIdentifiers Die aktuelle Liste der FilterChipInputIdentifier
 * @param filterName Der Name des Filters im FilterBelegDTO, der entfernt werden soll
 */
function deleteFilterChipInputIdentifierByFilterName(
  chipInputIdentifiers: FilterChipInputIdentifier[],
  filterName: string,
): FilterChipInputIdentifier[] {
  return chipInputIdentifiers.filter(chip => {
    return chip.filterName !== filterName;
  });
}
