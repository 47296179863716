import {createReducer, on} from '@ngrx/store';
import {StipulateDialogActions} from '../actions/stipulate-dialog.actions';
import {StipulateDialogState} from '../states/stipulate-dialog.state';


export const initialStipulateDialogState: StipulateDialogState = {
  isOpen: false,
  belegIds: [],
};

export const stipulateDialogReducer = createReducer(
  initialStipulateDialogState,

  on(
    StipulateDialogActions.open,
    (state, {belegIds}) => ({
      ...state,
      isOpen: true,
      belegIds,
    })
  ),

  on(
    StipulateDialogActions.close,
    (state) => ({
      ...state,
      isOpen: false,
    })
  ),
);
