import {createAction, props} from '@ngrx/store';
import {Extras} from '@sentry/types/types-ts3.8/extra';
import {MappedHttpErrorResponse} from '@adnova/jf-ng-components';


export class SentryActions {

  public static captureException = createAction(
    '[Sentry] Captures the exception and sends it so sentry.',
    props<{
      error: MappedHttpErrorResponse,
      extras: Extras,
    }>(),
  );

}
