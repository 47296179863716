import {Component, Input} from '@angular/core';
import {Activity} from '../../../interfaces/activity.interface';


@Component({
  selector: 'bo-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent {

  @Input()
  value!: Activity;

  constructor() {
  }

}
