import {Injectable, OnDestroy} from '@angular/core';
import {FilterBelegDTO, InhaberDTO, PageableBelegDTOSortingEnum} from '../../../openapi/beleg-openapi';
import {BehaviorSubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Pageable} from '../../../services/overview.service';
import {NavigationService} from '@adnova/jf-ng-components';


export interface BelegFilter {
  inhaberId: string;
  filter: FilterBelegDTO;
  sorting: PageableBelegDTOSortingEnum[];
  pageable: Pageable;
}

@Injectable({
  providedIn: 'root'
})
export class LeftPanelService implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  private _inhabers: InhaberDTO[] = [];
  private _inhabersChanged$ = new Subject<void>();
  private _inhaberId = '';
  private _triggerReload$ = new Subject<void>();
  private _filter$ = new BehaviorSubject<BelegFilter>({
    inhaberId: '',
    filter: {},
    sorting: [PageableBelegDTOSortingEnum.Erstelltdesc],
    pageable: {
      pageSize: 50,
      pageIndex: 0,
    },
  });

  public inhaberLoaded = false;

  constructor(
    private navigationService: NavigationService,
  ) {
    this.navigationService.inhaberLoaded$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaberLoaded => {
      this.inhaberLoaded = inhaberLoaded;
    });

    this.navigationService.inhaberList$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhabers => {
      this._inhabers = inhabers;
      this._inhabersChanged$.next();
    });

    this.navigationService.currentInhaber$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inhaber => {
      let inhaberId = '';
      if (inhaber) {
        inhaberId = inhaber.id;
      }
      this._inhaberId = inhaberId;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._inhabersChanged$.next();
    this._inhabersChanged$.complete();
    this._triggerReload$.next();
    this._triggerReload$.complete();
  }

  get inhabers(): InhaberDTO[] {
    return this._inhabers;
  }

  get inhaberId(): string {
    return this._inhaberId;
  }

  get filterSubject(): BehaviorSubject<BelegFilter> {
    return this._filter$;
  }

  get inhabersChanged(): Subject<void> {
    return this._inhabersChanged$;
  }

  get triggerReload(): Subject<void> {
    return this._triggerReload$;
  }

}
