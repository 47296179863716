import {Injectable, OnDestroy} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PortalMessageBroker} from './portal-message-broker';


export enum WellKnownShellProperties {
  APP_MODE_NORMAL = 'shell:app-mode:normal',
  APP_MODE_POPUP = 'shell:app-mode:popup',
  APP_MODE_WIDGET = 'shell:app-mode:widget',
  EMBEDDED_EMBEDDED = 'shell:embedded:embedded',
  EMBEDDED_EMBEDDING = 'shell:embedded:embedding',
}

export enum WellKnownJustFarmingProperties {
  ANALYTICS = 'vendor:de.just-farming:analytics',
  ERROR_REPORTING = 'vendor:de.just-farming:errorReporting',
}

export type WellKnownProperties = WellKnownShellProperties | WellKnownJustFarmingProperties;
export type PropertyKey = string | WellKnownProperties;

export type Property = boolean | string | number | undefined;
export type ArrayProperty = Property[];
export type ContextInfoProperty = Property | ArrayProperty;

export interface ContextInfo {
  properties: Map<PropertyKey, ContextInfoProperty>;
}


@Injectable({
  providedIn: 'root'
})
export class ContextInfoService
  implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  private readonly _contextInfo$ = new BehaviorSubject<ContextInfo>({
    properties: new Map(),
  });

  constructor(
    private logger: NGXLogger,
    private portalMessageBroker: PortalMessageBroker,
  ) {
    this.portalMessageBroker.registerContextInfoCallback(contextInfo => {
      this._contextInfo$.next(contextInfo);

      this.logger.debug('ContextInfoService: new context info', this._contextInfo$.value);
    });

    // INFO: Nachfolgende Zeilen sind nur für Testzwecke, um das Empfangen von Intents ohne Shell zu testen.
    // this._contextInfo$.next({
    //   properties: new Map().set('global:inhaberId', '9edae4ae-1b12-4489-bd98-dd3a4080ef42')
    // });
    //
    // setTimeout(() => {
    //   this._contextInfo$.next({
    //     properties: new Map().set('global:inhaberId', '61cd1c8f-5717-42fa-9770-93cf917de84f')
    //   });
    // }, 10000);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get contextInfo(): ContextInfo {
    return this._contextInfo$.value;
  }

  get contextInfo$(): Observable<ContextInfo> {
    return this._contextInfo$.asObservable()
      .pipe(
        takeUntil(this.unsubscribe$),
      );
  }

  /**
   * Legt den Wert einer geteilten Eigenschaft fest.
   *
   * Steht anderen Applications über das ContextInfo Objekt zur Verfügung.
   *
   * @param key eindeutiger Identifikator
   * @param value Wert zum angegebenen Schlüssel
   */
  public submitProperty(key: string, value: ContextInfoProperty): void {
    if (this.portalMessageBroker.isRunningInPortal()) {
      this.portalMessageBroker.submitContextInfoProperty(key, value);
    }
  }

}
