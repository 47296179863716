<h2 mat-dialog-title>Dublette bearbeiten</h2>
<mat-dialog-content>
  Bitte wähle eine Aktion für jede erkannte Dublette. Bereits fertiggestellte Belege können nur <br>
  storniert werden.

  <div class="sub-title">Mögliche Dubletten:</div>
  <bo-dublette-item
    *ngFor="let data of dublettenDatas"
    [id]="data.id"
    [icon]="'common:dublette'"
    [empfaenger]="data.empfaenger"
    [belegStatus]="data.belegStatus"
    [isFestgeschrieben]="data.isFestgeschrieben"
    [erstellt]="data.erstellt"
    [zahlungStatus]="data.zahlungStatus"
    [isOriginal]="false"
    (openPreview)="doOpenPreview($event)"
    (deleteOrReverse)="doDeleteOrReverseDublette($event)"
  >
  </bo-dublette-item>

  <div class="button-wrapper">
    <span
      [matTooltip]="deleteAllDisabled ? 'Kann nicht auf Dubletten mit Status Fertiggestellt ausgeführt werden.\n'+
      'Klicke hierfür auf das jeweilige Mülleimer-Symbol.' : ''"
      matTooltipTouchGestures="on"
      matTooltipPosition="below"
    >
      <button
        mat-raised-button
        [disabled]="deleteAllDisabled"
        color="accent"
        (click)="deleteAll()"
      >
        <bo-icon icon="actions:delete" primaryStroke="{{!deleteAllDisabled ? 'white' : '#919191'}}"></bo-icon>
        Dubletten löschen
      </button>
    </span>
    <button
      mat-flat-button
      (click)="doCloseDialog()"
    >Trotzdem behalten
    </button>
  </div>

  <div class="sub-title">Original:</div>
  <bo-dublette-item
    *ngIf="originalBelegData"
    [id]="originalBelegData.id"
    [icon]="'common:beleg'"
    [empfaenger]="originalBelegData.empfaenger"
    [belegStatus]="originalBelegData.belegStatus"
    [isFestgeschrieben]="originalBelegData.isFestgeschrieben"
    [erstellt]="originalBelegData.erstellt"
    [zahlungStatus]="originalBelegData.zahlungStatus"
    [isOriginal]="true"
    (openPreview)="doOpenPreview($event)"
    (deleteOrReverse)="doDeleteOrReverseDublette($event)"
  ></bo-dublette-item>
</mat-dialog-content>
