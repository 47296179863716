import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReuseBelegDialogComponent} from './reuse-beleg-dialog.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';


@NgModule({
  declarations: [
    ReuseBelegDialogComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
  ]
})
export class ReuseBelegDialogModule {

  /**
   * @property components
   * @description
   * Statische Eigenschaft, die die Komponenten des Moduls zur Verfügung stellt.
   * Diese Eigenschaft ist besonders nützlich für das Lazy Loading von Komponenten
   * innerhalb von Angular Material Dialogen. Durch die Bereitstellung eines Zugriffs
   * auf die Komponentenklasse ermöglicht es Angular, die Komponente zur Laufzeit zu
   * instanziieren, selbst wenn das Modul vorab geladen wurde.
   */
  public static components = {
    reuseBelegDialogComponent: ReuseBelegDialogComponent,
  };
}
