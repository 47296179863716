import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ActivityDisplayComponent} from './activity-display.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {IconModule} from '../common/icon/icon.module';
import {ActivityComponent} from './activity/activity.component';
import {MatLineModule} from '@angular/material/core';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';


@NgModule({
  declarations: [
    ActivityDisplayComponent,
    ActivityComponent,
  ],
  exports: [
    ActivityDisplayComponent
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatButtonModule,
    IconModule,
    MatLineModule,
    MatListModule,
    MatProgressSpinnerModule,
  ]
})
export class ActivityDisplayModule {
}
