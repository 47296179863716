import {createSelector} from '@ngrx/store';
import {AppState} from '../states/app.state';
import {identity} from 'rxjs';


export class PreviewDialogSelectors {

  private static select = {
    belegId: (state: AppState) => state.previewDialog?.belegId,
  };

  public static belegId = createSelector(
    this.select.belegId,
    identity,
  );

}
