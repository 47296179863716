<mat-form-field appearance="outline">
  <mat-label>Betrag von</mat-label>
  <input
    matInput
    currencyMask
    [formControl]="controlVon"
    [options]="inputOptions"
  >
</mat-form-field>

<mat-form-field appearance="outline">
  <mat-label>Betrag bis</mat-label>
  <input
    matInput
    currencyMask
    [formControl]="controlBis"
    [options]="inputOptions"
  >
</mat-form-field>
