<div
  class="aktionen-status"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  [hidden]="!beleg.aktionen?.exists"
  (mouseover)="onAktionenOver()"
  (mouseout)="onAktionenOut()"
  (focus)="onAktionenOver()"
  (blur)="onAktionenOut()"
  [ngClass]="{
    'aktionen-loading': loadingAktionen,
    'aktionen-finished': aktionenFinished,
  }"
>
  Aktionen
  <ng-template
    cdkConnectedOverlay
    cdkConnectedOverlayPanelClass="aktionen-overlay"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOffsetX]="16"
    [cdkConnectedOverlayPositions]="[
        {
            originX: 'end',
            originY: 'center',
            overlayX: 'start',
            overlayY: 'center'
        }
    ]"
    [cdkConnectedOverlayOpen]="(aktionenOpen$ | async) || false"
  >
    <ul class="aktionen-details">
      <li
        *ngFor="let aktion of aktionen"
        [innerText]="createAktionInfo(aktion)"
      ></li>
      <li
        *ngIf="!aktionen.length"
      >
        Details konnten nicht abgerufen werden.
      </li>
    </ul>
  </ng-template>
</div>
