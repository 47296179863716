import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReuseBelegData} from '../../interfaces/reuse-beleg-data.interface';
import {Store} from '@ngrx/store';
import {BelegEntitiesActions} from '../../store/actions/beleg-entities.actions';
import {BelegEntitiesSelectors} from '../../store/selectors/beleg-entities.selectors';
import {AppState} from '../../store/states/app.state';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ReuseBelegDialogActions} from '../../store/actions/reuse-beleg-dialog.actions';
import {ReuseBelegDialogSelectors} from '../../store/selectors/reuse-beleg-dialog.selectors';


@Component({
  selector: 'bo-reuse-beleg-dialog',
  templateUrl: './reuse-beleg-dialog.component.html',
  styleUrls: ['./reuse-beleg-dialog.component.scss']
})
export class ReuseBelegDialogComponent implements OnInit, OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  protected reuseBelegData!: ReuseBelegData;

  constructor(
    private store: Store<AppState>,
  ) {
  }

  ngOnInit() {
    this.store.select(ReuseBelegDialogSelectors.reuseBelegData).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(data => {
      if (!data) return;

      this.reuseBelegData = data;
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  protected doReuseBeleg(): void {
    this.store.dispatch(BelegEntitiesActions.reverseBeleg({
      inhaberId: this.reuseBelegData.inhaberId,
      belegId: this.reuseBelegData.belegId,
      createKorrekturBeleg: true,
      belegAlreadyReversed: this.reuseBelegData.belegAlreadyReversed,
      isDublette: this.reuseBelegData.isDublette,
    }));

    this.closeDialog();
  }

  protected closeDialog(): void {
    this.store.dispatch(ReuseBelegDialogActions.closeReuseBelegDialog());
  }
}
