<h2 mat-dialog-title>Löschen nicht mehr möglich</h2>
<mat-dialog-content>
  Dieser Beleg wurde bereits in deiner Buchführung revisionssicher gespeichert.
  <br><br>
  Möchtest du diesen Beleg zurücknehmen oder korrigieren, so<br>
  wird dieser in jedem Fall storniert. Er verbleibt aber bei einer<br>
  Betriebsprüfung sichtbar in deinen Buchführungsdaten.
  <span *ngIf="reuseBelegData?.showReuseOption">
    <br><br>
    Für die Korrektur der Daten kannst du diesen Beleg wiederverwenden.
  </span>
  <span *ngIf="linkedPayment">
    <br><br>
    Diesem Beleg ist ein Bankkontoumsatz zugeordnet. Durch das Stornieren
    wird die Verknüpfung zwischen Beleg und Bankkontoumsatz gelöst.
  </span>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    mat-stroked-button
    (click)="doReverseBeleg(false)"
  >Beleg stornieren
  </button>
  <button
    mat-stroked-button
    *ngIf="reuseBelegData?.showReuseOption"
    (click)="doReverseBeleg(true)"
  >Stornieren und wiederverwenden
  </button>
  <button mat-flat-button class="fw-bold" (click)="closeDialog()">Abbrechen</button>
</mat-dialog-actions>
