import {createAction, props} from '@ngrx/store';

export class StipulateDialogActions {

  public static open = createAction(
    '[Overview Table] Open stipulate beleg dialog.',
    props<{
      belegIds: string[];
    }>(),
  );

  public static close = createAction(
    '[Stipulate-Beleg-Dialog] Close stipulate beleg dialog.',
  );

}
