import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {BelegDTO, BelegService, InhaberDTO} from '../../openapi/beleg-openapi';
import {takeUntil} from 'rxjs/operators';
import {BelegIdentity} from '../../interfaces/beleg-identity.interface';
import {NGXLogger} from 'ngx-logger';
import {InhaberService} from '../../openapi/zahlung-openapi';


@Injectable({
  providedIn: 'root'
})
export class EditService implements OnDestroy {

  private readonly unsubscribe$ = new Subject<void>();

  public currentBeleg?: BelegDTO;
  public currentBeleg$ = new BehaviorSubject<BelegDTO | undefined>(this.currentBeleg);

  private _bankTransferAllowedForInhaber: InhaberDTO[] = [];

  public currentBelegeList: BelegIdentity[] = [];
  public currentBelegeList$ = new BehaviorSubject<BelegIdentity[]>(this.currentBelegeList);

  public currentBelegNumber = 0;
  public currentBelegNumber$ = new BehaviorSubject<number>(this.currentBelegNumber);

  public hasNext = false;
  public hasNext$ = new BehaviorSubject<boolean>(this.hasNext);

  public hasPrevious = false;
  public hasPrevious$ = new BehaviorSubject<boolean>(this.hasPrevious);

  constructor(
    private belegService: BelegService,
    private logger: NGXLogger,
    private inhaberService: InhaberService,
  ) {

    this.inhaberService.getInhabers().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: inhaberDtos => {
        this._bankTransferAllowedForInhaber = inhaberDtos;
      },
      error: err => {
        this.logger.error('error occurred while fetching inhaberDtos from zahlung-service', err);
      }
    });

    this.currentBeleg$.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(currentBeleg => {
        this.currentBeleg = currentBeleg;
        this.calcHasNext();
        this.calcHasPrevious();
      });

      this.currentBelegeList$.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(currentBelegeList => {
        this.currentBelegeList = currentBelegeList;

        /*
         * INFO: Es muss der erste Beleg geladen werden, sofern nur ein einziger Beleg in der Liste ist.
         * Ansonsten würde die Bearbeiten-Seite immer den ersten Beleg in der Liste laden.
         * Unabhängig davon, ob dieser geöffnet wird oder es sich um einen anderen Beleg handelt.
         */
        if (this.currentBelegeList.length === 1) {
          this.currentBelegNumber$.next(0);
        }

        this.calcHasNext();
        this.calcHasPrevious();
      });

      this.currentBelegNumber$.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(currentBelegNumber => {
        if (this.currentBelegeList.length > 0) {
          if (this.currentBelegeList[currentBelegNumber]) {
            this.currentBelegNumber = currentBelegNumber;
            const currentBelegIdentity = this.currentBelegeList[currentBelegNumber];
            this.belegService.getBeleg(
              currentBelegIdentity.inhaberId,
              currentBelegIdentity.id
            ).subscribe(beleg => {
              this.currentBeleg$.next(beleg);
            });
          } else {
            this.logger.warn('Beleg-Index wurde in Array nicht gefunden.');
          }
          this.calcHasNext();
          this.calcHasPrevious();
        }
      });

      this.hasNext$.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(hasNext => {
        this.hasNext = hasNext;
      });

      this.hasPrevious$.pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(hasPrevious => {
        this.hasPrevious = hasPrevious;
      });

  }

  isBankTransferAllowed(inhaberId: string): boolean {
    return this._bankTransferAllowedForInhaber.some(el => el.id === inhaberId);
  }

  calcHasNext(): void {
    if (this.currentBelegeList[this.currentBelegNumber + 1]) {
      this.hasNext$.next(true);
      return;
    }
    this.hasNext$.next(false);
  }

  calcHasPrevious(): void {
    if (this.currentBelegeList[this.currentBelegNumber - 1]) {
      this.hasPrevious$.next(true);
      return;
    }
    this.hasPrevious$.next(false);
  }

  /**
   * Entfernt einen Beleg aus der aktuellen Liste und aktualisiert den anzuzeigenden Beleg auf der Bearbeiten-Seite.
   *
   * @param belegId Id es Belegs, welcher entfernt werden soll.
   */
  public removeBeleg(belegId: string): void {
    // INFO: Belege aktualisieren
    const newBelegIdentitys = this.currentBelegeList.filter(belegIdentity => {
      return belegIdentity.id !== belegId;
    });
    this.currentBelegeList$.next(newBelegIdentitys);

    // INFO: Anzuzeigenden Beleg auf der Bearbeiten-Seite aktualisieren
    this.currentBelegNumber$.next(this.currentBelegNumber);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
