import {createAction, props} from '@ngrx/store';


export class MoveBelegDialogActions {

  public static open = createAction(
    '[Move-Belege-Dialog] Open move-belege-dialog.',
    props<{
      sourceInhaberId: string,
      belegIds: string[],
    }>()
  );

  public static close = createAction(
    '[Move-Belege-Dialog] Close move-belege-dialog.'
  );

}
