import {AppState} from '../states/app.state';
import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {BelegEntitiesSelectors} from './beleg-entities.selectors';


export class StipulateDialogSelectors {

  private static select = {
    isOpen: (state: AppState) => state.stipulateDialog.isOpen,
    stipulateDialog: (state: AppState) => state.stipulateDialog,
    belegIds: (state: AppState) => state.stipulateDialog.belegIds,
  };

  public static stipulateDialog = createSelector(
    this.select.stipulateDialog,
    identity,
  );

  public static isOpen = createSelector(
    this.select.isOpen,
    identity,
  );

  public static belegDtos = (inhaberId: string) => createSelector(
    this.select.belegIds,
    BelegEntitiesSelectors.all,
    (belegIds, belege) =>
      BelegEntitiesSelectors.belegeByIds(inhaberId, belegIds).projector(belege),
  );

}
