import {createReducer} from '@ngrx/store';
import {EditState} from '../../interfaces/edit-state.interface';


export const initialEditState: EditState = {
};

export const editReducer = createReducer(
  initialEditState,

);
