import {PreviewDialogState} from '../states/preview-dialog.state';
import {createReducer, on} from '@ngrx/store';
import {BelegEntitiesActions} from '../actions/beleg-entities.actions';


export const initialPreviewDialogState: PreviewDialogState = {
};

export const previewDialogReducer = createReducer(
  initialPreviewDialogState,

  on(
    BelegEntitiesActions.openBelegPreviewDialog,
    (state, {inhaberId, belegId}) => ({
      ...state,
      inhaberId,
      belegId,
    })
  ),

  on(
    BelegEntitiesActions.closeBelegPreviewDialog,
    (state) => ({
      ...state,
      inhaberId: undefined,
      belegId: undefined,
    })
  ),

);
