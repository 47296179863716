// Angular
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BrowserModule} from '@angular/platform-browser';
import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
// Material
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
// Auth
import {
  AuthInterceptor as OidcAuthInterceptor,
  AuthModule,
  OidcSecurityService,
  OpenIdConfiguration,
  StsConfigHttpLoader,
  StsConfigLoader
} from 'angular-auth-oidc-client';
// Logging
import {LoggerModule, NGXLogger, NgxLoggerLevel} from 'ngx-logger';
// Portal
import {PortalMessageBroker} from './portal/portal-message-broker';
import {PortalTokenService} from './portal/portal-token.service';
// OpenAPI
import {
  ApiModule as BelegApiInternalModule,
  Configuration as BelegConfiguration,
  InhaberService
} from './openapi/beleg-openapi';
import {ApiModule as ZahlungApiInternalModule, Configuration as ZahlungConfiguration} from './openapi/zahlung-openapi';
// App
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BelegApiModule} from './modules/common/beleg-api/beleg-api.module';
import {MockMatIconComponent} from './modules/common/mock-mat-icon/mock-mat-icon.component';
import {httpInterceptorProviders} from './http-interceptors';
import {DefaultComponent} from './templates/default/default.component';
import {IconModule} from './modules/common/icon/icon.module';
import {LegalLinksComponent} from './modules/overview/legal-links/legal-links.component';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {EditEffects} from './store/effects/edit.effects';
import {
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatLegacyTooltipModule as MatTooltipModule
} from '@angular/material/legacy-tooltip';
import {mergeMap, take, tap} from 'rxjs';
import {ConfigModule} from './config/config.module';
import {ConfigService} from './config/service/config.service';
import {filter, map} from 'rxjs/operators';
import {AuthInterceptor} from './auth/auth.interceptor';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import * as Sentry from '@sentry/angular-ivy';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {metaReducers, reducers} from './store/reducers/reducers';
import {BelegStipulatedDialogModule} from './modules/beleg-stipulated-dialog/beleg-stipulated-dialog.module';
import {ReuseBelegDialogModule} from './modules/reuse-beleg-dialog/reuse-beleg-dialog.module';
import {DubletteSnackbarComponent} from './modules/common/dublette-snackbar/dublette-snackbar.component';
import {
  MatomoConsentMode,
  MatomoInitializationMode,
  MatomoInitializerService,
  NgxMatomoTrackerModule
} from '@ngx-matomo/tracker';
import {ContextInfoService, WellKnownJustFarmingProperties} from './portal/context-info.service';
import {ActivityDisplayModule} from './modules/activity-display/activity-display.module';
import {PreviewModule} from './modules/common/preview/preview.module';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {SentryEffects} from './store/effects/sentry.effects';
import {
  AppSkeletonModule,
  BetriebsauswahlModule,
  CONTEXT_INFO,
  ErrorPagesModule,
  INHABER_SERVICE,
  NavigationModule,
  NGX_LOGGER,
} from '@adnova/jf-ng-components';
import {BetriebsauswahlPageComponent} from './modules/pages/betriebsauswahl-page/betriebsauswahl-page.component';
import {DefaultPageComponent} from './modules/pages/default-page/default-page.component';




registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    MockMatIconComponent,
    DefaultComponent,
    LegalLinksComponent,
    DubletteSnackbarComponent,
    BetriebsauswahlPageComponent,
    DefaultPageComponent,
  ],
  imports: [
    IconModule,
    BelegApiModule,
    // Angular
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    // Material
    MatIconModule,
    MatListModule,
    MatSidenavModule,
    MatMenuModule,
    MatButtonModule,
    MatToolbarModule,
    MatCardModule,
    MatSnackBarModule,
    // Config
    ConfigModule,
    // Logging
    LoggerModule.forRoot({
      level: NgxLoggerLevel.INFO, // default logging level
      enableSourceMaps: !environment.production,
    }),
    // Auth
    // TODO: Auth ggf. in eigenes Module auslagern
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: (configService: ConfigService) => {
          const configObservable = configService.loaded$
            .pipe(
              take(1),
              map(config => {
                const authConfig: OpenIdConfiguration = {
                  // loaded
                  authority: config.auth.authority,
                  clientId: config.auth.clientId,
                  scope: config.auth.scope,
                  logLevel: config.auth.logLevel,
                  renewTimeBeforeTokenExpiresInSeconds: config.auth.renewTimeBeforeTokenExpiresInSeconds,
                  // predefined
                  redirectUrl: window.location.origin,
                  postLogoutRedirectUri: window.location.origin,
                  responseType: 'code',
                  silentRenew: true,
                  triggerAuthorizationResultEvent: true,
                  ignoreNonceAfterRefresh: true,
                  useRefreshToken: true,
                  // INFO: Bei anpassungen auch AuthInterceptor.matchesSecureRoots() anpassen
                  secureRoutes: [
                    config.beleg.apiBaseUrl,
                    config.beleg.naseBaseUrl,
                    config.zahlung.apiBaseUrl,
                  ],
                };
                return authConfig;
              }),
            );
          return new StsConfigHttpLoader(configObservable);
        },
        deps: [ConfigService],
      },
    }),
    // OpenAPI
    BelegApiInternalModule.forRoot(() => new BelegConfiguration()),
    ZahlungApiInternalModule.forRoot(() => new ZahlungConfiguration()),
    // App
    AppRoutingModule,
    StoreModule.forRoot(
      reducers,
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictActionSerializability: true,
          strictStateSerializability: true,
        }
      }),
    EffectsModule.forRoot([
      EditEffects,
      SentryEffects,
    ]),
    StoreDevtoolsModule.instrument({name: 'Belege Online', maxAge: 25, logOnly: !environment.production}),
    MatTooltipModule,
    PreviewModule,
    BelegStipulatedDialogModule,
    ReuseBelegDialogModule,
    NgxMatomoTrackerModule.forRoot({
      mode: MatomoInitializationMode.AUTO_DEFERRED,
      requireConsent: MatomoConsentMode.TRACKING,
    }),
    ActivityDisplayModule,
    MatDialogModule,
    NavigationModule,
    ErrorPagesModule,
    AppSkeletonModule,
    BetriebsauswahlModule,
  ],
  exports: [
    AuthModule,
  ],
  providers: [
    {
      provide: CONTEXT_INFO,
      useClass: ContextInfoService,
    },
    {
      provide: INHABER_SERVICE,
      useClass: InhaberService,
    },
    {
      provide: NGX_LOGGER,
      useClass: NGXLogger,
    },
    OidcAuthInterceptor,
    {
      // INFO: Interceptor um automatisch da OAuth Token zu setzen
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      // INFO: Initializer das Logging
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigService, logger: NGXLogger) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(value => {
              logger.updateConfig({
                ...logger.getConfigSnapshot(),
                level: value.logging.level
              });
            }),
          );
      },
      deps: [ConfigService, NGXLogger],
      multi: true,
    },
    {
      // INFO: Initializer für Sentry Integration
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        contextInfoService: ContextInfoService,
        logger: NGXLogger,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(config => {
              const sentry = config.sentry;
              const info = environment.info;
              if (sentry) {
                Sentry.init({
                  enabled: sentry.enabled,
                  debug: sentry.debug,
                  environment: config.environment,
                  release: info.commit,
                  dsn: sentry.dsn,
                  sampleRate: sentry.sampleRate,
                  autoSessionTracking: false,
                  enableTracing: false,
                  // maxBreadcrumbs nur erhöhen, wenn niemals sensiblen Daten mitgeschrieben werden
                  // https://docs.sentry.io/platforms/javascript/guides/angular/configuration/integrations/default/#breadcrumbs
                  maxBreadcrumbs: 0,
                  initialScope: {
                    extra: {
                      'info': info,
                    },
                  },
                  beforeSend: (event) => {
                    let contextInfo = contextInfoService?.contextInfo;
                    if (contextInfo?.properties.get(WellKnownJustFarmingProperties.ERROR_REPORTING)) {
                      return event;
                    }
                    logger.debug('Error reporting is disabled, dropping event');
                    return null;
                  },
                  beforeSendTransaction: (event) => {
                    let contextInfo = contextInfoService?.contextInfo;
                    if (
                      contextInfo?.properties.get(WellKnownJustFarmingProperties.ANALYTICS)
                      && contextInfo?.properties.get(WellKnownJustFarmingProperties.ERROR_REPORTING)
                    ) {
                      return event;
                    }
                    logger.debug('Error reporting or analytics are disabled, dropping transaction event');
                    return null;
                  },
                  beforeBreadcrumb: (breadcrumb) => {
                    let contextInfo = contextInfoService?.contextInfo;
                    if (
                      contextInfo?.properties.get(WellKnownJustFarmingProperties.ANALYTICS)
                      && contextInfo?.properties.get(WellKnownJustFarmingProperties.ERROR_REPORTING)
                    ) {
                      return breadcrumb;
                    }
                    logger.debug('Error reporting or analytics are disabled, dropping breadcrumb');
                    return null;
                  },
                  ignoreErrors: [
                    // INFO: https://gitlab.intern.landdata.de/land-data/belege/belege-online-angular/-/issues/478
                    /startCleanup: Page \d+ is currently rendering/,
                  ]
                });
              }
            }),
          );
      },
      deps: [
        ConfigService,
        ContextInfoService,
        NGXLogger,
      ],
      multi: true,
    },
    {
      // INFO: Angular Sentry ErrorHandler
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        matomoInitializerService: MatomoInitializerService,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(config => {
              const matomoConfig = config.matomo;

              if (
                matomoConfig?.enabled
                && matomoConfig?.trackerUrl
                && matomoConfig?.siteId
              ) {
                matomoInitializerService.initializeTracker({
                  trackerUrl: matomoConfig.trackerUrl,
                  siteId: matomoConfig.siteId,
                });

              }
            }),
          );
      },
      deps: [
        ConfigService,
        MatomoInitializerService,
      ],
      multi: true,
    },
    {
      // INFO: Initializer der die Base-URL setzt
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        belegConfiguration: BelegConfiguration,
        zahlungConfiguration: ZahlungConfiguration,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            tap(value => {
              belegConfiguration.basePath = value.beleg.apiBaseUrl;
              zahlungConfiguration.basePath = value.zahlung.apiBaseUrl;
            }),
          );
      },
      deps: [ConfigService, BelegConfiguration, ZahlungConfiguration],
      multi: true,
    },
    {
      // INFO: Initializer für Portal integration die Base-URL setzt
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        belegConfiguration: BelegConfiguration,
        portalMessageBroker: PortalMessageBroker,
        portalTokenService: PortalTokenService,
        oidcSecurityService: OidcSecurityService,
      ) => () => {
        return configService.loaded$
          .pipe(
            take(1),
            mergeMap(config => {
              return fromPromise(portalMessageBroker.init(config.portal.targetOrigin))
                .pipe(map(() => config));
            }),
            mergeMap(config => {
              return fromPromise(portalTokenService.init(config.auth.renewTimeBeforeTokenExpiresInSeconds))
                .pipe(map(() => config));
            }),
            tap(() => {
              const runningEmbedded = portalMessageBroker.isRunningInPortal();
              Sentry.setTag('runningEmbedded', runningEmbedded);

              if (runningEmbedded) {
                const decoded = jwtDecode<JwtPayload>(portalTokenService.getAdnovaToken());
                const token = decoded as any;

                const email = token.email;
                const username = token.preferred_username;
                const name = token.name;

                Sentry.withScope(scope => {
                  let user = scope.getUser();
                  if (!user) {
                    user = {};
                  }
                  user.id = decoded.sub;
                  user.email = email;
                  user.username = username;
                  user['name'] = name;

                  scope.setUser(user);
                });
              } else {
                oidcSecurityService.getAccessToken()
                  .pipe(
                    filter(value => {
                      if (value) {
                        return true;
                      }
                      return false;
                    }),
                  )
                  .subscribe(accessToken => {
                    const decoded = jwtDecode<JwtPayload>(accessToken);
                    const token = decoded as any;

                    const email = token.email;
                    const username = token.preferred_username;
                    const name = token.name;

                    Sentry.withScope(scope => {
                      let user = scope.getUser();
                      if (!user) {
                        user = {};
                      }
                      user.id = decoded.sub;
                      user.email = email;
                      user.username = username;
                      user['name'] = name;

                      scope.setUser(user);
                    });
                  });
              }
            }),
          );
      },
      deps: [ConfigService, BelegConfiguration, PortalMessageBroker, PortalTokenService, OidcSecurityService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true,
        showDelay: 600,
        hideDelay: 150,
      },
    },
    httpInterceptorProviders,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
