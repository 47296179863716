import {ZahlungEntitiesState} from '../states/zahlung-entities.state';
import {createReducer, on} from '@ngrx/store';
import {ZahlungEntitiesActions} from '../actions/zahlung-entities.actions';
import {zahlungEntitiesAdapter} from '../adapters/zahlung-entities.adapter';
import {belegEntitiesAdapter} from '../adapters/beleg-entities.adapter';


export const initialZahlungEntitiesState: ZahlungEntitiesState = zahlungEntitiesAdapter.getInitialState();

export const zahlungEntitiesReducer = createReducer(
  initialZahlungEntitiesState,

  on(
    ZahlungEntitiesActions.getZahlungSuccess,
    (state, {zahlungDto}) => {
      const zahlungDtos = zahlungEntitiesAdapter.getSelectors().selectAll(state);
      if (zahlungDtos.find(currentZahlungDto => currentZahlungDto.id === zahlungDto.id)) {
        return belegEntitiesAdapter.updateOne(
          {
            id: zahlungDto.id,
            changes: zahlungDto
          }, state);
      } else {
        return belegEntitiesAdapter.upsertOne(
          zahlungDto,
          state,
        );
      }
    }
  ),

  on(
    ZahlungEntitiesActions.readZahlungenSuccess,
    (state, {zahlungDtos}) => {
      return zahlungEntitiesAdapter.upsertMany(zahlungDtos, state);
    }
  ),

  on(
    ZahlungEntitiesActions.deleteZahlungSuccess,
    (state, {zahlungId}) => {
      return zahlungEntitiesAdapter.removeOne(zahlungId, state);
    }
  ),

);
