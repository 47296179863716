import {createReducer, on} from '@ngrx/store';
import {DeleteDialogActions} from '../actions/delete-dialog.actions';
import {DeleteDialogState} from '../states/delete-dialog.state';


export const initialDeleteDialogState: DeleteDialogState = {
  belegIds: [],
};

export const deleteDialogReducer = createReducer(
  initialDeleteDialogState,

  on(
    DeleteDialogActions.open,
    (state, {belegIds}) => ({
      ...state,
      belegIds,
    })
  ),

  on(
    DeleteDialogActions.close,
    (state) => ({
      ...state,
      belegIds: [],
    })
  ),
);
