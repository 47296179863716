<h2>Verschieben in</h2>
<mat-selection-list [multiple]="false">
  <mat-list-option
    *ngFor="let inhaber of (moveDestinationInhabers$ | async)"
    [value]="inhaber"
    (click)="move(inhaber.id)">

    <div class="d-flex flex-direction-column">
      <div class="title">
        {{inhaber.bezeichnung}}
      </div>
      <div class="tab-content subtitle">
        <ng-container *ngIf="inhaber.betriebsnummer">
          {{inhaber.betriebsnummer}}
        </ng-container>
        <ng-container *ngIf="inhaber.geschaeftstaetigkeit">
          {{inhaber.geschaeftstaetigkeit}}
        </ng-container>
      </div>
    </div>

  </mat-list-option>
</mat-selection-list>

<button
  mat-flat-button
  class="fw-bold"
  (click)="closeDialog()"
>Abbrechen</button>
