import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

import {BelegDTO} from '../../../openapi/beleg-openapi';
import {PdfPreviewComponent} from './pdf-preview/pdf-preview.component';


@Component({
  selector: 'bo-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {

  @ViewChild(PdfPreviewComponent)
  pdfPreviewComponent?: PdfPreviewComponent;

  @Input()
  isFullscreenDialog = false;

  @Input()
  beleg$!: Observable<BelegDTO | undefined>;

  @Input()
  alwaysFullscreen?: boolean;

  @Input()
  wordsToMark: string[] = [];

  @Output()
  closePreview = new EventEmitter();

  beleg?: BelegDTO;

  inhaberId!: string;

  belegSubscription?: Subscription;

  constructor() {
  }

  ngAfterViewInit(): void {
    this.markWords();
  }

  markWords(): void {
    if (this.pdfPreviewComponent) {
      this.pdfPreviewComponent.wordsToMark = this.wordsToMark;
      this.pdfPreviewComponent.markWords = true;
    }
  }

  doClosePreview(): void {
    this.closePreview.emit();
  }

  /**
   * Prüft, ob der Beleg ein PDF oder eine XRechnung ist.
   * PDF = application/pdf
   * XRechnung = application/xml
   *
   * @param beleg
   */
  isPdfOrXrechnung(beleg?: BelegDTO): boolean {
    /*
     * TODO:
     * Sobald via OpenAPI verfügbar, zusätzlich über folgendes Flag prüfen
     * (contentType === 'application/xml'
     *   && beleg?.getBelegbucherkennungsart() == Belegbucherkennungsart.XRechnung)
     */
    const contentType = beleg?.contentType;
    return contentType === 'application/pdf' || contentType === 'application/xml';
  }

  ngOnInit(): void {
    if (this.beleg$ !== undefined) {
      this.belegSubscription = this.beleg$.subscribe(
        value => {
          this.beleg = value;
        },
      );
    }

    this.alwaysFullscreen = this.alwaysFullscreen === undefined ? false : this.alwaysFullscreen;
  }

  ngOnDestroy(): void {
    this.belegSubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.wordsToMark) {
      this.markWords();
    }
  }
}
