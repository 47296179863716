import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import {formatPercent} from '@angular/common';
import {HttpEventType, HttpProgressEvent, HttpResponse} from '@angular/common/http';
import {NGXLogger} from 'ngx-logger';
import {Observable, throwError} from 'rxjs';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {BelegDTO, BelegService} from '../../../openapi/beleg-openapi';
import {BelegApiModule} from './beleg-api.module';
import {ActivityDisplayService} from '../../activity-display/activity-display.service';
import {ActivityIdentifier} from '../../../interfaces/activity-identifier.interface';


// FIXME: Den Service löschen, nach dem der PDF-Viewer als eine eigene Komponente verfügbar ist.
@Injectable({
  providedIn: BelegApiModule
})
export class BelegDownloadService {

  constructor(
    private logger: NGXLogger,
    @Inject(LOCALE_ID) private locale: string,
    private belegService: BelegService,
    private activityDisplayService: ActivityDisplayService,
  ) {
  }

  doDownload(
    beleg: BelegDTO,
    showProgress: boolean = true,
  ): void {

    this.downloadInternal(beleg, showProgress, true)
      .subscribe(response => {
        const blob = response.body;

        const objectUrl = window.URL.createObjectURL(blob as Blob);

        const a = document.createElement('a');
        a.href = objectUrl;
        a.download = beleg.dateiname as string;
        a.click();

        window.URL.revokeObjectURL(objectUrl);

        this.logger.debug('BelegDownloadService.doDownload(): download complete', beleg);
      }, error => {
        this.logger.debug('BelegDownloadService.doDownload(): download failed', beleg, error);
      });
  }

  backgroundDownload(
    beleg: BelegDTO,
    showProgress: boolean = true,
  ): Observable<HttpResponse<Blob>> {
    return this.downloadInternal(beleg, showProgress, false);
  }

  private downloadInternal(
    beleg: BelegDTO,
    showProgress: boolean,
    showActivity: boolean
  ): Observable<HttpResponse<Blob>> {
    const inhaberId = beleg.inhaberId;
    const belegId = beleg.id;

    let identifier: ActivityIdentifier | undefined;
    if (showProgress && showActivity) {
      identifier = this.activityDisplayService.addTask(inhaberId, 'Herunterladen ' + beleg.dateiname, '');
    }

    return this.belegService.getBelegContent(inhaberId, belegId, 'events', true, {httpHeaderAccept: 'application/octet-stream'})
      .pipe(
        tap(event => {
          if (identifier) {
            switch (event.type) {
              case HttpEventType.DownloadProgress:
                const progress = event as HttpProgressEvent;
                if (progress.total) {
                  this.logger.trace('BelegDownloadService.downloadInternal(): download progress',
                    beleg, progress.loaded, progress.total);

                  const progressValue = progress.loaded / progress.total;
                  this.activityDisplayService.updateActivity(identifier, {
                    progress: progressValue * 100,
                    mode: 'determinate',
                    description: formatPercent(progressValue, this.locale),
                  });
                } else {
                  this.logger.trace('BelegDownloadService.downloadInternal(): download progress', beleg, progress.loaded);
                }
                break;
              case HttpEventType.Response:
                this.logger.debug('BelegDownloadService.downloadInternal(): download complete', beleg);

                this.activityDisplayService.successActivity(identifier, 'Herunterladen erfolgreich');

                break;
            }
          }
        }, error => {
          this.logger.warn('BelegDownloadService.downloadInternal(): download failed', beleg, error);

          if (identifier) {
            this.activityDisplayService.failActivity(identifier, 'Herunterladen fehlgeschlagen');
          }
        }),
        catchError(err => {
          return throwError(err);
        }),
        filter(event => {
          return event.type === HttpEventType.Response;
        }),
        map(event => event as HttpResponse<Blob>)
      );
  }
}
