import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, from, Observable, Subject} from 'rxjs';
import {InhaberDTO} from '../openapi/beleg-openapi';
import {EditService} from '../modules/edit/edit.service';
import {Router} from '@angular/router';
import {BelegIdentity} from '../interfaces/beleg-identity.interface';
import {LeftPanelService} from '../modules/common/left-panel/left-panel.service';
import {HelperService} from './helper.service';
import {EditPageData} from '../interfaces/edit-page.data.interface';
import {Store} from '@ngrx/store';
import {AppState} from '../store/states/app.state';
import {OverviewTableActions} from '../store/actions/overview-table.actions';


export interface StatusFilter {
  offen?: boolean;
  bearbeitet?: boolean;
  festgeschrieben?: boolean;
  storniert?: boolean;
}

export interface Pageable {
  pageSize: number;
  pageIndex: number;
}

@Injectable({
  providedIn: 'root'
})
export class OverviewService implements OnDestroy {

  inhaberId = '';

  private readonly unsubscribe$ = new Subject<void>();

  private inhaber$ = new BehaviorSubject<InhaberDTO | undefined>(undefined);

  constructor(
    private router: Router,
    private editService: EditService,
    private leftPanelService: LeftPanelService,
    private helper: HelperService,
    private store: Store<AppState>,
  ) {
  }

  get inhaber(): Observable<InhaberDTO | undefined> {
    return this.inhaber$.asObservable();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public openOverview(
    inhaberId: string,
    statusFilter?: StatusFilter,
    searchText?: string,
  ): void {
    const oldValue = this.leftPanelService.filterSubject.value;

    from(this.router.navigate([`/overview/inhaber/${inhaberId}`]))
      .subscribe(value => {
        const newValue = this.helper.deepCopyFilter(oldValue);
        // reset fields
        newValue.pageable.pageIndex = 0;

        // insert values
        newValue.inhaberId = inhaberId;
        newValue.filter = {
          volltext: searchText,
        };
        if (statusFilter) {
          newValue.filter.offen = statusFilter.offen;
          newValue.filter.bearbeitet = statusFilter.bearbeitet;
          newValue.filter.festgeschrieben = statusFilter.festgeschrieben;
          newValue.filter.storniert = statusFilter.storniert;
        }
        this.leftPanelService.filterSubject.next(newValue);

        this.store.dispatch(OverviewTableActions.updateStatusFilter({
          offen: !!newValue.filter.offen,
          bearbeitet: !!newValue.filter.bearbeitet,
          festgeschrieben: !!newValue.filter.festgeschrieben,
          storniert: !!newValue.filter.storniert,
        }));
      });
  }

  openEditBeleg(
    editPageData: EditPageData,
  ): void {
    this.editService.currentBelegeList$.next(editPageData.belege);
    this.editService.currentBelegNumber$.next(editPageData.index);

    if (editPageData.belege.length !== 0) {
      this.router.navigate(
        [
          '/edit/inhaber/' + editPageData.inhaberId + '/beleg/' + this.getBelegIdFromIndex(editPageData.belege, editPageData.index)
        ]
      );
    }
  }

  private getBelegIdFromIndex(belege: BelegIdentity[], index: number): string {
    return belege[index]?.id;
  }
}
