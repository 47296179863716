import {Component, EventEmitter, Input, OnInit, Output, OnDestroy} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'bo-filter-dublette',
  templateUrl: './filter-dublette.component.html',
  styleUrls: ['./filter-dublette.component.scss']
})
export class FilterDubletteComponent implements OnInit, OnDestroy {
  private readonly unsubscribe$ = new Subject<void>();
  protected dubletteControl = new FormControl();

  @Input()
  public set value(showDublette: boolean | undefined) {
    this.dubletteControl.setValue(
        showDublette,
        {emitEvent: false}
    )
  }

  @Output()
  public valueChange = new EventEmitter<boolean | undefined>()

  ngOnInit() {
    this.dubletteControl.valueChanges.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((value) => {
      this.valueChange.emit(value);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
